import React from "react";
import GoalCard from "./GoalCard";

const Goals = () => {
  return (
    <>
      <div className="w-full flex flex-col h-[88vh] border border-gray-100 rounded-2xl py-8 px-4 gap-[40px]">
        <div
          style={{ lineHeight: "150%" }}
          className="  text-xl text-black font-bold "
        >
          Goals
        </div>
        <div className="flex justify-center items-start flex-col">
          <div
            className="hover:cursor-pointer flex items-center gap-[8px] rounded-lg text-sm font-semibold leading-5"
            style={{
              letterSpacing: "0.28px",
              fontFamily: "Inter",
              color: "var(--Shades-50, #FFF)",
              padding: "12px 16px 12px 12px",
              background: "var(--Dawn-500, #F57D34)",
            }}
          >
            + Add New Goals
          </div>
        </div>

        <GoalCard />
      </div>
    </>
  );
};

export default Goals;
