import { createSlice } from "@reduxjs/toolkit";
import { findAll } from "config/APIs/product";
import { logError } from "helpers/utils/common/logError";
import { updateItemInArray, updateObject } from "helpers/utils/redux";

const initialState = {
  list: [],
  fetching: true,
};

// Storing products
export const product = createSlice({
  name: "product",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list = action.payload.list;
      let list = action.payload.list;

      state.list = list;
      state.fetching = false;
    },
    updateAProduct: (state, action) => {
      let id = action.payload.id;
      let t = action.payload.product;
      const newList = updateItemInArray(state.list, id, (product) => {
        return updateObject(product, { ...t });
      });
      state.list = newList;
    },
    addAProduct: (state, action) => {
      let t = action.payload.product;

      const newList = state.list.concat([t]);
      state.list = newList;
    },
    deleteAProduct: (state, action) => {
      let id = action.payload.id;
      const newList = state.list.filter((t) => t?.id !== id);
      state.list = newList;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList, updateAProduct, addAProduct, deleteAProduct } =
  product.actions;

export function fetchAllProducts() {
  return async (dispatch) => {
    try {
      const response = await findAll();
      if (response.status === 200) {
        let data = response.data.data;

        dispatch(updateList({ list: data }));
      }
    } catch (err) {
      logError("Fetch all products: ", err);
    }
  };
}

export default product.reducer;
