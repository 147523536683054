import React from "react";

const PostCard = () => {
  return (
    <>
      <div
        style={{ background: "var(--Neutral-50, #FAFAFA)" }}
        className="flex py-4 px-4 items-start gap-4 rounded-2xl flex-col "
      >
       
        <div
          style={{
            color: "var(--Neutral-500, #696763)",
            letterSpacing: "0.32px",
          }}
          className="flex gap-[10px]"
        >

         <img src="#" className="rounded-full" />
         <div className="flex flex-col">
          <span className="text-base font-semibold"> Broklyn Simons </span>
          <span className="text-xs font-light"> Designation </span>
        </div>
        </div>

<div className="flex flex-wrap">
        <div
          style={{
            border: "1px solid var(--Neutral-200, #E7E6E5)",
            fontFamily: "Inter",
            letterSpacing: "0.24px",
          }}
          className="flex items items-start gap-[8px] py-1 px-4 rounded-lg text-center font-inter text-neutral-800 text-xs font-light leading-4 tracking-tighter"
        >
          Project Name
        </div>
</div>
        
      </div>
    </>
  );
};

export default PostCard;
