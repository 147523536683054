import { useEffect, useState } from "react";
import Modal from "../Modal";
import Epics from "./Epics";
import Stories from "./Stories";
import { useSelector } from "react-redux";
import { PROJECT_CATEGORY_AGILE_SCRUM } from "helpers/projects";
import Products from "components/Planner/Common/Products";

export default function GoalSetting() {
  const products = useSelector((state) => state?.products?.list);
  const fetching = useSelector((state) => state.products.fetching);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [selectedStory, setSelectedStory] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (products.length > 0) {
      setSelectedProduct(products[0]);
    }
  }, [products]);

  return (
    <div className="relative ">
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        epic={selectedEpic}
        story={selectedStory}
      />
      <div className="min-80vh grid grid-cols-3 gap-6 mt-5">
        <Products
          fetching={fetching}
          list={products}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
        <Epics
          selectedProduct={selectedProduct}
          selectedEpic={selectedEpic}
          setSelectedEpic={setSelectedEpic}
        />
        <Stories
          selectedProduct={selectedProduct}
          selectedEpic={selectedEpic}
          setIsOpen={setIsOpen}
          selectedStory={selectedStory}
          setSelectedStory={setSelectedStory}
        />
      </div>
    </div>
  );
}
