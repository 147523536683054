import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import UsersTask from "components/SquadUser/UsersTask";
import * as TaskAPI from "config/APIs/task/task";
import { getUserDetails } from "config/APIs/squad";
import { UserIcon } from "@heroicons/react/outline";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import UserMessages from "components/SquadUser/UserMessages";
import Tabs from "components/Comman/Tabs";
import UsersProjects from "components/SquadUser/UserProjects";
import UserInsights from "components/SquadUser/Insights";
import {
  getUserGoalComment,
  getUserGoals,
  getUsersProjects,
} from "config/APIs/users";
import SquadLoader from "./SquadLoader";
import UsersGoal from "components/SquadUser/UsersGoal";

const tabs = [
  { label: "Insights", value: "insights" },
  { label: "Goals", value: "goals" },
  // { label: "Tasks", value: "tasks" },
  { label: "Projects", value: "projects" },
  // { label: "Messages", value: "messages" },
];

let statsList = [
  {
    label: "Goals past due date",
    field: "pastDueDate",
    condition: { pastDueDate: true },
  },
  {
    label: "Goals not started",
    field: "notStarted",
    condition: { status: "NotStarted" },
  },
  {
    label: "Goals in progress",
    field: "inProgress",
    condition: { status: "InProgress" },
  },
  {
    label: "Goals completed last week",
    field: "completed",
    condition: { completedLastWeek: true },
  },
];

function SquadUser({ time }) {
  const { id } = useParams();
  const history = useHistory();
  const [details, setDetails] = useState({});
  const [taskQuery, setTaskQuery] = useState({});
  const [goalQuery, setGoalQuery] = useState({});
  const [fetching, setFetching] = useState(false);
  const [fetchingMessages, setFetchingMessages] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [goals, setGoals] = useState([]);
  const [selectedTab, setSelectedTab] = useState("insights");
  const [messages, setMessages] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectsTypeWise, setProjectsTypeWise] = useState({});

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      getMemberDetails();
      getProjects();
      // getMessages();
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  // useEffect(() => {
  //   let isMounted = true;

  //   if (isMounted) {
  //     getTasks();
  //   }

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [taskQuery]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getGoals();
    }

    return () => {
      isMounted = false;
    };
  }, [goalQuery]);

  // useEffect(() => {
  //   localStorage.setItem("userTab", selectedTab);
  // }, [selectedTab]);

  // useEffect(() => {
  //   if (localStorage.getItem("userTab") != "insights") {
  //     setSelectedTab(localStorage.getItem("userTab"));
  //     localStorage.removeItem("userTab");
  //   }
  // }, []);

  const getMessages = async () => {
    setFetchingMessages(true);
    let msg = [];

    try {
      const response = await getUserGoalComment(id);
      const msg = response.data.data;
    } catch (err) {
      console.log("Fetch task comment error", err);
    }

    setMessages(msg);
    setFetchingMessages(false);
  };

  const getMemberDetails = async () => {
    try {
      const response = await getUserDetails(id);
      const user = response.data.data;

      setDetails(user);
    } catch (err) {
      console.log("Fetch user error", err);
    }
  };

  const getTasks = async () => {
    setFetching(true);

    TaskAPI.getUsersTasks(id, taskQuery)
      .then((res) => {
        let resp = res.data.data;
        setTasks(resp);
        setFetching(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGoals = async () => {
    setFetching(true);

    getUserGoals(id, goalQuery)
      .then((res) => {
        let resp = res.data.data;
        setGoals(resp);
        setFetching(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProjects = async () => {
    getUsersProjects(id)
      .then((res) => {
        let p = res.data.data;
        let types = [];
        let temp = {};
        p?.map((i) => {
          if (types?.includes(i?.type)) {
            temp[i.type].push(i);
          } else {
            types.push(i?.type);
            temp[i.type] = [i];
          }
        });
        setProjects(p);
        setProjectsTypeWise(temp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="w-full pt-2.5 px-2.5">
        <div className="w-full px-5 relative">
          <div className="absolute top-3 right-5">
            <ArrowLeftIcon
              onClick={() => {
                history.goBack();
              }}
              className="w-4 text-black mr-2"
            />
          </div>
          <div
            className="p-5 flex flex-col w-full bg-white"
            style={{ borderRadius: "20px" }}
          >
            <div className="flex flex-row items-center space-x-2.5">
              <div
                className={`w-10 h-10 bg-gray-200 flex flex-row items-center justify-center rounded-full `}
              >
                {details?.image?.url ? (
                  <img
                    src={details?.image?.url}
                    className="rounded-full h-10 w-10"
                    alt=""
                  />
                ) : (
                  <UserIcon className="rounded-full h-7 w-7 text-gray-500" />
                )}
              </div>
              <div className="">
                <p className="text-xs font-lato text-primary-gray-900 font-normal leading-4 cursor-pointer break-all">
                  {details?.first_name}
                </p>

                <p className="text-2xs tracking-normal font-lato font-normal leading-3 text-primary-gray-250 max-w-max cursor-pointer">
                  {details?.designation?.role?.role_name}
                </p>
                <p className="text-2xs tracking-normal font-lato font-normal leading-3 text-primary-gray-250 max-w-max cursor-pointer">
                  {details?.designation?.rank?.rank_name}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      {fetching ? (
        <div className="space-y-5 p-5">
          {[1, 2, 3, 4].map((epic, idx) => (
            <div className="p-5 flex flex-row items-center bg-white shadow-container rounded-20px text-primary-gray-1000">
              <h1 className="text-2xl font-bold w-1/3 text-center">0</h1>
              <p className="text-sm h-4 w-1/2 rounded animate-pulse bg-primary-gray-200"></p>
            </div>
          ))}
        </div>
      ) : (
        <div className="max-h-60vh overflow-y-auto px-2.5">
          {selectedTab == "insights" && (
            <UserInsights
              statsList={statsList}
              stats={details?.stats}
              setGoalQuery={(val) => {
                setGoalQuery(val);
                setSelectedTab("goals");
              }}
            />
          )}
          {selectedTab == "projects" && (
            <UsersProjects
              typeWiseProject={projectsTypeWise}
              fetching={fetching}
              projects={projects}
              userId={details?.id}
              setProjects={setProjects}
              setTypeWiseProject={setProjectsTypeWise}
              details={details}
            />
          )}
          {selectedTab == "tasks" && (
            <UsersTask
              statsList={statsList}
              taskQuery={taskQuery}
              setTaskQuery={setTaskQuery}
              projects={projects}
              tasks={tasks}
              fetching={fetching}
              getTasks={getTasks}
              details={details}
            />
          )}
          {selectedTab == "goals" && (
            <UsersGoal
              statsList={statsList}
              goalQuery={goalQuery}
              setGoalQuery={setGoalQuery}
              projects={projects}
              goals={goals}
              fetching={fetching}
              getGoals={() => {
                getGoals();
                getProjects();
              }}
              details={details}
            />
          )}
          {selectedTab == "messages" && <UserMessages messages={messages} />}
        </div>
      )}
    </>
  );
}

export default SquadUser;
