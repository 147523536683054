import { useState } from "react";
import SearchBox from "components/Comman/Inputs/SearchBox";
import CommunityList from "components/TAM/Mobile";
import { RadioTabs } from "components/Comman/Tabs";
import { USER_TYPE_CAARYA_CORE, userTypes } from "helpers/constants/users";

function TAMMobile() {
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });
  const [showing, setShowing] = useState("");
  const [selectedTab, setSelectedTab] = useState(USER_TYPE_CAARYA_CORE);

  return (
    <>
      <div className="block lg:hidden">
        <div className="px-7.5 pt-2.5 space-y-2.5 w-full">
          <SearchBox
            placeholder="Find a member!"
            search={search}
            setSearch={setSearch}
          />
          <RadioTabs
            tabs={userTypes}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>

        <div className="z-20 w-full py-2 pl-8 pr-8 flex flex-row items-center justify-between">
          <h1 className="text-xs font-lato font-normal w-full text-primary-gray-900 flex flex-row items-center">
            Showing <p className="font-bold ml-1">{showing}</p>
          </h1>
        </div>

        <div className="max-h-70vh overflow-x-hidden">
          <CommunityList
            search={search}
            setShowing={setShowing}
            selectedTab={selectedTab}
          />
        </div>
      </div>
    </>
  );
}

export default TAMMobile;
