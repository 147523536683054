import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tasks: [],
  tempTask: [],
  today: { fetching: false, list: [] },
};

// Storing User's Tasks
export const userTasks = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    updateTasks: (state, action) => {
      if (action.payload?.tasks) {
        state.tasks = action.payload?.tasks;
      }
    },
    updateTasksList: (state, action) => {
      state.tempTask = action.payload;
    },
    updateTodayTask: (state, action) => {
      let list = action.payload.list;
      let fetching = action.payload.fetching;
      if (fetching !== null && fetching != undefined) {
        state.today.fetching = fetching;
      }
      if (list?.length > 0) {
        state.today.list = list;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateTasks, updateTasksList, updateTodayTask } =
  userTasks.actions;

export default userTasks.reducer;
