import { CheckCircleIcon } from "@heroicons/react/solid";
import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "redux/toaster";
import * as GoalsAPI from "config/APIs/task/goal";

import { Eye, Fire, Lightbulb, WarningCircle } from "@phosphor-icons/react";
import UpdateGoal from "../Modals/EditGoal/UpdateGoal";

function Insights({ onUpdate, details, setDetails, setTab, fetchGoal }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const [missing, setMissing] = useState([]);
  const [showEditModal, setShowEditModal] = useState({
    isOpen: false,
    type: "",
  });

  useEffect(() => {
    let temp = [];

    if (details?.priority == null || details?.priority == 6) {
      temp.push({ name: "Missing Priority", type: "priority" });
    }
    if (moment(details?.dueDate).unix() < moment().unix()) {
      temp.push({ name: "Past due date", type: "dueDate" });
    }

    setMissing(temp);
  }, [details]);

  const handleUpdate = async ({ inFocus, priority }) => {
    const update = { ...details };
    if (inFocus) {
      update["inFocus"] = 1;
    }

    if (priority) {
      update["priority"] = priority;
    }

    let body = {
      ...update,
    };
    try {
      const response = await GoalsAPI.updateGoal(details.id, body);
      const goal = response.data.data;
      dispatch(showToast({ message: "Goal added to focus!", type: "success" }));

      onUpdate();
    } catch (err) {
      switch (err.response?.status) {
        case 401:
          dispatch(showToast({ message: "Unauthorized!", type: "error" }));
          break;
        default:
          dispatch(
            showToast({
              message: err?.response?.message || "Something went wrong!",
              type: "error",
            })
          );
      }
    }
  };

  const updatePriority = (priority) => {
    // if (user?.id !== details?.creatorId) {
    //   dispatch(
    //     showToast({
    //       message: "Can only be changed by manager!",
    //       type: "error",
    //     })
    //   );
    //   return;
    // }
    setDetails({
      ...details,
      priority,
    });
    handleUpdate({ priority });
  };

  // const updateGoal = (goal) => {
  //   if (user?.id !== details?.creatorId) {
  //     dispatch(
  //       showToast({
  //         message: "Can only be changed by manager!",
  //         type: "error",
  //       })
  //     );
  //     return;
  //   }
  //   setDetails({
  //     ...details,
  //     goal,
  //   });
  //   handleUpdate({ goal });
  // };

  return (
    <div className="lg:px-2.5">
      <UpdateGoal
        isOpen={showEditModal?.isOpen}
        closeModal={() => setShowEditModal({ isOpen: false, type: "" })}
        goal={details}
        details={details}
        setDetails={setDetails}
        type={showEditModal?.type}
        onUpdate={() => {
          onUpdate();
          setShowEditModal({ isOpen: false, type: "" });
        }}
      />

      <div className={`px-5 py-2.5 mt-6 rounded-10px space-y-5`}>
        <div className="flex flex-col space-y-5 font-bold">
          {moment().diff(moment(details?.dueDate), "days", false) > 0 && (
            <div className="font-lato text-xs lg:text-sm text-center flex flex-row items-center space-x-2.5 text-secondary-red-400">
              <WarningCircle className=" w-4 h-4" />
              <p>
                This goal has been due since
                {` ${moment().diff(
                  moment(details?.dueDate),
                  "days",
                  false
                )} day${
                  moment().diff(moment(details?.dueDate), "days", false) > 1
                    ? "s"
                    : ""
                }`}
              </p>
            </div>
          )}
          {moment().diff(moment(details?.dueDate), "days", false) == 0 && (
            <div className="font-lato text-xs lg:text-sm text-center flex flex-row items-center space-x-2.5 text-secondary-green-550">
              <Lightbulb className="w-4 h-4" /> <p>This goal is due today </p>
            </div>
          )}
          {moment().diff(moment(details?.dueDate), "days", false) < 0 &&
            moment().diff(moment(details?.dueDate), "days", false) > -8 && (
              <div className="font-lato text-xs lg:text-sm text-center flex flex-row items-center space-x-2.5 text-secondary-orange-550">
                <p>💪🏻 This goal is due this week </p>
              </div>
            )}
        </div>
        <div className="flex flex-col space-y-5">
          <div className="font-lato text-xs lg:text-sm text-center flex flex-col items-start space-y-1.5 text-primary-gray-1000">
            <p
              onClick={() => {
                handleUpdate({ inFocus: true });
              }}
              className="flex items-center space-x-2.5 text-secondary-green-300 cursor-pointer hover:underline font-lato"
            >
              <Eye className="w-4 h-4 mr-3" />{" "}
              {details?.inFocus == 1
                ? "You are already focusing on this"
                : "Be the first one to do it ->"}{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="px-5 lg:px-0">
        <div
          className={`${
            missing?.length == 0
              ? "bg-secondary-green-50"
              : "bg-secondary-red-50"
          } px-5 py-2.5 mt-6 rounded-10px`}
        >
          <div className="grid grid-cols-1 gap-x-2.5 gap-y-5">
            {missing?.length > 0 ? (
              <>
                {missing?.map((i) => {
                  return (
                    <div
                      className={`flex flex-col space-y-3`}
                      key={missing?.type}
                    >
                      <h1 className="font-bold text-xs font-lato">{i?.name}</h1>
                      <div className={`w-full flex flex-row`}>
                        {i?.type == "priority" ? (
                          <div className="w-full flex items-center justify-around">
                            {[1, 2, 3, 4, 5, 6].map((i) => {
                              return (
                                <div
                                  className={`flex items-center gap-[2px]  priority-dark-${i}`}
                                  onClick={() => {
                                    updatePriority(i);
                                  }}
                                >
                                  <Fire className="h-3 text-primary-yellow-medium" />
                                  P{i}
                                </div>
                              );
                            })}
                          </div>
                        ) : i?.type == "dueDate" ? (
                          <div
                            className={`bg-secondary-red-100 text-secondary-red-550 text-xs font-lato font-normal py-1 px-2 rounded-full`}
                          >
                            {moment(details?.dueDate).format("LL")}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="col-span-2 font-lato text-xs lg:text-sm text-center flex flex-row items-center space-x-2.5 text-primary-gray-1000">
                <p>All goal details are already updated </p>
                <CheckCircleIcon className="text-primary-green-medium w-4 h-4" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`py-2.5 px-5 mt-6 rounded-10px space-y-5`}>
        <div className="flex flex-col space-y-5">
          {user?.id == details?.creator?.id &&
            user?.id == details?.owner?.id && (
              <div className="font-lato text-xs lg:text-sm text-center flex flex-col items-start space-y-1.5 text-primary-gray-1000">
                <p>
                  You <b>created</b> this goal and you <b>own</b> it
                </p>
                <p
                  onClick={() => {
                    setTab("details");
                  }}
                  className="text-secondary-green-550 cursor-pointer hover:underline font-lato"
                >
                  Are you sure you don't want to delegate it?
                </p>
              </div>
            )}
          {user?.id == details?.owner?.id && (
            <div className="font-lato text-xs lg:text-sm text-center flex flex-col items-start space-y-1.5 text-primary-gray-1000">
              <p>
                You <b>own</b> this goal
              </p>
              {details?.collaborators?.length > 0 ? (
                <p className="text-2xs lg:text-xs text-left">
                  You currently have {details?.length} collaborators on this
                  goal
                </p>
              ) : (
                <p
                  onClick={() => {
                    setTab("details");
                  }}
                  className="text-secondary-green-550 cursor-pointer hover:underline font-lato"
                >
                  Add a collaborator -&gt;
                </p>
              )}
              {/* <p className="text-secondary-orange-550 cursor-pointer hover:underline font-lato">
              Contribute more ->
            </p> */}
            </div>
          )}
          {details?.collaborators?.map((i) => i?.id)?.includes(user?.id) && (
            <div className="font-lato text-xs lg:text-sm text-center flex flex-col items-start space-y-1.5 text-primary-gray-1000">
              <p>
                You are a <b>collaborator</b> on this goal
              </p>
              {/* <p className="text-2xs lg:text-xs text-left">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
              </p> */}
              {/* <p className="text-secondary-orange-550 cursor-pointer hover:underline font-lato">
              Contribute more ->
            </p> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Insights;
