import { useState, useEffect } from "react";
import moment from "moment";
import StatusDropDown from "./StatusDropDown";
function ReleaseItem({ item, showLine, onUpdate, index }) {
  const [status, setStatus] = useState();

  useEffect(() => {
    if (status !== item?.status) {
      setStatus(item?.status);
    }
  }, [item]);

  return (
    <>
      <li>
        <div className="relative pb-8">
          {showLine ? (
            <span
              className="absolute top-9 left-4 -ml-px h-full w-0.5 border-r border-primary-gray-250"
              aria-hidden="true"
            />
          ) : null}
          <div className="relative flex space-x-6 w-full">
            <StatusDropDown
              disabled={!onUpdate}
              index={index}
              status={status}
              setStatus={(val) => {
                setStatus(val);
                onUpdate("status", val, item?.id);
              }}
            />
            <div className="min-w-0 flex-1 flex justify-between space-x-4 w-full">
              <div className="w-full">
                <div className="w-full flex flex-col md:flex-row items-start md:items-center justify-between">
                  <div
                    className={`text-sm flex flex-row items-start font-lato cursor-pointer text-primary-gray-600 font-bold w-full break-words`}
                  >
                    <p>{item.title}</p>
                  </div>
                </div>
                <p
                  className={`text-2xs font-lato cursor-pointer text-primary-gray-350 font-normal`}
                >
                  {item?.description}
                </p>
                {item?.dueDate && (
                  <p
                    className={`block text-2xs my-1.5 max-w-max font-lato cursor-pointer ${
                      moment(item?.dueDate).unix() <
                      moment().add(7, "days").unix()
                        ? "text-primary-orange-darker font-normal bg-primary-orange-lightest"
                        : "text-primary-indigo-700 font-normal bg-primary-indigo-100"
                    } px-4 py-1 rounded-full`}
                  >
                    {moment(item?.dueDate).format("ll")}
                  </p>
                )}
                <p
                  className={`text-2xs font-lato cursor-pointer text-primary-orange-darker underline font-normal`}
                >
                  {item?.project?.title}
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
}

export default ReleaseItem;
