import {
  CheckCircle,
  NotePencil,
  Target,
  Trash,
  XCircle,
} from "@phosphor-icons/react";
import * as EpicsAPI from "config/APIs/task/epics";
import * as StoriesAPI from "config/APIs/task/stories";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import ConfirmModal from "components/Modals/Common/ConfirmModal";
import ChronosButton from "components/Comman/Buttons";
import ConfirmCard from "components/Comman/Cards/ConfirmCard";

export const EpicItem = ({
  bold = false,
  isSelected = false,
  epic,
  onClick,
  onEdit,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [title, setTitle] = useState();
  const [selectedToDelete, setSelectedToDelete] = useState();
  const [selectedToDeleteOpen, setSelectedToDeleteOpen] = useState();

  useEffect(() => {
    if (epic) {
      setTitle(epic?.title);
    }
  }, [epic]);

  const handleUpdate = async () => {
    try {
      const response = await EpicsAPI.updateEpics(epic.id, {
        title,
      });
      const { task } = response.data.data;
      setEditable(false);
    } catch (err) {
      switch (err.response?.status) {
        case 422:
          let error = {},
            { data } = err.response.data;
          for (let key of Object.keys(data)) {
            if (key.split(".")[0] === "update")
              error[key.split(".")[1]] = data[key];
          }

          break;
        case 401:
          console.log(err?.response);
          dispatch(
            showToast({ message: err.response?.data?.message, type: "error" })
          );
          break;
        default:
          dispatch(
            showToast({
              message: err.response?.data?.message,
              type: "error",
            })
          );
      }
    }
  };
  const handleDelete = async () => {
    try {
      await EpicsAPI.deleteEpics(epic?.id);
      onUpdate();
      setSelectedToDelete(false);
      setSelectedToDeleteOpen(false);
    } catch (err) {
      console.log("Epic delete error", err);
      dispatch(
        showToast({ message: err.response?.data?.message, type: "error" })
      );
    }
  };
  return (
    <div className="">
      {/* <ConfirmModal
        isOpen={selectedToDelete}
        closeModal={() => setSelectedToDelete(false)}
        onAccept={() => handleDelete()}
        text={
          <>Are you sure you want to delete the following epic: {epic?.title}</>
        }
      /> */}
      <div
        className={`cursor-pointer transition ${
          editable ? "epicStory-card" : "epicStory-card-minis"
        } ease-in-out duration-150 ${
          isSelected ? "bg-primary-green-50 text-black" : "bg-white text-black"
        } rounded-lg  hover:shadow relative`}
      >
        {/* <Target
        size={45}
        className={`${isSelected ? "text-white" : "text-primary-ash-180"}`}
      /> */}
        <div className="flex items-start gap-4 w-full p-3">
          {editable ? (
            <textarea
              placeholder=""
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              rows={3}
              autoFocus
              className="text-[18px] bg-transparent p-0 w-full border-0 focus:border-0 focus:ring-0 focus:outline-none"
            />
          ) : (
            <p
              onClick={onClick}
              className={`${
                bold ? "font-bold" : "font-normal"
              } text-sm max-w-[80%]`}
            >
              {title}
            </p>
          )}
          {editable ? null : (
            <NotePencil
              onClick={() => {
                setEditable(true);
              }}
              size={20}
              className="text-primary-gray-600 absolute top-2 right-2"
            />
          )}
        </div>
        {editable && (
          <div className="w-full mt-1 flex cursor-pointer px-3 flex-row items-center space-x-1 justify-between">
            <ChronosButton
              text={selectedToDelete ? "deleting epic" : "delete"}
              onClick={() => {
                setSelectedToDelete(true);
                setTimeout(() => {
                  setSelectedToDeleteOpen(true);
                }, 200);
              }}
              tertiary
              red
            />

            <div className="flex flex-row items-center space-x-1 justify-end">
              <CheckCircle
                size={24}
                onClick={() => {
                  handleUpdate();
                }}
              />
              <XCircle
                size={24}
                onClick={() => {
                  setSelectedToDelete(false);
                  setSelectedToDeleteOpen(false);
                  setEditable(false);
                  setTitle(epic?.title);
                }}
              />
            </div>
          </div>
        )}
        {console.log(selectedToDelete, selectedToDeleteOpen)}
        {selectedToDelete && (
          <div className="mt-1 confirm-card rounded-b-lg">
            <ConfirmCard
              id={epic?.id}
              isOpen={selectedToDeleteOpen}
              closeModal={() => {
                setSelectedToDelete(false);
                setSelectedToDeleteOpen(false);
              }}
              onAccept={() => handleDelete()}
              text={
                <>
                  Are you sure you want to delete the following epic:{" "}
                  {epic?.title}
                </>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const StoryItem = ({
  isSelected = false,
  story,
  onClick,
  onEdit,
  onUpdate,
}) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [title, setTitle] = useState();
  const [selectedToDelete, setSelectedToDelete] = useState();
  const [selectedToDeleteOpen, setSelectedToDeleteOpen] = useState();

  useEffect(() => {
    if (story) {
      setTitle(story?.title);
    }
  }, [story]);

  const handleUpdate = async () => {
    try {
      const response = await StoriesAPI.updateStories(story.id, {
        title,
      });
      const { task } = response.data.data;
      setEditable(false);
    } catch (err) {
      switch (err.response?.status) {
        case 422:
          let error = {},
            { data } = err.response.data;
          for (let key of Object.keys(data)) {
            if (key.split(".")[0] === "update")
              error[key.split(".")[1]] = data[key];
          }

          break;
        case 401:
          console.log(err?.response);
          dispatch(
            showToast({ message: err.response?.data?.message, type: "error" })
          );
          break;
        default:
          dispatch(
            showToast({
              message: err.response?.data?.message,
              type: "error",
            })
          );
      }
    }
  };

  const handleDelete = async () => {
    try {
      await StoriesAPI.deleteStories(story?.id);

      onUpdate();
      setSelectedToDelete(false);
      setSelectedToDeleteOpen(false);
    } catch (err) {
      console.log("Story delete error", err);
      dispatch(
        showToast({ message: err.response?.data?.message, type: "error" })
      );
    }
  };
  return (
    <div>
      {/* <ConfirmModal
        isOpen={selectedToDelete}
        closeModal={() => setSelectedToDelete(false)}
        onAccept={() => handleDelete()}
        text={
          <>
            Are you sure you want to delete the following story: {story?.title}
          </>
        }
      /> */}

      <div
        className={`cursor-pointer transition ${
          editable ? "epicStory-card" : "epicStory-card-minis"
        } ease-in-out duration-150 ${
          isSelected ? "bg-primary-green-50 text-black" : "bg-white text-black"
        } rounded-lg hover:shadow relative`}
      >
        <div className="flex p-3 items-start gap-4 w-full ">
          {/* <Target
        size={45}
        className={`${isSelected ? "text-white" : "text-primary-yellow-300"}`}
      /> */}
          {editable ? (
            <textarea
              placeholder=""
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              rows={3}
              autoFocus
              className="text-[18px] bg-transparent p-0 w-full border-0 focus:border-0 focus:ring-0 focus:outline-none"
            />
          ) : (
            <p onClick={onClick} className={`font-bold text-sm max-w-[80%]`}>
              {title}
            </p>
          )}
          {editable ? null : (
            <NotePencil
              onClick={() => {
                setEditable(true);
              }}
              size={20}
              className="text-primary-gray-600 absolute top-2 right-2"
            />
          )}
        </div>{" "}
        {editable && (
          <div className="w-full mt-1 px-3 flex cursor-pointer flex-row items-center space-x-1 justify-between">
            <ChronosButton
              text={selectedToDelete ? "deleting story" : "delete"}
              onClick={() => {
                setSelectedToDelete(true);
                setTimeout(() => {
                  setSelectedToDeleteOpen(true);
                }, 200);
              }}
              tertiary
              red
            />
            <div className="flex flex-row items-center space-x-1 justify-end">
              <CheckCircle
                size={24}
                onClick={() => {
                  handleUpdate();
                }}
              />
              <XCircle
                size={24}
                onClick={() => {
                  setEditable(false);
                  setTitle(story?.title);
                  setSelectedToDelete(false);
                  setSelectedToDeleteOpen(false);
                }}
              />
            </div>
          </div>
        )}{" "}
        {selectedToDelete && (
          <div className="mt-1 confirm-card rounded-b-lg">
            <ConfirmCard
              id={story?.id}
              isOpen={selectedToDeleteOpen}
              closeModal={() => {
                setSelectedToDelete(false);
                setSelectedToDeleteOpen(false);
              }}
              onAccept={() => handleDelete()}
              text={
                <>
                  Are you sure you want to delete the following story:{" "}
                  {story?.title}
                </>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const Skeleton = () => {
  return (
    <div
      className={`animate-pulse flex items-start gap-4 w-full cursor-pointer bg-primary-gray-150 text-black rounded-lg p-3 hover:shadow`}
    >
      <Target size={45} className={"text-primary-ash-180"} />
      <div className="flex flex-col">
        <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-2"></div>
        <div className="h-2.5 bg-gray-200 rounded-full w-36 mb-2"></div>
        <div className="h-2.5 bg-gray-200 rounded-full w-24 mb-2"></div>
      </div>
    </div>
  );
};
