import ProjectPlannerLaptop from "components/Planner/ProjectPlanner/LaptopPage";

export default function ProjectPlanner() {
  return (
    <>
      <div className="block lg:hidden"></div>
      <div className="hidden lg:block">
        <ProjectPlannerLaptop />
      </div>
    </>
  );
}
