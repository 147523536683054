import { useState } from "react";
import * as GoalAPI from "config/APIs/task/goal";
import { ItemLoader } from "./Item";
import moment from "moment";
import PlannerList from "./List";
import MembersList from "./MembersList";
import DropdownInput from "components/Comman/Inputs/DropdownInput";

export default function BacklogGrooming({
  tasks,
  setSelectedTaskId,
  getTasks,
  fetching,
  groupBy,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);

  const updateTasks = async (operation) => {
    let ids = selectedTasks?.map((t) => t?.id);

    switch (operation) {
      case "move_to_tomorrow": {
        try {
          await GoalAPI.bulkUpdateGoals(ids, {
            dueDate: moment().add(1, "days").format("YYYY-MM-DD"),
          });
          getTasks(true);
        } catch (error) {
          console.log("error", error);
        } finally {
          setLoading(false);
        }
        break;
      }
      case "move_to_next_week": {
        try {
          await GoalAPI.bulkUpdateGoals(ids, {
            dueDate: moment().add(7, "days").format("YYYY-MM-DD"),
          });
          getTasks(true);
        } catch (error) {
          console.log("error", error);
        } finally {
          setLoading(false);
        }
        break;
      }
      case "delete": {
        try {
          await GoalAPI.bulkDeleteGoals(ids);
          getTasks(true);
        } catch (error) {
          console.log("error", error);
        } finally {
          setLoading(false);
        }
        break;
      }
      default:
    }

    setSelectedTasks([]);
  };

  return (
    <div className="grid grid-cols-[2fr,1fr] gap-4 xl:gap-6 max-h-[80vh] overflow-y-auto pb-20">
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-2 border-solid border-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      )}

      {!fetching ? (
        <div className="relative space-y-6 bg-white rounded-2xl shadow-md py-6 px-4">
          <div className="flex flex-row justify-between">
            <h1 className="text-black font-poppins text-base font-medium">
              Pending Goals
            </h1>
            <DropdownInput
              list={[
                {
                  label: "Projects",
                  value: "",
                },
              ]}
              value={groupBy}
              //setValue={}
              label="Project"
              srOnly
            />
          </div>
          <div className="space-y-14">
            <PlannerList
              category="pendingTasks"
              groupBy={groupBy}
              goals={tasks}
              heading="In Focus"
              selectedTasks={selectedTasks}
              setSelectedTasks={setSelectedTasks}
              updateTasks={updateTasks}
              setSelectedTaskId={setSelectedTaskId}
            />
            <PlannerList
              category="notInFocus"
              groupBy={groupBy}
              goals={tasks}
              heading="Not In Focus"
              selectedTasks={selectedTasks}
              setSelectedTasks={setSelectedTasks}
              updateTasks={updateTasks}
              setSelectedTaskId={setSelectedTaskId}
            />
          </div>
        </div>
      ) : (
        <div className="relative space-y-14 bg-white rounded-2xl shadow-md py-6 px-4">
          <div>
            <div className="flex justify-between">
              <div className="bg-black text-xs text-white px-3 py-2 rounded-md inline-flex items-center font-lato">
                InFocus
              </div>
            </div>
            <div className="mt-6">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, idx) => {
                return <ItemLoader idx={idx} />;
              })}
            </div>
          </div>
        </div>
      )}

      {/* <div className="relative space-y-6 bg-white rounded-2xl shadow-md px-2">
        {selectedTaskId ? (
          <TaskView
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={setSelectedTaskId}
            onUpdate={() => {
              getTasks(true);
            }}
          />
        ) : (
          <EmptyState
            text="Select a goal to view it's details"
            image="/assets/images/empty/goal.png"
          />
        )}
      </div> */}
      <MembersList />
    </div>
  );
}
