import {
  CheckCircle,
  PencilCircle,
  Smiley,
  Users,
} from "@phosphor-icons/react";
import { useSelector } from "react-redux";

const getClass = (type) => {
  switch (type) {
    case "goals":
      return "bg-primary-green-50 text-primary-green-300 border-primary-green-300";
    case "projects":
      return "bg-primary-yellow-50 text-primary-yellow-300 border-primary-yellow-300";
    case "people":
      return "bg-primary-blue-50 text-primary-blue-300 border-primary-blue-300";
    case "goals_due":
      return "bg-primary-red-50 text-primary-red-300 border-primary-red-300";
    case "members":
      return "bg-violet-50 text-violet-600 border-violet-300";
    default:
      return "";
  }
};

const Card = ({ icon, type, count, text, description }) => {
  if (!type) type = text;
  return (
    <div className="w-full">
      <div
        className={`text-xs py-[24px] px-[16px] rounded-md flex flex-col text-center items-center font-lato border w-full ${getClass(
          type
        )}`}
      >
        {icon ? (
          icon
        ) : (
          <p className="text-[28px] font-poppins font-medium">{count}</p>
        )}
        <div className="flex flex-col ml-2">
          {/* <p className="text-[16px] font-bold">
            {count} <small className="text-xs relative -top-0.5">{text}</small>
          </p> */}
          <p className="text-black capitalize font-inter text-[12px] tracking-[0.24px] mt-3">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default function Header({ stats, weekly }) {
  const user = useSelector((state) => state.user.user);
  return (
    <>
      <div className="mt-6 text-center">
        <h1 className="text-xl font-bold flex items-center gap-2 justify-center">
          Hello, {user?.first_name} 😄
        </h1>
        <p className="font-semibold text-sm">
          Here's where you will view a summary of projects, goals, team members
          and reports.
        </p>
      </div>
      <div className="flex mt-6 justify-around lg:gap-8 xl:gap-16">
        <Card
          text="goals"
          count={stats?.goalsDone}
          description={"goals worked on"}
          //description={`were done ${weekly ? "this week" : "today"}`}
        />
        <Card
          //icon={<PencilCircle size={40} />}
          text="projects"
          count={stats?.noGoals}
          description={"goals completed"}
          type="goals_due"
          //description={`had no work ${weekly ? "this week" : "today"}`}
        />
        <Card
          //icon={<PencilCircle size={40} />}
          text="members"
          count={stats?.goalsDue}
          description={"total time punched in today"}
          //type="goals_due"
        />
        {!weekly && (
          <Card
            //icon={<Users size={40} />}
            text="people"
            count={"00 / 00"}
            description={"members punched in"}
            //description={`did 0 goals ${weekly ? "this week" : "today"}`}
          />
        )}
      </div>
    </>
  );
}
