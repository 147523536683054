import { pmoInstance } from "config";
import { getHeader } from "config";

export const createRelease = (body) =>
  pmoInstance.post(`/release`, body, getHeader());

export const updateRelease = (id, body) =>
  pmoInstance.patch(`/release/${id}`, body, getHeader());

export const bulkUpdateReleases = (ids, body) =>
  pmoInstance.patch(`/release`, { ids, update: body }, getHeader());

export const getRelease = (ReleaseId) =>
  pmoInstance.get(`/release/${ReleaseId}`, getHeader());
export const getReleaseIssues = () =>
  pmoInstance.get(`/release/issues`, getHeader());

/**
 * The following API has Pagination
 * @param {} query adds queries to the endpoints
 * Queries --> size, page, searchItem, sort&sort, start_time, end_time, status, last_week, no_members, no_epics, no_due_date, to_self, admin, project, project_type
 * Value for searchItem --> Search strings
 * Values for sort --> | value from BE |&ASC/DESC
 */
export const getAllReleases = (query = {}) =>
  pmoInstance.get(
    `/release${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );

export const deleteRelease = (ReleaseId) =>
  pmoInstance.delete(`/release/${ReleaseId}`, getHeader());

export const bulkDeleteReleases = (ids) =>
  pmoInstance.patch(`/release/delete`, { ids }, getHeader());
