import React, { useEffect, useState } from "react";
import EmptyState from "components/Comman/EmptyState";
import { useDispatch, useSelector } from "react-redux";
import { XIcon } from "@heroicons/react/solid";
import moment from "moment";
import { showToast } from "redux/toaster";
import { updateReleaseList } from "redux/release";
import { getAllReleases, updateRelease } from "config/APIs/release";
import ReleaseItem from "./Card/Item";

function ReleaseList({
  search,
  selectedIssue,
  setSelectedIssue,
  selectedFilter,
  setSelectedFilter,
  time,
  onlyThisWeek,
}) {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.releases);
  const [fetching, setFetching] = useState(true);
  const [release, setRelease] = useState([]);
  const [projectWise, setProjectWise] = useState([]);
  const [selectedGoalToDelete, setSelectedGoalToDelete] = useState();
  const [selectedGoalToEdit, setSelectedGoalToEdit] = useState();

  const getReleases = async (refresh) => {
    if (list[time]?.fetching == true && !refresh) {
      setFetching(true);
      return;
    }

    if (list[time]?.list?.length > 0 && !refresh) {
      setRelease(list[time]?.list);
      setProjectWise(list[time]?.projectWiseRelease);
      setFetching(false);

      return;
    }
    setFetching(true);
    dispatch(updateReleaseList({ type: time, fetching: true }));
    try {
      const response = await getAllReleases({ time: time });
      let list = response.data.data.response;
      let temp = [];
      list?.map((i) => {
        if (temp?.find((p) => p?.id == i?.project?.id)) {
          let idx = temp?.findIndex((p) => p?.id == i?.project?.id);
          temp[idx].goals.push(i);
        } else {
          let obj = { ...i?.project };
          obj["goals"] = [i];
          temp.push(obj);
        }
      });
      setRelease(list);
      setProjectWise(temp);
      dispatch(
        updateReleaseList({
          type: time,
          list: list,
          fetching: false,
          projectWise: temp,
        })
      );
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  const onUpdate = async (field, val, id) => {
    let temp = [...list[time].list];
    let newData = { ...temp?.find((r) => r?.id == id) };
    let idx = release?.findIndex((r) => r?.id == id);
    newData[field] = val;
    try {
      let body = {
        ...newData,
      };
      const response = await updateRelease(id, body);

      temp[idx] = newData;

      let temp1 = [];
      temp?.map((i) => {
        if (temp1?.find((p) => p?.id == i?.project?.id)) {
          let idx = temp1?.findIndex((p) => p?.id == i?.project?.id);
          temp1[idx].goals.push(i);
        } else {
          let obj = { ...i?.project };
          obj["goals"] = [i];
          temp1.push(obj);
        }
      });
      setProjectWise(temp1);
      setRelease(temp);
      dispatch(
        updateReleaseList({
          type: time,
          list: temp,
          fetching: false,
          projectWise: temp1,
        })
      );
    } catch (err) {
      switch (err.response?.status) {
        case 401:

        default:
          dispatch(
            showToast({
              message: err?.response?.data?.message,
              type: "error",
            })
          );
      }
    }
  };
  useEffect(() => getReleases(), [list[time]?.list, time]);
  useEffect(
    () => getList(),
    [search?.searchText, selectedFilter?.value, selectedIssue, time]
  );
  const getList = () => {
    let temp = [...list[time].list];
    if (search?.searchText) {
      temp = temp?.filter((item) =>
        item?.title?.toLowerCase()?.includes(search?.searchText?.toLowerCase())
      );
    }

    if (selectedFilter?.value !== null) {
      temp = temp?.filter((item) => selectedFilter?.value == item?.projectId);
    }

    switch (selectedIssue?.type) {
      case "noMembers":
        temp = temp?.filter((t) => t?.owner == null);
        break;
      case "pending":
        temp = temp?.filter((t) => moment(t?.dueDate).unix() < moment().unix());
        break;
      case "noStory":
        temp = temp?.filter((t) => !t?.story_id);
        break;
      case "notStarted":
        temp = temp?.filter((t) => t?.status == "NotStarted");
        break;
      case "noDate":
        temp = temp?.filter((t) => !t?.dueDate);
        break;

      default:
    }
    let temp1 = [];
    temp?.map((i) => {
      if (temp1?.find((p) => p?.id == i?.project?.id)) {
        let idx = temp1?.findIndex((p) => p?.id == i?.project?.id);
        temp1[idx].goals.push(i);
      } else {
        let obj = { ...i?.project };
        obj["goals"] = [i];
        temp1.push(obj);
      }
    });
    setProjectWise(temp1);
    setRelease(temp);
  };
  const getLength = () => {
    let l = 0;

    projectWise?.map((project) => {
      if (!selectedFilter?.value || selectedFilter?.value == project?.id)
        l += project?.goals?.length;
    });
    return l;
  };

  return (
    <>
      {/* <EditGoal
        onDelete={() => {
          setSelectedGoalToDelete(selectedGoalToEdit);
        }}
        editValues={selectedGoalToEdit}
        isOpen={selectedGoalToEdit ? true : false}
        closeModal={() => setSelectedGoalToEdit(false)}
        onUpdate={() => getReleases(true)}
      /> */}

      {!selectedIssue && selectedFilter?.value == null && (
        <div className="z-20 w-full py-2 pl-8 pr-6 flex flex-row items-center justify-between">
          <h1 className="text-xs font-lato font-normal w-full text-primary-gray-900 flex flex-row items-center">
            Showing{" "}
            <b className="mx-1">{` ${release?.length || 0} releases `}</b>
            {time == "thisMonth"
              ? "this month"
              : time == "thisQuarter"
              ? "this quarter"
              : "this week"}
          </h1>
        </div>
      )}
      {(selectedIssue || selectedFilter?.value !== null) && (
        <div className="flex flex-row items-center space-x-3 pl-8 pr-5 py-2 ">
          <h1 className="text-xs font-lato leading-4 text-primary-gray-900">
            {selectedIssue && Object.keys(selectedIssue)?.length > 0 ? (
              <>
                Showing: <b>{selectedIssue?.count} </b>
                {selectedIssue?.title}{" "}
                {selectedFilter?.value !== null &&
                  ` for ${selectedFilter?.label}`}
              </>
            ) : (
              <>
                Showing: <b>{getLength()} goals</b>{" "}
                {selectedFilter?.value !== null &&
                  ` for ${selectedFilter?.label}`}
              </>
            )}
          </h1>
          {((selectedIssue && Object.keys(selectedIssue)?.length > 0) ||
            selectedFilter?.value !== null) && (
            <XIcon
              className="w-3 h-3 text-primary-red-medium cursor-pointer"
              onClick={() => {
                setSelectedIssue();
                setSelectedFilter({ label: "All", value: null });
              }}
            />
          )}
        </div>
      )}
      {!fetching ? (
        <div className="overflow-y-auto max-h-65vh px-7.5 space-y-5 pt-1">
          {projectWise?.length > 0 ? (
            projectWise?.map((project) => {
              if (
                !selectedFilter?.value ||
                selectedFilter?.value == project?.id
              )
                if (project?.goals?.length > 0)
                  return (
                    <div className="flex flex-col space-y-2.5">
                      <div className="flex flex-row space-x-2.5 items-center">
                        {project?.image?.url ? (
                          <img
                            src={project?.image?.url}
                            className="rounded-full h-5 w-5 object-cover"
                            alt=""
                          />
                        ) : (
                          <div className="rounded-full h-5 w-5 text-primary-orange-darker p-0.5 bg-primary-orange-lightest" />
                        )}
                        <h1 className="text-xs font-lato font-semibold leading-4 text-primary-gray-1000">
                          {project?.title}
                        </h1>
                      </div>
                      <div className="flow-root pt-1">
                        <ul role="list" className="">
                          {project?.goals?.map((item, idx) => {
                            return (
                              <ReleaseItem
                                index={idx}
                                item={item}
                                showLine={idx !== project?.goals.length - 1}
                              />
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  );
            })
          ) : (
            <EmptyState
              text="No releases found!"
              image="/assets/images/empty/goal.png"
            />
          )}
        </div>
      ) : (
        <div className="space-y-5 p-5">
          <div className="flow-root pt-1">
            <ul role="list" className="">
              {[1, 2, 3].map((epic, idx) => (
                <li>
                  <div className="relative pb-8">
                    {idx !== 2 ? (
                      <span
                        className="absolute top-4 left-4 -ml-px h-full w-0.5 border-r border-color-gray-350"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex space-x-6 w-full">
                      <div>
                        <span className="h-10 w-8 rounded-full flex items-center justify-center py-2 components-tabs-epics-epicsv2-span">
                          <img
                            src="/assets/images/icons/goalGray.svg"
                            alt=""
                            className="h-6 w-6"
                          />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1 flex justify-between space-x-4 w-full">
                        <div className="w-full">
                          <div className="w-full flex flex-row items-center justify-between">
                            <div
                              className={`text-sm w-8/12 h-4 rounded-lg bg-indigo-100 animate-pulse flex flex-row items-center inter cursor-pointer text-primary-gray-600 font-normal`}
                            ></div>
                            <p
                              className={`text-2xs w-2/12 h-4 rounded-lg bg-gray-100 animate-pulse inter cursor-pointer text-primary-gray-600 font-normal`}
                            ></p>
                          </div>
                          <p
                            className={`text-2xs inter cursor-pointer text-primary-gray-350 font-normal space-y-2 mt-2`}
                          >
                            <p
                              className={`text-2xs w-8/12 h-4 rounded-lg bg-gray-100 animate-pulse inter cursor-pointer text-primary-gray-600 font-normal`}
                            ></p>
                            <p
                              className={`text-2xs w-8/12 h-4 rounded-lg bg-gray-100 animate-pulse inter cursor-pointer text-primary-gray-600 font-normal`}
                            ></p>
                            <p
                              className={`text-2xs w-2/12 h-4 rounded-lg bg-gray-100 animate-pulse inter cursor-pointer text-primary-gray-600 font-normal`}
                            ></p>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default ReleaseList;
