import {
  MdCalendarToday,
  MdExpandMore,
  MdNavigateBefore,
  MdQueryBuilder,
} from "react-icons/md";

import { FiPlus } from "react-icons/fi";
import CommonAttendance from "./CommonAttendance";
import { useState } from "react";
import { attendanceList } from "helpers/constants/EventPlanner";
const MarkAttendance = ({ toggleAttendanceDrawer }) => {
  const [attendance, setAttendance] = useState(
    attendanceList.map((a) => ({ ...a, selected: false }))
  );
  const [showBulkAction, setShowBulkAction] = useState(false);
  const [showBulkActionDropdown, setShowBulkActionDropdown] = useState(false); // For toggling dropdown

  const handleAttendanceChange = (name, value) => {
    setAttendance(
      attendance.map((participant) => {
        if (participant.name === name) {
          return { ...participant, attendance: value };
        }
        return participant;
      })
    );
  };

  const handleBulkAttendanceChange = (value) => {
    setAttendance(
      attendance.map((participant) => {
        if (participant.selected) {
          return { ...participant, attendance: value };
        }
        return participant;
      })
    );
  };

  const handleCheckboxChange = (index, isSelected) => {
    const newAttendance = [...attendance];
    newAttendance[index].selected = isSelected;
    setAttendance(newAttendance);

    // Determine if the bulk action button should be shown
    const anySelected = newAttendance.some((p) => p.selected);
    setShowBulkAction(anySelected);
    setShowBulkActionDropdown(anySelected && showBulkActionDropdown);
  };
  const toggleBulkActionDropdown = () => {
    setShowBulkActionDropdown(!showBulkActionDropdown);
  };
  return (
    <div className="w-[480px] bg-white space-y-6 flex flex-col">
      <div className="p-4">
        <button
          onClick={toggleAttendanceDrawer(false)}
          className="flex space-x-2 justify-center items-center"
        >
          <MdNavigateBefore className="text-black" />
          <p className="font-inter font-semibold underline-offset-4 underline">
            Back
          </p>
        </button>
      </div>
      <div className="p-6 space-y-6">
        <div className="space-y-2 flex flex-col">
          <div>
            <p className="font-poppins text-base font-medium">
              Mark attendance for
            </p>
          </div>
          <div className="border-[#CFCDC9] p-4 border-[1px] rounded-2xl space-y-2 flex flex-col">
            <div className="font-poppins font-medium text-xl">
              Ted Talk Mornings
            </div>
            <div className="space-x-6 flex flex-row">
              <div className="text-[#9C9A96] flex space-x-1 justify-center items-center flex-row font-semibold text-xs font-inter">
                <div>
                  {" "}
                  <MdCalendarToday />
                </div>
                <div>
                  <p> Mar 4</p>
                </div>
              </div>
              <div className="text-[#9C9A96] flex flex-row space-x-1 justify-center items-center font-semibold text-xs font-inter">
                <div>
                  {" "}
                  <MdQueryBuilder />
                </div>
                <div>
                  <p> 11:00 am</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <div>
            <p className="font-poppins text-base font-medium">Participants</p>
          </div>

          <div className="flex flex-row justify-between">
            <button>
              <div className="flex w-[158px] flex-row space-x-2 p-2 text-[#F57D34]">
                <div>
                  <p className="font-inter underline underline-offset-2 font-semibold text-sm">
                    Add Participant
                  </p>
                </div>
                <div>
                  <FiPlus />
                </div>
              </div>
            </button>
            {showBulkAction && (
              <div className="flex justify-end">
                <div className="relative inline-block text-left">
                  <button
                    onClick={toggleBulkActionDropdown}
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[#F57D34] text-sm font-medium text-white hover:bg-[#F57D34] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-[#F57D34]"
                  >
                    Mark All As
                    <MdExpandMore
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                  {showBulkActionDropdown && (
                    <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                      <div className="py-1">
                        <button
                          onClick={() => handleBulkAttendanceChange("Present")}
                          className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                        >
                          Present
                        </button>
                        <button
                          onClick={() => handleBulkAttendanceChange("Absent")}
                          className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                        >
                          Absent
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div>
            {attendance.map((participant, index) => (
              <CommonAttendance
                key={participant.name}
                name={participant.name}
                selected={participant.selected}
                onAttendanceChange={handleAttendanceChange}
                onCheckboxChange={(isSelected) =>
                  handleCheckboxChange(index, isSelected)
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MarkAttendance;
