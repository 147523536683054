import { Drawer } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import UserGoals from "components/TAM/Laptop/UserGoals";
import { getMemberReport } from "config/APIs/project";
import { TaskStatus } from "helpers/task";
import { useEffect, useState } from "react";
import ReportsCard from "./ReportsCard";
import PerformanceCard from "./PerformanceCard";
ChartJS.register(ArcElement, Tooltip, Legend);

let chartData = {
  labels: ["Red", "Blue", "Yellow", "Green"],
  datasets: [
    {
      label: "# of Goals",
      data: [0, 0, 0, 0],
      backgroundColor: ["#C63434", "#BA7507", "#CE5511", "#008B46"],
      borderWidth: 1,
    },
  ],
};

export default function Individual({ member, projectId }) {
  const [stats, setStats] = useState({ goalsAllocation: chartData });
  const [fetching, setFetching] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const fetchStat = async () => {
    setFetching(true);
    try {
      const response = await getMemberReport(member?.id, { projectId });
      let data = response.data.data.goalStats;
      let chartStat = data?.goalCount;
      let label = TaskStatus?.map((s) => s?.label);

      let values = [];
      label?.map((l) => {
        let idx = chartStat?.findIndex(
          (c) => c?.status == TaskStatus?.find((t) => t?.label == l)?.value
        );
        if (idx) values.push(chartStat[idx]?.count);
        else values.push(0);
      });
      chartData.labels = label;
      chartData.datasets[0].data = values;
      console.log(chartData);
      data["goalsAllocation"] = chartData;

      setStats(data);
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => {
    if (member?.id) {
      fetchStat();
    }
  }, [member?.id]);

  return (
    <>
      <div className="w-full h-full border border-gray-100 rounded-md p-4 px-6 shadow-md">
        <div className="flex flex-row items-center justify-between space-x-2">
          <div
            className={`flex items-start gap-4 cursor-pointer text-black rounded-lg px-3 pb-5 hover:shadow`}
          >
            <img
              src={
                member?.image?.url ||
                "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg"
              }
              className={`h-8 w-8 rounded-full object-cover`}
            />
            <div>
              <h2 className="font-bold">{member?.first_name}</h2>
              <p className="text-sm capitalize">
                {member?.type || member?.userProjects?.type || "Member"}
              </p>
            </div>
          </div>
          <button
            onClick={() => setIsDrawerOpen(true)}
            className="w-max mb-auto flex items-center gap-1 border p-1.5 border-black rounded text-xs text-black"
          >
            View Goals
          </button>{" "}
        </div>
        <div className="grid grid-cols-[2fr,1fr] gap-4">
          <div className="flex flex-col gap-6">
            <ReportsCard type="completed" stats={stats} />
            <ReportsCard type="overdue" stats={stats} />
            <ReportsCard type="incomplete" stats={stats} />
            <ReportsCard type="total" stats={stats} />
          </div>
          <div className="">
            <PerformanceCard data={stats?.goalsAllocation} />
          </div>
        </div>
      </div>
      <Drawer
        anchor={window.innerWidth < 1024 ? "bottom" : "right"}
        PaperProps={{
          style: {
            borderRadius:
              window.innerWidth < 1024 ? "20px 20px 0px 0px" : "0px",
            maxHeight: "100vh",
            width: window.innerWidth < 1024 ? "100%" : "516px",
          },
        }}
        open={isDrawerOpen}
        closeModal={() => setIsDrawerOpen(false)}
        transitionDuration={250}
      >
        <UserGoals
          selectedUser={member}
          closeModal={() => setIsDrawerOpen(false)}
        />
      </Drawer>
    </>
  );
}
