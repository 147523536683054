import React from "react";
import { BiTargetLock } from "react-icons/bi";
import "../../assets/styles/scss/ReleaseCard.scss"

const ReleaseCard = () => {
  return (
    <div className="release-card__container">
      <div  className="release-card__container__icon-container">
        <div className="icon-container">
          <BiTargetLock />
          <div className="line-divider"></div>
        </div>
      </div>
      <div style={{width:"-webkit-fill-available"}} className="release-card__container__content-container">
        <div className="content-container flex flex-col gap-[10px]">
          <div className="description font-semibold">
            <p>Release Description in a line</p>
            <span className="hover:cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M7.02927 4.51L7.48927 4.97L2.95927 9.5H2.49927V9.04L7.02927 4.51ZM8.82927 1.5C8.70427 1.5 8.57427 1.55 8.47927 1.645L7.56427 2.56L9.43927 4.435L10.3543 3.52C10.5493 3.325 10.5493 3.01 10.3543 2.815L9.18427 1.645C9.08427 1.545 8.95927 1.5 8.82927 1.5ZM7.02927 3.095L1.49927 8.625V10.5H3.37427L8.90427 4.97L7.02927 3.095Z"
                  fill="#696763"
                />
              </svg>
            </span>
          </div>
          <p className="short-description">short description here</p>
          <p className="date w-fit">July 16, 2023</p>
        </div>
      </div>
    </div>
  );
};

export default ReleaseCard;
