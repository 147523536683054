import { useState } from "react";

import MySquad from "components/MySquad/SquadList";
import EmptyState from "components/Comman/EmptyState";
import SquadUser from "pages/MySquad/SquadUser";

function SquadPageLaptop() {
  const [selectedMember, setSelectedMember] = useState();
  return (
    <>
      <div className="max-h-95vh overflow-y-hidden w-full">
        <div className="w-full flex flex-row items-start justify-between space-x-4">
          <div className="w-1/3 bg-white rounded-[40px] mt-2.5 min-w-[315px]">
            <MySquad
              selectedMember={selectedMember}
              onSelect={(val) => {
                setSelectedMember(val);
              }}
            />
          </div>
          <div className="w-2/3">
            {selectedMember ? (
              <>
                <SquadUser
                  memberId={selectedMember?.id}
                  member={selectedMember}
                />
              </>
            ) : (
              <EmptyState
                imageLarge
                image="/assets/images/empty/member.svg"
                text="Select a member to view!"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SquadPageLaptop;
