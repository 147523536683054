import { pmoInstance } from "config";
import { instance, getHeader } from "config";

/**
 * Gives a Tasks
 * @param id --> id of User
 */
export const getTasksByUsers = (query) =>
  pmoInstance.get(
    `/tasks/byUser${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );

/**
 * Gives a Tasks Comments
 * @param id --> id of User
 */
export const getCommentsByUsersTasks = (id, query) =>
  pmoInstance.get(
    `/users/task/comments/${id}${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
/**
 * To create Tasks
 * body= {
   "title": "Latest Test Create3",
   "date": "2022-01-20T19:27:26.000Z",
   "status": "NotStarted",
   "category": "Tasks",
   "type_id": "c5db9b77-7ec7-41f8-91fd-bacf41b1fbae"
   }
 */
export const createTasks = (body) =>
  pmoInstance.post(`/task`, body, getHeader());

/**
 * To delete Tasks
 * @param id --> id of Tasks
 */
export const deleteTasks = (id) =>
  pmoInstance.delete(`/task/${id}`, getHeader());

export const bulkDeleteTasks = (ids) =>
  pmoInstance.patch(`/task/delete`, { ids }, getHeader());

/**
 * To get all Tasks
 * @param type --> Tasks
 */
export const getAllTasks = (query) =>
  pmoInstance.get(
    `/task${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );

/**
 * Gives a Tasks
 * @param id --> id of Tasks
 */
export const getTasksById = (id, query) =>
  pmoInstance.get(
    `/task/${id}${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );

/**
 * To update Tasks
 * @param id --> id of Tasks
 */
export const updateTasks = (id, body) =>
  pmoInstance.patch(`/task/${id}`, body, getHeader());

export const bulkUpdateTasks = (ids, body) =>
  pmoInstance.patch(`/task`, { ids, update: body }, getHeader());

/**
 * The following API has Pagination
 * @param {} query adds queries to the endpoints
 * Queries --> size, page, searchItem, sort&sort, task
 * Value for searchItem --> Search strings
 * Values for task --> Task ID
 * Values for sort --> | values in the object |&ASC/DESC
 */
export const getAllComments = async (query = {}) =>
  instance.get(
    `admin/task/comment/all?${new URLSearchParams(query)}`,
    getHeader()
  );

/**
 * Add a comment to a task
 */
export const addComment = async (body) =>
  instance.post("/admin/task/comment/add", body, getHeader());

/**
 *
 * @param {} userId
 * @returns Tasks for a particular users
 */
export const getUsersTasks = (userId, query = {}) =>
  pmoInstance.get(
    `/users/${userId}/task${
      Object.keys(query)?.length > 0 ? `?${new URLSearchParams(query)}` : ""
    }`,
    getHeader()
  );

/**
 *
 * @param {} taskId
 * @returns Members of a task
 */
export const getTasksUsers = (taskId) =>
  instance.get(`admin/task/user/${taskId}`, getHeader());

/**
 * Getting Task Type for the user
 */
export const getTaskTypeForUser = () =>
  instance.get(`admin/user/student/taskType`, getHeader());

export const getGeneralStats = (query = {}) =>
  instance.get(
    `/admin/task/analytics/stats/general?${new URLSearchParams(query)}`,
    getHeader()
  );

export const getTaskIssue = (query = {}) =>
  pmoInstance.get(`/tasks/issues?${new URLSearchParams(query)}`, getHeader());
