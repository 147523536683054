import React, { useState } from "react";
import EventTabs from "components/Comman/Tabs/EventTabs/EventTabs";
import Planner from "components/EventPlanner/Planner";
import Tracker from "components/EventPlanner/Tracker";

const EventPlanner = () => {
  const [activeTab, setActiveTab] = useState("Planner");

  return (
    <div className="space-y-4">
      <div>
        <EventTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div>
        {activeTab === "Planner" && <Planner />}
        {activeTab === "Tracking" && <Tracker />}
      </div>
    </div>
  );
};

export default EventPlanner;
