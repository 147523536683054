import React, { useEffect, useRef, useState } from "react";

import { XIcon } from "@heroicons/react/solid";
import ChronosButton from "components/Comman/Buttons";
function ConfirmCard({ isOpen, closeModal, onAccept, text, loader, id }) {
  const [showExpanded, setShowExpanded] = useState(false);
  const [openExpanded, setOpenExpanded] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    if (openExpanded) {
      setTimeout(() => {
        setShowExpanded(true);
      }, 50);
    } else {
      closeModal();
    }
  }, [openExpanded]);
  useEffect(() => {
    let ele = ref.current;
    if (!showExpanded) {
      ele.classList.add("opacity-0");
      ele.classList.remove("confirm-card-slide-in");
      ele.classList.add("confirm-card-slide-out");
      setTimeout(() => {
        setOpenExpanded(false);
      }, 500);
    } else {
      if (ele) {
        ele.classList.remove("opacity-0");
        ele.classList.remove("confirm-card-slide-out");
        ele.classList.add("confirm-card-slide-in");
      }
    }
  }, [showExpanded]);

  useEffect(() => {
    if (isOpen) setOpenExpanded(true);
    else setOpenExpanded(false);
  }, [isOpen]);

  return (
    <div
      className={`bg-transparent rounded-lg ${
        openExpanded ? "confirm-card-open" : "confirm-card-close"
      }`}
    >
      <div id={`showExpanded-${id}`} className={`p-3 opacity-0`} ref={ref}>
        <>
          <div className="flex flex-row items-end justify-between">
            <h5
              className="font-lato font-bold text-sm text-primary-gray-1000 flex flex-col items-start"
              id="exampleModalLiveLabel"
            >
              Confirmation!
            </h5>
          </div>

          <div className="text-left text-sm font-karla text-primary-gray-1000 mt-3">
            {React.cloneElement(text, {})}
          </div>
          <div className="mt-2.5 flex w-full flex-row items-center justify-end space-x-5">
            <ChronosButton
              loader={loader}
              text="Yes"
              secondary
              onClick={() => {
                onAccept();
                setShowExpanded(false);
              }}
            />

            <ChronosButton
              text="No"
              primary
              onClick={() => {
                let ele = document.getElementById(`showExpanded-${id}`);
                if (ele) {
                  ele.classList.remove("confirm-card-slide-in");
                  ele.classList.add("confirm-card-slide-out");
                }

                setTimeout(() => {
                  setShowExpanded(false);
                }, 50);
              }}
            />
          </div>
        </>
      </div>
    </div>
  );
}

export default ConfirmCard;
