import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  release: [],
  projectWiseRelease: [],
  thisWeek: { fetching: false, list: [], projectWiseRelease: [] },
  thisMonth: { fetching: false, list: [], projectWiseRelease: [] },
  thisQuarter: { fetching: false, list: [], projectWiseRelease: [] },
};

// Storing User's Tasks
export const release = createSlice({
  name: "release",
  initialState,
  reducers: {
    updateReleaseList: (state, action) => {
      let type = action.payload.type;
      let list = action.payload.list;
      let projectWise = action.payload.projectWise;
      let fetching = action.payload.fetching;
      if (fetching !== null && fetching != undefined) {
        state[type].fetching = fetching;
      }
      if (list?.length > 0) {
        state[type].list = list;
        let t = [...state.release];
        t = t.concat(list);
        t = [...new Map(t.map((item) => [item["id"], item])).values()];
        state.release = t;
      }
      if (projectWise?.length > 0) {
        state[type].projectWiseRelease = projectWise;
        let t = [...state.projectWiseRelease];
        t = t.concat(projectWise);
        t = [...new Map(t.map((item) => [item["id"], item])).values()];
        state.projectWiseRelease = t;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateReleaseList } = release.actions;

export default release.reducer;
