import React from "react";

const Card = ({ Heaading }) => {
  return (
    <>
      <div
        className="flex py-5 px-3  flex-col items-start gap-[32px] flex-1 rounded-xl"
        style={{
          boxShadow:
            "0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.10), 0px 0px 16px 0px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div>
          <div className="text-base font-semibold text-neutral-500">
            {Heaading}
          </div>
          <span
            style={{ fontSize: "10px", fontFamily: "Inter" }}
            className="font-light"
          >
            No of hours collectively clocked in today
          </span>
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="text-neutral-800 text-center font-inter font-semibold text-2xl leading-none tracking-wider">
            {Heaading === "Total Hours" ? "27h 32m" : "150"}
          </div>
          {Heaading == "Total Hours" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12H4C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12C20 7.582 16.418 4 12 4C9.536 4 7.332 5.114 5.865 6.865L8 9H2V3L4.447 5.446C6.28 3.336 8.984 2 12 2ZM13 7V11.585L16.243 14.828L14.828 16.243L11 12.413V7H13Z"
                fill="#816FE9"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7.5 15.75C7.5 15.5511 7.57902 15.3603 7.71967 15.2197C7.86032 15.079 8.05109 15 8.25 15H11.25C11.4489 15 11.6397 15.079 11.7803 15.2197C11.921 15.3603 12 15.5511 12 15.75C12 15.9489 11.921 16.1397 11.7803 16.2803C11.6397 16.421 11.4489 16.5 11.25 16.5H8.25C8.05109 16.5 7.86032 16.421 7.71967 16.2803C7.57902 16.1397 7.5 15.9489 7.5 15.75ZM7.5 12.75C7.5 12.5511 7.57902 12.3603 7.71967 12.2197C7.86032 12.079 8.05109 12 8.25 12H15.75C15.9489 12 16.1397 12.079 16.2803 12.2197C16.421 12.3603 16.5 12.5511 16.5 12.75C16.5 12.9489 16.421 13.1397 16.2803 13.2803C16.1397 13.421 15.9489 13.5 15.75 13.5H8.25C8.05109 13.5 7.86032 13.421 7.71967 13.2803C7.57902 13.1397 7.5 12.9489 7.5 12.75ZM7.5 9.75C7.5 9.55109 7.57902 9.36032 7.71967 9.21967C7.86032 9.07902 8.05109 9 8.25 9H15.75C15.9489 9 16.1397 9.07902 16.2803 9.21967C16.421 9.36032 16.5 9.55109 16.5 9.75C16.5 9.94891 16.421 10.1397 16.2803 10.2803C16.1397 10.421 15.9489 10.5 15.75 10.5H8.25C8.05109 10.5 7.86032 10.421 7.71967 10.2803C7.57902 10.1397 7.5 9.94891 7.5 9.75ZM7.5 6.75C7.5 6.55109 7.57902 6.36032 7.71967 6.21967C7.86032 6.07902 8.05109 6 8.25 6H15.75C15.9489 6 16.1397 6.07902 16.2803 6.21967C16.421 6.36032 16.5 6.55109 16.5 6.75C16.5 6.94891 16.421 7.13968 16.2803 7.28033C16.1397 7.42098 15.9489 7.5 15.75 7.5H8.25C8.05109 7.5 7.86032 7.42098 7.71967 7.28033C7.57902 7.13968 7.5 6.94891 7.5 6.75Z"
                fill="#ABE085"
              />
              <path
                d="M4.5 0H19.5C20.2956 0 21.0587 0.316071 21.6213 0.87868C22.1839 1.44129 22.5 2.20435 22.5 3V21C22.5 21.7956 22.1839 22.5587 21.6213 23.1213C21.0587 23.6839 20.2956 24 19.5 24H4.5C3.70435 24 2.94129 23.6839 2.37868 23.1213C1.81607 22.5587 1.5 21.7956 1.5 21V19.5H3V21C3 21.3978 3.15804 21.7794 3.43934 22.0607C3.72064 22.342 4.10218 22.5 4.5 22.5H19.5C19.8978 22.5 20.2794 22.342 20.5607 22.0607C20.842 21.7794 21 21.3978 21 21V3C21 2.60218 20.842 2.22064 20.5607 1.93934C20.2794 1.65804 19.8978 1.5 19.5 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V4.5H1.5V3C1.5 2.20435 1.81607 1.44129 2.37868 0.87868C2.94129 0.316071 3.70435 0 4.5 0Z"
                fill="#ABE085"
              />
              <path
                d="M1.5 7.5V6.75C1.5 6.55109 1.57902 6.36032 1.71967 6.21967C1.86032 6.07902 2.05109 6 2.25 6C2.44891 6 2.63968 6.07902 2.78033 6.21967C2.92098 6.36032 3 6.55109 3 6.75V7.5H3.75C3.94891 7.5 4.13968 7.57902 4.28033 7.71967C4.42098 7.86032 4.5 8.05109 4.5 8.25C4.5 8.44891 4.42098 8.63968 4.28033 8.78033C4.13968 8.92098 3.94891 9 3.75 9H0.75C0.551088 9 0.360322 8.92098 0.21967 8.78033C0.0790176 8.63968 0 8.44891 0 8.25C0 8.05109 0.0790176 7.86032 0.21967 7.71967C0.360322 7.57902 0.551088 7.5 0.75 7.5H1.5ZM1.5 12V11.25C1.5 11.0511 1.57902 10.8603 1.71967 10.7197C1.86032 10.579 2.05109 10.5 2.25 10.5C2.44891 10.5 2.63968 10.579 2.78033 10.7197C2.92098 10.8603 3 11.0511 3 11.25V12H3.75C3.94891 12 4.13968 12.079 4.28033 12.2197C4.42098 12.3603 4.5 12.5511 4.5 12.75C4.5 12.9489 4.42098 13.1397 4.28033 13.2803C4.13968 13.421 3.94891 13.5 3.75 13.5H0.75C0.551088 13.5 0.360322 13.421 0.21967 13.2803C0.0790176 13.1397 0 12.9489 0 12.75C0 12.5511 0.0790176 12.3603 0.21967 12.2197C0.360322 12.079 0.551088 12 0.75 12H1.5ZM1.5 16.5V15.75C1.5 15.5511 1.57902 15.3603 1.71967 15.2197C1.86032 15.079 2.05109 15 2.25 15C2.44891 15 2.63968 15.079 2.78033 15.2197C2.92098 15.3603 3 15.5511 3 15.75V16.5H3.75C3.94891 16.5 4.13968 16.579 4.28033 16.7197C4.42098 16.8603 4.5 17.0511 4.5 17.25C4.5 17.4489 4.42098 17.6397 4.28033 17.7803C4.13968 17.921 3.94891 18 3.75 18H0.75C0.551088 18 0.360322 17.921 0.21967 17.7803C0.0790176 17.6397 0 17.4489 0 17.25C0 17.0511 0.0790176 16.8603 0.21967 16.7197C0.360322 16.579 0.551088 16.5 0.75 16.5H1.5Z"
                fill="#ABE085"
              />
            </svg>
          )}
        </div>
      </div>
    </>
  );
};

export default Card;
