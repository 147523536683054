import Tabs from "./Tabs"
import React ,{useState} from 'react'
import GoalCard from "components/SprintPlanner/GoalCard";

const tabs = [
    { label: "Goals", value: "all" },
    { label: "Time Sheet", value: "my" },
  ];

const Menubar = () => {
    const [selectedTab, setSelectedTab] = useState("all");
  return (
    <>
    <Tabs
    tabs={tabs}
    selectedTab={selectedTab}
    setSelectedTab={setSelectedTab}
  />

<GoalCard />
</>
  )
}

export default Menubar