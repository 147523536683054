import { pmoInstance } from "config";
import { getHeader, instance } from "config";

/**
 * The following API has Pagination
 * @param {} query adds queries to the endpoints
 * Queries --> size, page, searchItem, sort&sort, rank_type, is_active, college, role_type
 * Value for searchItem --> Search strings
 * Values for is_active --> true/false
 * Values for rank_type -->
 * Values for sort --> college/rank_name/role_name/caarya_id/date_of_joining/discord_id/mentor&ASC/DESC
 */
export const getAllUsersAdmin = (query = {}) => {
  return pmoInstance.get(`/users?` + new URLSearchParams(query), getHeader());
};

export const getTAMList = (query = {}) => {
  return pmoInstance.get(
    `/users/tam?` + new URLSearchParams(query),
    getHeader()
  );
};

export const getUsersProjects = (id, query = {}) => {
  return pmoInstance.get(
    `/users/${id}/project?` + new URLSearchParams(query),
    getHeader()
  );
};

export const updateUsersProjects = (id, body) => {
  return pmoInstance.patch(`/users/${id}/project`, body, getHeader());
};
export const getUserGoalComment = (id) => {
  return pmoInstance.get(`/users/goal/comments/${id}`, getHeader());
};

export const getUserGoals = (id, query) => {
  return pmoInstance.get(
    `/users/${id}/goal?` + new URLSearchParams(query),
    getHeader()
  );
};
