import CommonTrackSection from "./CommonTrackSection";

const TrackSection = () => {
  return (
    <div className="flex space-y-10 flex-col justify-center">
      <div className="flex justify-center items-center">
        <p className="text-[#F57D34] font-semibold text-sm">
          4th March, 2024 - 10th March, 2024
        </p>
      </div>
      <div className="flex justify-center">
        <CommonTrackSection />
      </div>
    </div>
  );
};
export default TrackSection;
