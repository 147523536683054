import React from "react";
import SprintCard from "./SprintCard";
import Members from "./Members";

const ReportCardLaptop = () => {
  return (
    <>
      <div className="relative ">
        <div className="grid grid-cols-2 gap-6 mt-5">
        <Members />
        <SprintCard />
      </div>
      </div>
    </>
  );
};

export default ReportCardLaptop;
