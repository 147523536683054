import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

import ProjectTabs from "./Tabs";

const FilterItem = ({ type, message, handleClick }) => {
  return (
    <div className="relative p-2 px-3 bg-white border border-gray-300 rounded-lg text-xs font-lato">
      <AiOutlineClose
        onClick={handleClick}
        className="w-5 h-5 absolute -top-3 -right-3 text-white bg-gray-200 rounded-full p-1"
      />
      <strong>{type}: </strong> {message}
    </div>
  );
};

function ProjectTaskManagemnet({
  permissions,
  project,
  viewAllTask,
  setViewAllTask,
}) {
  const [selectedRelease, setSelectedRelease] = useState();
  const [selectedEpic, setSelectedEpic] = useState();
  const [selectedStory, setSelectedStory] = useState();
  const [selectedTab, setSelectedTab] = useState("Goals");
  const [tabs, setTabs] = useState([
    { label: "Goals", value: "Goals" },
    { label: "Members", value: "members" },
    { label: "Summary", value: "summary" },
  ]);

  return (
    <>
      <div id="project-tabs" className="lg:px-0">
        <ProjectTabs
          selectedTab={selectedTab}
          setSelectedTab={(val) => {
            setSelectedTab(val);
          }}
          tabs={tabs}
          project={project}
          permissions={permissions}
          goal={selectedRelease}
          setSelectedRelease={setSelectedRelease}
          // Epic={selectedEpic?.id === 0 ? null : selectedEpic}
          epic={selectedEpic}
          story={selectedStory?.id === 0 ? null : selectedStory}
          setSelectedEpic={(val) => {
            setSelectedEpic(val);
            // SetSelectedTab("Stories");
          }}
          setSelectedStory={(val) => {
            setSelectedStory(val);
            setSelectedTab("To-Do");
          }}
        />
      </div>
    </>
  );
}

export default ProjectTaskManagemnet;
