import React from "react";
import Card from "./MemberCard/Card";
import SearchBox from "components/Comman/SearchBox";
import { useState } from "react";
import PostCard from "./MemberCard/PostCard";
const Members = () => {
  const [searchItem, setSearchItem] = useState("");
  return (
    <>
      <div className="w-full flex flex-col h-[88vh] border border-gray-100 rounded-2xl py-8 px-4 gap-[40px]">
        <div
          style={{ lineHeight: "150%" }}
          className="  text-xl text-black font-bold "
        >
          Members
        </div>
        <div className="flex justify-evenly gap-[10px] items-center w-full">
          <Card Heaading="Total Hours" />
          <Card Heaading="Total Session" />
        </div>

        <SearchBox
          placeholder="Search for members..."
          search={searchItem}
          setSearch={setSearchItem}
        />

        <PostCard />
      </div>
    </>
  );
};

export default Members;
