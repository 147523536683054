import { TaskStatus } from "helpers/task";
import { useEffect, useState } from "react";
import Item, { ItemLoader } from "./Item";

function PlannerList({
  category,
  groupBy,
  goals,
  heading,
  selectedTasks,
  setSelectedTasks,
  updateTasks,
  setSelectedTaskId,
}) {
  const [list, setList] = useState({ name: "", tempList: [] });

  useEffect(() => {
    getListToShow();
  }, [goals, category, groupBy]);

  const getListToShow = () => {
    let tempList = goals[category] || [];
    let l = {};

    if (goals && tempList?.length > 0) {
      switch (groupBy) {
        case "project":
          let projects = [];
          tempList?.map((p) => {
            if (projects?.find((t) => t?.id == p?.project?.id)) {
            } else {
              projects?.push({ ...p?.project, name: p?.project?.title });
            }
          });
          projects?.map((p) => {
            p["goals"] = tempList?.filter((g) => g?.project?.id == p?.id);
          });
          l["tempList"] = projects;
          break;
        case "priority":
          let priority = [];
          tempList?.map((p) => {
            if (priority?.find((t) => t?.value == p?.priority)) {
            } else {
              priority?.push({
                value: p?.priority,
                name: `Priority ${p?.priority}`,
              });
            }
          });
          priority?.map((p) => {
            p["goals"] = tempList?.filter((g) => g?.priority == p?.value);
          });
          l["tempList"] = priority;
          break;
        case "status":
          let status = [];
          tempList?.map((p) => {
            if (status?.find((t) => t?.value == p?.status)) {
            } else {
              status?.push({
                value: p?.status,
                name: TaskStatus?.find((s) => s?.value == p?.status)?.label,
              });
            }
          });
          status?.map((p) => {
            p["goals"] = tempList?.filter((g) => g?.status == p?.value);
          });
          l["tempList"] = status;
          break;
        case "owner":
          let owners = [];
          tempList?.map((p) => {
            if (owners?.find((t) => t?.value == p?.ownerId)) {
            } else {
              owners?.push({
                value: p?.ownerId,
                name: p?.owner?.first_name || "Not Assigned",
              });
            }
          });
          owners?.map((p) => {
            p["goals"] = tempList?.filter((g) => g?.ownerId == p?.value);
          });
          l["tempList"] = owners;
          break;
        default:
          l["tempList"] = [{ name: "", goals: tempList }];
      }
      setList({ ...l });
    }
  };

  return (
    <>
      <div>
        <div className="flex justify-between">
          <div className="text-xs font-medium text-primary-neutral-500 rounded-md inline-flex items-center font-poppins">
            {heading}
          </div>
          {selectedTasks.length > 0 && (
            <div className="flex gap-2 font-lato">
              <div
                className="border text-xs border-primary-neutral-400 text-primary-neutral-800 font-inter px-3 py-2 rounded-lg font-semibold inline-flex items-center cursor-pointer"
                onClick={() => updateTasks("move_to_tomorrow")}
              >
                Move to Tomorrow
              </div>
              <div
                className="border text-xs border-primary-neutral-400 text-primary-neutral-800 font-inter px-3 py-2 rounded-lg font-semibold inline-flex items-center cursor-pointer"
                onClick={() => updateTasks("move_to_next_week")}
              >
                Move to Next Week
              </div>
              <div
                className="border text-xs font-inter border-primary-red-medium text-primary-red-medium px-3 py-2 rounded-lg font-semibold inline-flex items-center cursor-pointer"
                onClick={() => updateTasks("delete")}
              >
                Delete
              </div>
            </div>
          )}
        </div>
        {list?.tempList?.length > 0 &&
          list?.tempList?.map((l) => {
            return (
              <div className="mt-6">
                {l?.name && l?.name !== "" && (
                  <p className="text-sm font-karla mb-2.5">{l?.name}</p>
                )}
                <div
                  className={`relative py-2 border text-xs group rounded-t-[12px] border-gray-100 bg-primary-neutral-100 text-black font-semibold grid grid-cols-12 gap-4 cursor-pointer leading-[18px] tracking-[0.24px]`}
                >
                  <div className="flex flex-row space-x-4 items-center col-span-6">
                    <span className="max-w-[80%] py-2 px-[16px] text-left">
                      Goal Name
                    </span>
                  </div>
                  <div className="col-span-6 grid grid-cols-8 gap-4">
                    <div className="col-span-3  p-2 text-left">Status</div>

                    <span className="col-span-3  p-2 text-left">
                      Assigned To
                    </span>
                    <span className={`col-span-2  p-2 text-left  `}>
                      Prioritize
                    </span>
                  </div>
                </div>
                <div className="rounded-b-[12px] py-4 px-2 flex flex-col w-full border border-primary-neutral-100 space-y-4">
                  {l?.goals?.map((item, idx) => {
                    return (
                      <Item
                        idx={idx}
                        task={item}
                        onSelect={() =>
                          setSelectedTasks((prev) => [...prev, item])
                        }
                        onDeselect={() =>
                          setSelectedTasks((prev) =>
                            prev.filter((i) => i.id !== item.id)
                          )
                        }
                        selectedTask={selectedTasks?.find(
                          (t) => t?.id == item?.id
                        )}
                        setSelectedTaskId={setSelectedTaskId}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default PlannerList;
