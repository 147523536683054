import React, { useEffect, useState } from "react";
import * as GoalAPI from "config/APIs/task/goal";
import ChronosButton from "components/Comman/Buttons";
import { PlusIcon } from "@heroicons/react/solid";
import EmptyTasks from "assets/icons/EmptyTasks.png";
import GoalCard from "components/Goals/Card/GoalCard";
import GoalCreateModal from "components/Goals/Modals/GoalCreate";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const GoalsTab = ({ project, projectTab, hideGoals, setTasksView }) => {
  const history = useHistory();
  const [tasks, setTasks] = useState([]);
  const [originalTasks, setOriginalTasks] = useState([]);
  const [openMobileCreateModal, setOpenMobileCreateModal] = useState(false);

  const [fetching, setFetching] = useState(false);
  const [taskToDelete, setTaskToDelete] = React.useState();

  const fetchGoals = async () => {
    setFetching(true);
    try {
      let query = {};

      if (project) {
        query["projectId"] = project?.id;
      }

      const response = await GoalAPI.getAllGoals(query);
      const fetchedSubTasks = response.data.data?.response;
      console.log("FetchedSubTasks", fetchedSubTasks);
      setOriginalTasks(fetchedSubTasks);
      setTasks(fetchedSubTasks);
    } catch (err) {
      console.log("Subtask fetch error", err);
    }
    setFetching(false);
  };

  useEffect(() => {
    if (projectTab) {
      if (project?.id) fetchGoals({ page: 0 });
    } else {
      fetchGoals({ page: 0 });
    }
  }, [project]);

  const handleDeleteGoal = async () => {
    try {
      await GoalAPI.deleteGoal(taskToDelete.id);
      setOriginalTasks((state) =>
        state.filter((e) => e.id !== taskToDelete.id)
      );
      setTaskToDelete(null);
    } catch (err) {
      console.log("Task delete error", err);
    }
  };

  return (
    <>
      {/* <EditStory
        epic={epic}
        project={project}
        goal={goal || null}
        isOpen={selectedStoryToEdit ? true : false}
        closeModal={() => setSelectedStoryToEdit()}
        onUpdate={() => {}}
        editValues={selectedStoryToEdit}
        isEdit={true}
        onDelete={(val) => {
          setSelectedStoryToDelete(val);
        }}
      />*/}
      <GoalCreateModal
        closeModal={() => {
          setOpenMobileCreateModal(false);
        }}
        isOpen={openMobileCreateModal}
        onCreate={() => {
          fetchGoals();
        }}
        project={project}
      />

      <div className="md:absolute top-0 right-0 py-2 mb-3">
        <div className="block">
          <div className="flex flex-row items-center justify-between">
            <ChronosButton
              primary
              text="Add new Goal"
              icon={<PlusIcon className="w-4 h-4 ml-1.5" />}
              onClick={() => setOpenMobileCreateModal(true)}
            />
          </div>
        </div>
      </div>
      <div className="hidden md:block py-2 mb-3"></div>

      <div className="mb-4 rounded-xl">
        {tasks.length > 0 ? (
          <div className="space-y-5">
            {tasks?.map((item, idx) => {
              return (
                <GoalCard
                  index={idx}
                  item={item}
                  onUpdate={() => {
                    fetchGoals();
                  }}
                  onClick={() => {
                    history.push(`/goals/${item?.id}`);
                  }}
                />
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center items-center flex-col pt-6 gap-2">
            <p className="text-gray-500 text-sm">
              The project has no goals to show
            </p>
            <div className="relative h-[149px] w-[192px] overflow-hidden">
              <img
                src={EmptyTasks}
                alt="empty epics"
                className="relative h-full w-full object-contain"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GoalsTab;
