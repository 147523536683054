import ChronosButton from "components/Comman/Buttons";
import React, { useState } from "react";

function UserCard({ item, selectedUser, setSelectedUser }) {
  const [viewMore, setViewMore] = useState(false);

  return (
    <div
      onClick={() => {
        setSelectedUser(item);
      }}
      className={`bg-gray-50 rounded-3xl cursor-pointer ${
        item?.id == selectedUser?.id
          ? "border border-primary-orange-medium"
          : ""
      }`}
    >
      <div className="flex p-4">
        <div>
          <img
            src={item?.image?.url || "/assets/images/defaultUser.png"}
            className="w-10 h-10 rounded-3xl mr-4 object-cover"
          />
        </div>
        <div>
          {item?.first_name}
          <div className="text-[#696763] text-xs">
            {item?.designation?.designation_name}
          </div>
        </div>
      </div>
      {item?.projects?.length > 0 && (
        <div className="flex flex-row flex-wrap p-4 -ml-1">
          {viewMore ? (
            <>
              {item?.projects?.map((p) => {
                return (
                  <div className="text-xs p-2 rounded-3xl border border-gray-200 m-1">
                    {p?.title}
                  </div>
                );
              })}
              {viewMore == true && (
                <div className="pl-2 pt-1">
                  <ChronosButton
                    tertiary
                    text="View Less"
                    onClick={() => setViewMore(false)}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {item?.projects?.slice(0, 4)?.map((p) => {
                return (
                  <div className="text-xs p-2 rounded-3xl border border-gray-200 m-1">
                    {p?.title}
                  </div>
                );
              })}

              {item?.projects?.length > 4 && (
                <div className="pl-2 pt-1">
                  <ChronosButton
                    tertiary
                    text="View More"
                    onClick={() => setViewMore(true)}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default UserCard;
