import DeliveryPlannerLaptop from "components/Planner/DeliveryPlanner/LaptopPage";

export default function DeliveryPlanner() {
  return (
    <>
      <div className="block lg:hidden"></div>
      <div className="hidden lg:block">
        <DeliveryPlannerLaptop />
      </div>
    </>
  );
}
