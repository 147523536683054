function StatsCard({ count, label = "", onClick }) {
  return (
    <div
      onClick={() => {
        if (onClick) onClick();
      }}
      className="p-5 flex flex-row items-center bg-white shadow-container rounded-20px text-primary-gray-1000"
    >
      <h1 className="text-2xl font-bold w-1/3 text-center">{count || 0}</h1>
      <p className="text-sm">{label}</p>
    </div>
  );
}

export default StatsCard;
