import React from "react";
import ReactApexChart from "react-apexcharts";

const ClockIn = () => {
  const generateData = (count, range) => {
    const data = [];
    for (let i = 0; i < count; i++) {
      // Generate random value within the specified range
      const value =
        Math.floor(Math.random() * (range.max - range.min + 1)) + range.min;
      data.push(value);
    }
    return data;
  };

  return (
    <div className="p-4 grid grid-cols-3 gap-6 ">
      <div className="border rounded-[16px] border-neutral-300 flex-col p-4 col-span-2">
        <p className="text-[14px] font-semibold font-inter text-[#363430]">
          Clock In Overview
        </p>
        <ReactApexChart
          options={{
            chart: {
              height: 350,
              type: "heatmap",
            },
            dataLabels: {
              enabled: false,
            },
            colors: ["#5E4FBA"],
            xaxis: {
              categories: ["S", "M", "T", "W", "T", "F", "S"],
              labels: {
                style: {
                  fontSize: "12px",
                  fontWeight: 600,
                  fontFamily: "Inter",
                },
              },
            },
          }}
          series={[
            { name: "Daylight", data: generateData(7, { min: 0, max: 90 }) },
            { name: "Sundown", data: generateData(7, { min: 0, max: 90 }) },
            { name: "Daylight", data: generateData(7, { min: 0, max: 90 }) },
          ]}
          type="heatmap"
          height={350}
        />
      </div>
      <div className="flex flex-col border rounded-[16px] border-neutral-300 p-4">
        <p className="text-[14px] font-semibold font-inter text-[#363430] pb-14">
          Hours Clocked In
        </p>
        <ReactApexChart
          options={{
            colors: [
              "#8B2F8A",
              "#CA498C",
              "#FDE3DF",
              "#E6BFCE",
              "#CF9BBD",
              "#B977AC",
              "#A2539B",
            ],
            chart: {
              width: 380,
              type: "pie",
            },
            labels: [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thrusday",
              "Friday",
              "Saturday",
            ],
            dataLabels: {
              enabled: false,
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          }}
          series={[44, 55, 13, 43, 22]}
          type="pie"
          width={380}
        />
      </div>
    </div>
  );
};

export default ClockIn;
