import Members from "./Members";
import Projects from "../Planner/Common/Projects";
import Individual from "./Individual";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
export default function MemberControl() {
  const projects = useSelector((state) => state.projects.list);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  useEffect(() => {
    if (projects.length > 0) {
      setSelectedProject(projects[0]);
      setSelectedMember(
        projects[0].owner ? projects[0].owner : projects[0].members[0]
      );
    }
  }, [projects]);
  return (
    <div className="relative ">
      <div className="min-h-[70vh] grid grid-cols-3 gap-6 mt-5">
        <Projects
          projects={projects}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />
        <Members
          project={selectedProject}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
        />
        <Individual member={selectedMember} projectId={selectedProject?.id} />
      </div>
    </div>
  );
}
