import { MdDone } from "react-icons/md";

const CommonTextfield = ({ label, completed, placeholder }) => {
  return (
    <div className="space-y-2">
      <div className="flex flex-row justify-between items-center">
        <div>
          <p className="font-medium text-xs font-poppins text-[#363430]">
            {label}
          </p>
        </div>
        {completed && (
          <div className="bg-[#363430] flex justify-center items-center p-[2px] rounded-[96px] h-4 w-4">
            <MdDone className="text-[white]" />
          </div>
        )}
      </div>
      <div>
        <input
          type="text"
          className={`w-full border-[#CFCDC9] border-[1px] text-[#363430] text-sm font-light rounded-lg p-4 h-[53px] ${
            completed ? "bg-[#f3f3f3]" : ""
          }`}
          placeholder={placeholder}
          disabled={completed}
        />
      </div>
    </div>
  );
};

export default CommonTextfield;
