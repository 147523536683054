import React from 'react'
import SprintPageMobile from "components/SprintPlanner/SprintPageMobile";
import SprintPageLaptop from "components/SprintPlanner/SprintPageLaptop";

const index = () => {
  return (
    <>
    <div className="block lg:hidden">
      <SprintPageMobile />
    </div>

    <div className="hidden lg:block mt-2.5">
      <SprintPageLaptop />
    </div>
  </>
  )
}

export default index