import React from 'react'
import GraphCard from "./SprintCard/GraphCard"
import Menubar from "./SprintCard/Menubar"

const SprintCard = () => {
  return (
    <>
      <div className="w-full flex flex-col h-[88vh] border border-gray-100 rounded-2xl py-8 px-4 gap-[40px]">
        <div
          style={{ lineHeight: "150%" }}
          className="  text-xl text-black font-bold "
        >
          Sprint Card
        </div>
        <GraphCard />
        <Menubar />
      </div>


    </>
  )
}

export default SprintCard