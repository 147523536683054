import React, { useEffect, useState } from "react";

import { Plus, Target } from "@phosphor-icons/react";
import Drawer from "@mui/material/Drawer";
import { BiChevronDown } from "react-icons/bi";
import ChronosButton from "components/Comman/Buttons";
import { Listbox } from "@headlessui/react";
import DateInput from "components/Comman/Inputs/DateInput";
import { bulkUpdateGoals, getAllGoals } from "config/APIs/task/goal";
import GoalCreate from "components/Modals/Goal/GoalCreate";
import { addOrRemove } from "helpers/array";
import moment from "moment";

function AssignGoal({ isOpen, closeModal, user, onUpdate }) {
  const [selectedProject, setSelectedProject] = useState();
  const [dueDate, setDueDate] = useState();
  const [goals, setGoals] = useState([]);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const fetchGoal = async () => {
    getAllGoals({ projectId: selectedProject?.id })
      .then((res) => {
        let resp = res.data.data?.response?.filter(
          (g) => g?.owners == null || g?.owners?.length == 0
        );
        setGoals(resp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const assignGoal = async () => {
    let body = {
      ownerId: user?.id,
      dueDate: moment(dueDate).format("YYYY-MM-DD"),
    };
    let ids = selectedGoals?.map((g) => g?.id);
    bulkUpdateGoals(ids, body)
      .then((res) => {
        setSelectedGoals([]);
        closeModal();
        onUpdate();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedProject(user?.projects[0] || null);
    }
  }, [isOpen]);
  useEffect(() => {
    fetchGoal();
  }, [selectedProject]);

  return (
    <>
      <GoalCreate
        projectType={selectedProject?.type}
        project={selectedProject}
        isOpen={openCreateModal}
        closeModal={() => setOpenCreateModal(false)}
        onCreate={() => {
          fetchGoal();
          onUpdate();
        }}
        ownerId={user?.id}
      />

      <Drawer
        anchor={window.innerWidth < 1024 ? "bottom" : "right"}
        PaperProps={{
          style: {
            borderRadius:
              window.innerWidth < 1024 ? "20px 20px 0px 0px" : "0px",
            maxHeight: "100vh",
            width: window.innerWidth < 1024 ? "100%" : "516px",
          },
        }}
        open={isOpen}
        onClose={() => closeModal()}
        transitionDuration={250}
      >
        <div className="pb-5">
          <div
            onClick={() => {
              closeModal();
            }}
            className="p-4 flex flex-row items-center space-x-1"
          >
            <BiChevronDown className="w-6 transform rotate-90 scale-150" />
            <p className="text-sm font-semibold font-karla text-primary-gray-600 underline cursor-pointer">
              Back
            </p>
          </div>
          <div className="px-10 space-y-8">
            <div className="py-2">
              <h1 className="font-karla text-xl text-black font-medium">
                Assign New Goal to
              </h1>
              <div className="mt-5 flex flex-row items-start space-x-4">
                <img
                  src={user?.image?.url || "/assets/images/defaultUser.png"}
                  className="w-10 h-10 rounded-full object-cover"
                />

                <div className="text-black font-karla font-semibold">
                  {user?.first_name}
                  <div className="text-gray-500 text-sm font-normal font-lato">
                    {user?.designation?.designation_name}
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-4">
              <div className="text-primary-gray-600 font-lato font-semibold">
                Select project
              </div>
              <Listbox value={selectedProject} onChange={setSelectedProject}>
                <Listbox.Button className="w-full">
                  <div className="border-b border-primary-gray-250 py-2 px-4 w-full flex flex-row items-center justify-between space-x-4">
                    <div className=" flex flex-row items-center justify-start space-x-4">
                      <img
                        src={
                          selectedProject?.image?.url ||
                          "/assets/svg/rocket.svg"
                        }
                        className="rounded-full bg-gray-100 w-8 h-8"
                      ></img>
                      <h2 className="font-semibold font-karla text-black">
                        {selectedProject?.title || "Unassigned Project"}
                      </h2>
                    </div>
                    <BiChevronDown className="transform w-6 scale-150" />
                  </div>
                </Listbox.Button>
                <Listbox.Options className="space-y-2">
                  {user?.projects?.map((project) => (
                    <Listbox.Option key={project.id} value={project}>
                      <div className="px-4 flex flex-row items-center justify-start space-x-4 cursor-pointer">
                        <img
                          src={project?.image?.url || "/assets/svg/rocket.svg"}
                          className="rounded-full bg-gray-100 w-8 h-8"
                        ></img>
                        <h2 className="font-semibold font-karla text-black">
                          {project.title || "Unassigned Project"}
                        </h2>
                      </div>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>
            </div>
            {selectedProject && (
              <>
                <div className="space-y-4">
                  <div className="text-primary-gray-600 font-lato font-semibold">
                    Select a goal/goals
                  </div>
                  <div className="space-y-4">
                    <ChronosButton
                      underline
                      tertiary
                      reverseIcon
                      icon={<Plus size={12} className="mr-2" />}
                      text="Create New Goal"
                      onClick={() => {
                        setOpenCreateModal(true);
                      }}
                    />

                    {goals?.map((goal) => {
                      return (
                        <div
                          onClick={() => {
                            let g = addOrRemove([...selectedGoals], goal);
                            setSelectedGoals(g);
                          }}
                          className={`border ${
                            selectedGoals?.find((g) => g?.id == goal?.id)
                              ? "border-primary-orange-medium"
                              : "border-primary-gray-80"
                          } bg-white rounded-lg p-4 w-full space-y-2`}
                        >
                          <div className="flex bg-primary-gray-50 border border-gray-80 rounded px-2 py-0.5 space-x-2 max-w-max flex-row items-center">
                            <Target className="w-4 h-4 text-primary-red-500" />
                            <span className="font-semibold font-karla text-3xs text-primary-gray-600">
                              Goal
                            </span>
                          </div>
                          <div className="text-primary-gray-600 font-karla text-sm w-full">
                            {goal?.title || ""}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="text-primary-gray-600 font-lato font-semibold">
                    Due On
                  </div>
                  <div className="border-b w-full border-primary-gray-250 rounded-t-lg border-t-0 border-l-0 border-r-0 bg-white text-sm text-primary-gray-600 max-w-max">
                    <DateInput
                      noBorder
                      date={dueDate}
                      onChange={(val) => {
                        setDueDate(val);
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center">
                  <ChronosButton
                    primary
                    text="Assign Goal"
                    onClick={() => {
                      assignGoal();
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default AssignGoal;
