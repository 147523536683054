import { QuestionMarkCircleIcon } from "@heroicons/react/solid";

import PriorityDropdown from "./PriorityDropdown";

function TaskLoaderCard({}) {
  return (
    <>
      <div className="p-5 flex flex-col w-full space-y-2 bg-white rounded-20px">
        <div className="flex flex-row items-start justify-between space-x-2 relative">
          <div className="flex flex-col items-start w-9/12">
            <p className="text-md inter text-black cursor-pointer break-words h-3 w-10/12 bg-gray-200 rounded"></p>
          </div>
          <PriorityDropdown
            disabled={true}
            priorityValue={0}
            setPriorityValue={() => {}}
          />
        </div>

        <div className="flex flex-row items-center space-x-1">
          <img
            src={"/assets/svg/project.svg"}
            alt="member"
            className="w-3 h-3 bg-primary-orange-dark text-white rounded-full flex-shrink-0 shadow-lg"
          />

          <p className="text-xs inter cursor-pointer theme-gray-200 h-2 bg-gray-200 w-1/4 rounded"></p>
        </div>

        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-start">
            <p className="text-primary-gray-250 font-lato text-2xs mr-1">
              Owned by:{" "}
            </p>

            <QuestionMarkCircleIcon className="w-6 h-6 sm:w-10 sm:h-10 bg-gray-300 text-gray-500 rounded-full flex-shrink-0 shadow-lg" />
          </div>
          <p className="text-xs inter cursor-pointer theme-gray-200 h-4 bg-gray-200 w-10 rounded-full"></p>
        </div>
        <div className="text-primary-gray-280 flex flex-row items-center justify-between">
          <p className="text-xs inter cursor-pointer theme-gray-200 h-2 bg-gray-200 w-1/4 rounded"></p>

          <div className="flex flex-row items-center space-x-2 text-primary-gray-350 inter font-normal text-2xs">
            <div className="flex flex-row items-center space-x-0.5">
              <img
                src="/assets/images/icons/comments.svg"
                alt=""
                className="w-2 h-3"
              />
              <p className="">{0}</p>
            </div>
            <div className="flex flex-row items-center space-x-0.5">
              <img
                src="/assets/images/icons/files.svg"
                alt=""
                className="w-2 h-3"
              />
              <p className="">{0}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskLoaderCard;
