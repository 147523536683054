const MembersTable = () => {
  return (
    <div className="mt-3 flow-root font-inter max-h-52 overflow-y-scroll rounded-lg">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-[#F3F2F2] text-[12px]">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left  font-semibold text-gray-900 sm:pl-6"
                  >
                    Member Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left font-semibold text-gray-900"
                  >
                    Time Punched In
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left font-semibold text-gray-900"
                  >
                    Goals in Focus
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left font-semibold text-gray-900"
                  >
                    Goals Completed
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white text-[13px]">
                <tr>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-gray-900 sm:pl-6">
                    {"person.name"}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                    {"person.title"}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                    {"person.email"}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-gray-500">
                    {"person.role"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembersTable;
