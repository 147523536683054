import SearchBox from "components/Comman/Inputs/SearchBox";
import Tabs from "components/Comman/Tabs";
import GoalHubList from "components/GoalHub/GoalHubList";
import GoalCreateEdit from "components/Modals/Goal/GoalCreate/GoalCreateEdit";
import { TASK_STATUS_INPROGRESS, TASK_STATUS_NOTSTARTED } from "helpers/task";
import { AdjustmentsIcon } from "@heroicons/react/outline";
import IconFilter from "components/Comman/Inputs/IconFilter";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllgoals } from "redux/goals";

function Today() {
  const dispatch = useDispatch();
  const allGoals = useSelector((state) => state.goals?.goals);

  const [createNew, setCreateNew] = useState(false);
  const [selectedSharedTab, setSelectedSharedTab] = useState("clockedIn");
  const [plannerMode, setPlannerMode] = useState(false);
  const [plannerHeading, setPlannerHeading] = useState(false);
  const [openIssue, setOpenIssue] = useState();
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });
  const [filterList, setFilterList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    label: "All",
    value: null,
  });

  const getGoals = (list, time) => {
    let temp = [];
    switch (time) {
      case "today":
        temp = list?.filter(
          (i) =>
            i?.status == TASK_STATUS_INPROGRESS ||
            (i?.status == TASK_STATUS_NOTSTARTED && i?.priority == 1) ||
            moment(i?.dueDate).unix() <= moment().unix()
        );
        switch (selectedSharedTab) {
          case "clockedIn":
            temp = temp?.filter((i) =>
              i?.sessions?.find((a) => a?.clockOut == null)
            );
            break;
          case "inFocus":
            temp = list?.filter((i) => i?.focusIn !== null || i?.priority == 1);
            break;
          case "week":
            temp = temp?.filter(
              (i) =>
                ((i?.status == TASK_STATUS_NOTSTARTED &&
                  (i?.priority == 2 || i?.priority == 3)) ||
                  (moment(i?.dueDate).unix() <=
                    moment().add(7, "days").unix() &&
                    moment(i?.dueDate).unix() > moment().unix())) &&
                i?.priority !== 1
            );
            break;
          case "later":
            temp = temp?.filter(
              (i) =>
                (i?.status == TASK_STATUS_NOTSTARTED && i?.priority > 3) ||
                !i?.priority
            );
            break;
          default:
        }
        break;
      case "week":
        temp = list?.filter(
          (i) =>
            (i?.status == TASK_STATUS_NOTSTARTED &&
              (i?.priority == 2 || i?.priority == 3)) ||
            (moment(i?.dueDate).unix() <= moment().add(7, "days").unix() &&
              moment(i?.dueDate).unix() > moment().unix())
        );
        break;
      case "later":
        temp = list?.filter(
          (i) =>
            (i?.status == TASK_STATUS_NOTSTARTED && i?.priority > 3) ||
            !i?.priority
        );
        break;
      default: {
        switch (selectedSharedTab) {
          case "clockedIn":
            temp = list?.filter((i) =>
              i?.sessions?.find((a) => a?.clockOut == null)
            );
            break;
          case "inFocus":
            temp = list?.filter((i) => i?.focusIn !== null || i?.priority == 1);
            break;
          case "week":
            temp = list?.filter(
              (i) =>
                (i?.status == TASK_STATUS_NOTSTARTED &&
                  (i?.priority == 2 || i?.priority == 3)) ||
                (moment(i?.dueDate).unix() <= moment().add(7, "days").unix() &&
                  moment(i?.dueDate).unix() > moment().unix())
            );
            break;
          case "later":
            temp = list?.filter(
              (i) =>
                (i?.status == TASK_STATUS_NOTSTARTED && i?.priority > 3) ||
                !i?.priority
            );
            break;
          default:
            temp = list;
        }
      }
    }

    return temp;
  };
  useEffect(() => {
    if (plannerMode) {
      setTimeout(() => {
        setPlannerHeading(true);
      }, 500);
    }
  }, [plannerMode]);
  useEffect(() => {
    if (!plannerHeading) {
      setTimeout(() => {
        setPlannerMode(false);
      }, 50);
    }
  }, [plannerHeading]);

  return (
    <>
      <GoalCreateEdit
        isOpen={createNew}
        closeModal={() => setCreateNew(false)}
      />

      <div className="px-7.5 pt-2.5 flex flex-row items-center space-x-2.5">
        <div className="w-10/12">
          <SearchBox
            placeholder="Start typing to find...."
            search={search}
            setSearch={setSearch}
          />
        </div>

        <img
          src="/assets/images/icons/issues.svg"
          onClick={() => setOpenIssue(true)}
          className="text-primary-orange-medium w-6 h-6"
        />

        <IconFilter
          icon={AdjustmentsIcon}
          list={[...filterList]
            ?.concat([
              {
                label: "All",
                value: null,
              },
            ])
            .reverse()}
          iconCss="text-black h-4 w-5 transform rotate-90"
          onClick={(val) => {
            setSelectedFilter(val);
          }}
          selected={selectedFilter}
        />
      </div>

      <div className="flex flex-col">
        <Tabs
          tabs={[
            { label: "Clocked In", value: "clockedIn" },
            { label: "In Focus", value: "inFocus" },
            { label: "Week", value: "week" },
            { label: "Later", value: "later" },
          ]}
          selectedTab={selectedSharedTab}
          setSelectedTab={setSelectedSharedTab}
        />

        <div className={`w-full px-4 max-h-[60vh] overflow-y-auto pt-6`}>
          <GoalHubList
            heading=""
            list={getGoals(allGoals, "today")}
            onUpdate={() => {
              dispatch(fetchAllgoals());
            }}
            showFocus
          />
        </div>
      </div>
    </>
  );
}

export default Today;
