import DailyPlannerLaptop from "components/Planner/DailyPlanner/LaptopPage";

export default function DailyPlanner() {
  return (
    <>
      <div className="hidden lg:block">
        <DailyPlannerLaptop />
      </div>
    </>
  );
}
