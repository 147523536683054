import { createSlice } from "@reduxjs/toolkit";
import { getAll, getDepartments } from "config/APIs/controls/accessmanagement";
import { findAll } from "config/APIs/project/index";
import * as CollegeAPI from "config/APIs/cgc";
import { getAllUsersAdmin } from "config/APIs/users";

const initialState = {
  users: [],
  colleges: [],
  roles: [],
  ranks: [],
  designations: [],
  projects: [],
  allProjects: [],
  mentors: [],
  departments: [],
  status: [],
  attributeTypes: [],
  attributes: [],
  crew: [],
  cgc: [],
};

export const dropdown = createSlice({
  name: "dropdown",
  initialState,
  reducers: {
    updateReduxDropdownList: (state, action) => {
      let field = action.payload.field;
      state[field] = action.payload.list;
    },
  },
});

export const { updateReduxDropdownList } = dropdown.actions;
export default dropdown.reducer;

export function fetchAllUsersList() {
  return async (dispatch) => {
    try {
      const response = await getAllUsersAdmin({ is_active: true });

      if (response.status === 200) {
        let data = response.data.data;

        // Dispatching user data to redux-store
        dispatch(
          updateReduxDropdownList({
            field: "users",
            list: data,
          })
        );
      }
    } catch (err) {
      console.log("user fetching error", err);
    }
  };
}

export function fetchAllMentors() {
  return async (dispatch) => {
    try {
      const response = await getAllUsersAdmin({
        is_active: true,
        is_mentor: true,
      });

      if (response.status === 200) {
        let data = response.data.data.response;

        // Dispatching user data to redux-store
        dispatch(
          updateReduxDropdownList({
            field: "mentors",
            list: data,
          })
        );
      }
    } catch (err) {
      console.log("mentor fetching error", err);
    }
  };
}

export function fetchAllDepartments() {
  return async (dispatch) => {
    try {
      const response = await getDepartments();

      if (response.status === 200) {
        let data = response.data.data.data;

        // Dispatching user data to redux-store
        dispatch(
          updateReduxDropdownList({
            field: "departments",
            list: data,
          })
        );
      }
    } catch (err) {
      console.log("department fetching error", err);
    }
  };
}

export function fetchAllCollege(type = "colleges") {
  return async (dispatch) => {
    try {
      const response = await CollegeAPI.findAll(type);
      if (response.status === 200) {
        let data = response.data.data.response;

        dispatch(
          updateReduxDropdownList({
            list: data,
            field: type,
          })
        );
      }
    } catch (err) {
      console.log("college fetching error", err);
    }
  };
}

export function fetchAllRanks() {
  return async (dispatch) => {
    try {
      const response = await getAll("ranks");
      if (response.status === 200) {
        const { ranks } = response.data.data;

        // Dispatching ranks data to redux-store
        dispatch(
          updateReduxDropdownList({
            field: "ranks",
            list: ranks,
          })
        );
      }
    } catch (err) {
      console.log("Ranks fetching error", err);
    }
  };
}

export function fetchAllRoles() {
  return async (dispatch) => {
    try {
      const response = await getAll("roles");
      if (response.status === 200) {
        const { roles } = response.data.data;

        // Dispatching roles data to redux-store
        dispatch(
          updateReduxDropdownList({
            field: "roles",
            list: roles,
          })
        );
      }
    } catch (err) {
      console.log("Roles fetching error", err);
    }
  };
}

export function fetchAllDesignations() {
  return async (dispatch) => {
    try {
      const response = await getAll("designations");
      if (response.status === 200) {
        let data = response.data.designations;

        // Dispatching designation data to redux-store
        dispatch(
          updateReduxDropdownList({
            field: "designations",
            list: data,
          })
        );
      }
    } catch (err) {
      console.log("Designation fetching error", err);
    }
  };
}

export function fetchAllProjects() {
  return async (dispatch) => {
    try {
      let q = { is_active: true, status: "Live" };

      // q["selfEntities"] = true;

      const response = await findAll();
      if (response.status === 200) {
        let data = response.data.data;

        // Dispatching Projects data to redux-store
        dispatch(
          updateReduxDropdownList({
            field: "projects",
            list: data,
          })
        );
      }
    } catch (err) {
      console.log("Projects fetching error", err);
    }
  };
}

const findPass = (tags, pass) => {
  if (tags?.includes(pass)) {
    return tags;
  }
};

export function fetchAllActiveProjects() {
  return async (dispatch) => {
    try {
      const admin = localStorage.getItem("admin");
      const { tags } = admin;

      let temp = [];
      // switch (tags) {
      //   case findPass(tags, "pmo"):
      //     temp.push(
      //       ProjectTypes.find((e) => e.value == PROJECT_TYPE_WORK_STUDY)
      //     );

      //     break;
      //   case findPass(tags, "vmo"):
      //     temp.push(
      //       ProjectTypes.find((e) => e.value == PROJECT_TYPE_INITIATIVES)
      //     );

      //     break;
      //   case findPass(tags, "cpo"):
      //     temp.push(
      //       ProjectTypes.find((e) => e.value == PROJECT_TYPE_CAARYA_INTERNAL)
      //     );

      //     break;
      //   default:
      //     temp = ProjectTypes;
      // }
      let query = {
        is_active: true,
        status: "Live",
        // types: JSON.stringify(temp?.map((i) => i?.value)),
      };
      let p = [];
      await findAll(query)
        .then((res) => {
          let data = res.data.data?.response || [];
          p = p.concat(data);
        })
        .catch((error) => {
          console.log(error);
        });
      delete query?.types;
      // query["type"] = PROJECT_TYPE_PERSONAL;
      // query["selfEntities"] = true;

      // await findAll(query)
      //   .then((res) => {
      //     let data = res.data.data?.response || [];
      //     p = p.concat(data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      dispatch(
        updateReduxDropdownList({
          field: "allProjects",
          list: p,
        })
      );

      // Dispatching Projects data to redux-store
    } catch (err) {
      console.log("Projects fetching error", err);
    }
  };
}
