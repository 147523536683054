import StatsCard from "components/Comman/Cards/StatsCard";

function UserInsights({ stats, setGoalQuery, statsList }) {
  return (
    <>
      <div className="w-full pt-2.5">
        <div className="space-y-5 max-h-70vh overflow-y-auto pb-16 px-5">
          {statsList?.map((item) => {
            return (
              <StatsCard
                count={stats ? stats[item?.field] : 0}
                label={item?.label}
                onClick={() => {
                  setGoalQuery(item?.condition);
                }}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default UserInsights;
