const EventTabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className="bg-[#FFFFFF] py-4 px-6 -mt-5 w-full">
      <div className="flex flex-row justify-center bg-[white] items-center space-x-2">
        {/* Tab for Planner */}
        <div
          className={`px-4 py-2 w-[200px] h-[40px] flex justify-center items-center ${
            activeTab === "Planner" ? "border-b-[3px] border-b-[#F57D34]" : ""
          }`}
          onClick={() => setActiveTab("Planner")}
        >
          <button>
            <p
              className={`font-poppins text-base ${
                activeTab === "Planner" ? "text-[#CE5511]" : "text-[#9C9A96]"
              }`}
            >
              Planner
            </p>
          </button>
        </div>

        {/* Divider */}
        <div className="w-[2px] h-10 bg-[#D9D9D9]"></div>

        {/* Tab for Tracking */}
        <div
          className={`px-4 py-2 w-[200px] h-[40px] flex justify-center items-center ${
            activeTab === "Tracking" ? "border-b-[3px] border-b-[#F57D34]" : ""
          }`}
          onClick={() => setActiveTab("Tracking")}
        >
          <button>
            <p
              className={`font-poppins text-base ${
                activeTab === "Tracking" ? "text-[#CE5511]" : "text-[#9C9A96]"
              }`}
            >
              Tracking
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};
export default EventTabs;
