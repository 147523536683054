import { RocketLaunch } from "@phosphor-icons/react";

const ProjectItem = ({ item, terrible = false }) => {
  return (
    <div
      className={`flex items-center w-full  rounded-lg p-2 ${
        terrible ? "bg-secondary-red-100" : "bg-blue-50"
      }`}
    >
      {item?.image?.url ? (
        <img src={item?.image?.url} alt="" className="w-6 h-6 rounded-full" />
      ) : (
        <RocketLaunch size={24} className="text-gray-500" />
      )}
      <h2 className="font-bold pl-2">{item?.title}</h2>
    </div>
  );
};

export const ProjectsTable = ({ projects, terrible, title, description }) => {
  return (
    <div className="w-full h-full border border-gray-200 rounded-lg p-4 px-6">
      <h2 className="font-semibold text-[16px] font-inter">
        {/* {terrible ? "Going Terrible!" : "All Good!"} */}
        {title}
      </h2>
      <p className="text-sm text-neutral-400 -mt-[0.5px] mb-4 font-inter">
        {/* {terrible
          ? "These projects are going terrible!"
          : "These projects are going great!"} */}
        {description}
      </p>
      <div className="flex flex-col mt-3 gap-3 h-56 overflow-y-auto">
        {/* {projects?.map((p) => {
          return <ProjectItem item={p} terrible={terrible} />;
        })} */}
        <BattleItems
          title="Project Name"
          description={"Battle Field Name (Project Category)"}
          iconColor="text-primary-dawn-500"
        />
        <BattleItems
          title="Project Name"
          description={"This is description"}
          iconColor="text-[#178229]"
          goalStats="2"
        />
      </div>
    </div>
  );
};

export const BattleItems = ({ title, description, iconColor, goalStats }) => {
  return (
    <div className="rounded-[8px] flex justify-start px-[16px] py-[8px] border border-gray-200">
      <div className="flex items-center">
        <RocketLaunch size={24} className={iconColor} />
      </div>
      <div className="flex flex-col ml-2 capitalize">
        <p className="text-[14px] font-inter font-medium">{title}</p>
        <p className="text-neutral-400 capitalize font-inter text-[12px] tracking-[0.24px]">
          {description}
        </p>
      </div>
      {goalStats && (
        <div className="flex flex-col items-end justify-end ml-auto font-inter">
          <p className="text-[14px] font-medium">{goalStats}</p>
          <p className="text-[10px] font-light text-[#363430] tracking-[0.16px]">
            Goals Assigned
          </p>
        </div>
      )}
    </div>
  );
};
