import {
  Calendar,
  Stack,
  RocketLaunch,
  Target,
  SquaresFour,
  Cards,
  User,
  FileText,
  Users,
} from "@phosphor-icons/react";
const mobileBottomNav = [
  {
    name: "TAM",
    path: "/home/tam",
    icon: <Cards size={25} />,
  },

  {
    name: "Releases",
    path: "/home/releases",
    icon: <Target size={25} />,
  },
  {
    name: "Today",
    path: "/today",
    image: "/assets/caaryaLogos/pmoLogo.png",
    selectedImage: "/assets/caaryaLogos/pmoLogo.png",
  },
  {
    name: "Projects",
    path: "/home/project",
    icon: <RocketLaunch size={25} />,
  },
  {
    name: "Reports",
    path: "/home/report",
    icon: <Stack size={25} />,
  },
];

const laptopNav = [
  {
    name: "Daily Planner",
    path: "/home/dailyPlanner",
    icon: <Calendar size={25} />,
  },
  {
    name: "Delivery Planner",
    path: "/home/deliveryPlanner",
    icon: <Stack size={25} />,
  },
  {
    name: "Member Control",
    path: "/home/tam",
    icon: <Users size={25} />,
  },
  {
    name: "Product Hub",
    path: "/home/document",
    icon: <SquaresFour size={25} />,
  },
  // {
  //   name: "Event Planner",
  //   path: "/home/EventPlanner",
  //   icon: <Calendar size={25} />,
  // },
  // {
  //   name: "Sprint Planner",
  //   path: "/home/SprintPlanner",
  //   icon: <SquaresFour size={25} />,
  // },
  // {
  //   name: "Report Card",
  //   path: "/home/ReportCard",
  //   icon: <SquaresFour size={25} />,
  // },
  // {
  //   name: "Member Control",
  //   path: "/home/memberControl",
  //   icon: <User size={25} />,
  // },
  // {
  //   name: "Documentation",
  //   path: "/home/document",
  //   icon: <FileText size={25} />,
  // },
];

export { mobileBottomNav, laptopNav };
