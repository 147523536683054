import Project from "pages/Project/IndividualProject";
import { useState } from "react";
import ProjectsList from "./ProjectList";

function ProjectsPageLaptop() {
  const [selectedProject, setSelectedProject] = useState();
  const [selectedProjectToEdit, setSelectedProjectToEdit] = useState();
  return (
    <>
      <ProjectsList
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        setEdit={setSelectedProjectToEdit}
      />

      {selectedProjectToEdit && (
        <div className="w-full max-h-65vh overflow-y-auto">
          <Project projectId={selectedProjectToEdit} />
        </div>
      )}
    </>
  );
}

export default ProjectsPageLaptop;
