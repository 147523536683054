import React, { useEffect, useState } from "react";
import ChronosButton from "components/Comman/Buttons";
import { PlusIcon } from "@heroicons/react/solid";
import SquadCard from "components/MySquad/SquadCard/index.js";
import AddMembers from "components/Modals/AddMembers";
import * as ProjectAPI from "config/APIs/project";

const MembersTabs = ({ project, owner, members }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [membersList, setMembersList] = useState([]);

  useEffect(() => {
    setMembersList(members);
  }, [members]);

  const handleUpdate = async (members, remove) => {
    try {
      let body = {
        membersIds: members,
      };
      if (remove) body["remove"] = true;
      const response = ProjectAPI.update(project.id, body);
    } catch (err) {
      console.log("Error", err);
      setMembersList(members);
    }
  };

  return (
    <>
      <AddMembers
        type="members"
        allUsers
        isOpen={openAddModal}
        closeModal={() => setOpenAddModal(false)}
        selected={membersList}
        onUpdate={(id, profiles) => {
          let all = [...membersList];
          let oldIds = all?.map((p) => p?.id);
          let newMem = profiles?.filter((p) => !oldIds?.includes(p?.id));
          all = all.concat(newMem);
          setMembersList(all);

          let ids = all?.map((p) => p?.id);
          console.log(ids);
          handleUpdate(ids);
          setOpenAddModal(false);
        }}
        project={project}
      />
      <div className="md:absolute top-0 right-0 py-2 mb-3">
        <div className="block">
          <div className="flex flex-row items-center justify-between">
            <ChronosButton
              primary
              text="Add Members"
              icon={<PlusIcon className="w-4 h-4 ml-1.5" />}
              onClick={() => setOpenAddModal(true)}
            />
          </div>
        </div>
      </div>
      <div className="hidden md:block py-2 mb-3"></div>

      <div className="mb-4 rounded-xl">
        {owner || members?.length > 0 ? (
          <div className="space-y-5">
            {owner &&
              [owner]?.map((person, idx) => {
                return (
                  <SquadCard
                    project
                    owner
                    profile={person}
                    onSelect={() => {
                      // localStorage.removeItem("userTab");
                      // history.push({
                      //   pathname: `/squadUser/${person?.id}`,
                      //   state: { user: person },
                      // });
                    }}
                  />
                );
              })}
            {membersList?.map((person, idx) => {
              return (
                <SquadCard
                  project
                  profile={person}
                  onSelect={() => {
                    // localStorage.removeItem("userTab");
                    // history.push({
                    //   pathname: `/squadUser/${person?.id}`,
                    //   state: { user: person },
                    // });
                  }}
                />
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center items-center flex-col pt-6 gap-2">
            <p className="text-gray-500 text-sm">
              {project?.title} has no members!
            </p>
            <div className="relative h-[149px] w-[192px] overflow-hidden">
              <img
                src="/assets/images/empty/member.svg"
                alt=""
                className="relative h-full w-full object-contain"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MembersTabs;
