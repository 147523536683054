import Tabs from "components/Comman/Tabs";
import { useState } from "react";
import { ReactComponent as GoalCard } from "../../assets/svg/GoalCard.svg";
import CommonTextfield from "./CommonTextfield";
import EventSection from "./EventSection";

const tabs = [
  {
    label: "Ted Talk Mornings",
    value: "Ted Talk Mornings",
  },
  {
    label: "Training Theater",
    value: "Training Theater",
  },
  {
    label: "Game Nights",
    value: "Game Nights",
  },
];

const Planner = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  return (
    <div className="w-full rounded-[20px] p-4 bg-white">
      <div>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <EventSection />
    </div>
  );
};
export default Planner;
