import React from "react";
import { BiTargetLock } from "react-icons/bi";
import ReleaseCard from "./ReleaseCard";
const Release = () => {
  return (
    <>
      <div className="w-full flex flex-col h-[88vh] border border-gray-100 rounded-2xl py-8 px-4 gap-[40px]">
        <div
          style={{ lineHeight: "150%" }}
          className="  text-xl text-black font-bold "
        >
          Releases
        </div>

        <div className="flex flex-col gap-[20px]">
          <div className="flex items-start gap-[8px] self-stretch">
            <div className="flex items-start gap-[16px]  navItems">
              This Week
            </div>
            <div className="flex items-start gap-[16px] navItems">
              This Month
            </div>
            <div className="flex items-start gap-[16px]  navItems">
              This Quarter
            </div>
          </div>

          <div
            style={{ color: "var(--Dawn-500, #F57D34)", fontFamily: "Inter" }}
            className="hover:cursor-pointer text-sm font-semibold flex justify-end items-center w-full py-2 px-4 rounded-lg"
          >
            + <span className="underline">Add New Release</span>
          </div>
        </div>
        <ReleaseCard />
      </div>
    </>
  );
};

export default Release;
