import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import EmptyState from "components/Comman/EmptyState";
import {
  USER_TYPE_CUSTOMER_FWS,
  USER_TYPE_CAARYA_CORE,
} from "helpers/constants/users";
import SearchBox from "components/Comman/Inputs/SearchBox";
import { BoxedTabs } from "components/Comman/Tabs";
import UserCard from "./UserCard";

const tabs = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "FWS",
    value: USER_TYPE_CUSTOMER_FWS,
  },
  {
    label: "Core",
    value: USER_TYPE_CAARYA_CORE,
  },
];

function UserList({ selectedUser, setSelectedUser }) {
  const community = useSelector((state) => state.tam);
  const [fetching, setFetching] = useState(false);
  const [selectedTab, setSelectedTab] = useState(USER_TYPE_CAARYA_CORE);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });
  const fetchAll = async () => {
    setFetching(true);
    if (community?.list?.length > 0) {
      setList(community?.typeWiseList[selectedTab]);
      setSelectedUser(
        community?.typeWiseList[selectedTab]?.length > 0
          ? community?.typeWiseList[selectedTab][0]
          : null
      );
      setFetching(false);
      return;
    }
  };

  useEffect(() => fetchAll(), [community?.typeWiseList]);
  useEffect(() => {
    setList(community?.typeWiseList[selectedTab]);
    setSelectedUser(
      community?.typeWiseList[selectedTab]?.length > 0
        ? community?.typeWiseList[selectedTab][0]
        : null
    );
    if (search?.isSearch) {
      let i = community?.typeWiseList[selectedTab]?.filter((e) =>
        e?.first_name.toLowerCase().includes(search?.searchText.toLowerCase())
      );
      setList(i);
      setSelectedUser(i?.length > 0 ? i[0] : null);
    }
  }, [search, selectedTab]);

  return (
    <div className="h-[88vh] rounded-2xl bg-white shadow-md p-4 space-y-4">
      <div className="font-bold text-xl">Talent Allocation Matrix</div>
      {/* <BoxedTabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      /> */}

      <div className="relative">
        <SearchBox
          placeholder="Find a member!"
          search={search}
          setSearch={setSearch}
        />
      </div>
      <div className="h-[68vh] overflow-y-auto flex flex-col gap-3">
        {list?.length > 0 ? (
          list?.map((item) => {
            return (
              <UserCard
                item={item}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            );
          })
        ) : (
          <EmptyState text="No members found!" />
        )}
      </div>
    </div>
  );
}

export default UserList;
