// OptedOutParticipant.js
import { RxAvatar } from "react-icons/rx";

const OptedOutParticipant = ({ name, time, imageSrc }) => {
  return (
    <div className="px-6 py-4 space-x-4 flex flex-row items-center">
      <div>
        {/* <img src={imageSrc} alt={name} className="h-10 w-10 rounded-full" /> Updated to use img */}
        <RxAvatar className="h-[32px] w-[32px]" />
      </div>
      <div className="flex flex-col ">
        <div>
          <p className="font-inter font-normal text-base text-[#363430]">
            {name}
          </p>
        </div>
        <div>
          <p className="font-inter font-semibold text-xs text-[#9C9A96]">
            {time}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OptedOutParticipant;
