import { useState } from "react";
import { CgProfile } from "react-icons/cg";

const CommonAttendance = ({
  name,
  selected,
  onAttendanceChange,
  onCheckboxChange,
}) => {
  const [checked, setChecked] = useState(false);

  // Handle checkbox toggle
  //   const handleCheckboxChange = (event) => {
  //     setChecked(event.target.checked);
  //     onSelectedChange(name, event.target.checked);
  //     onAttendanceChange(name, event.target.checked ? "Present" : "Absent");
  //   };
  const handleSelectChange = (event) => {
    onAttendanceChange(name, event.target.value);
  };

  const handleCheckboxChange = (event) => {
    onCheckboxChange(event.target.checked);
  };

  return (
    <div className="flex flex-row p-4 space-x-5">
      <div className="flex items-start pt-1">
        <input
          type="checkbox"
          checked={selected}
          onChange={handleCheckboxChange}
          className="checked:bg-black"
        />
      </div>
      <div className="flex items-start">
        <CgProfile className="w-[32px] h-[32px]" />
      </div>

      <div className="flex flex-col items-start">
        <div>{name}</div>
        <div>
          <select className="border-b-[1px] border-t-0 border-x-0 border-[#CFCDC9] p-2 w-[198px] focus:outline-none text-[#CFCDC9] font-inter font-semibold text-sm space-x-2">
            <option value="" disabled hidden>
              Mark Absent/Present
            </option>
            <option value="Absent">Absent</option>
            <option value="Present">Present</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default CommonAttendance;
