import { ReactComponent as GoalCard } from "../../assets/svg/GoalCard.svg";
import CommonTextfield from "./CommonTextfield";
import DropdownButton from "./DropDownBttn";

const topics = [
  { label: "Topic 1", completed: true },
  { label: "Topic 2", completed: true },
  { label: "Topic 3", completed: false },
  { label: "Topic 4", completed: false },
  { label: "Topic 5", completed: false },
];

const EventSection = () => {
  // Example dropdown options
  const dropdownOptions = [
    { label: "Option 1", value: "1" },
    { label: "Option 2", value: "2" },
    { label: "Option 3", value: "3" },
    // ...more options
  ];

  // Handler for when an option is selected
  const handleSelect = (option) => {
    console.log("Selected option:", option);
    // Perform actions based on the selected option
  };
  return (
    <div className="space-y-6 ">
      <div className="flex flex-row justify-center px-[48px] items-center space-x-[48px]">
        <div className="flex max-w-[500px] p-4 space-y-2 flex-col justify-start  text-left">
          <div className="flex justify-start">
            <p className="flex text-xl font-medium font-poppins text-black">
              Ted Talk Mornings
            </p>
          </div>
          <div className="flex justify-start">
            <p className="font-semibold text-[#9C9A96] text-xs">
              Every Monday, at 11 A.M
            </p>
          </div>
          <div className="flex justify-start">
            <p className="text-sm font-light text-[#363430]">
              Playing the Ted Talk on a Monday morning as a source of
              motivation. One can even recommend their favorite Ted Talk and
              join the ones that are already streaming.
            </p>
          </div>
        </div>
        <div>
          <GoalCard />
        </div>
      </div>
      <div className="p-2">
        <DropdownButton
          options={dropdownOptions}
          onSelect={handleSelect}
          label="March 24"
        />
      </div>
      <div className="space-y-4 px-4">
        {topics.map((topic, index) => (
          <CommonTextfield
            key={index}
            label={topic.label}
            completed={topic.completed}
            placeholder="Some Topic Added Here"
          />
        ))}
      </div>
    </div>
  );
};

export default EventSection;
