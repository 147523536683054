import DropdownButton from "./DropDownBttn";
import Participants from "./Participants";
import TrackSection from "./TrackSection";

const Tracker = () => {
  // Example dropdown options
  const dropdownOptions = [
    { label: "Option 1", value: "1" },
    { label: "Option 2", value: "2" },
    { label: "Option 3", value: "3" },
    // ...more options
  ];

  // Handler for when an option is selected
  const handleSelect = (option) => {
    console.log("Selected option:", option);
    // Perform actions based on the selected option
  };
  return (
    <div>
      <div>
        <div>
          <DropdownButton
            options={dropdownOptions}
            onSelect={handleSelect}
            label="This Week"
          />
        </div>
        <div>
          <TrackSection />
        </div>
      </div>
    </div>
  );
};
export default Tracker;
