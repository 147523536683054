const ProjectItem = ({
  isSelected = false,
  onClick,
  project,
  selectedGoalsCount,
}) => {
  console.log(project);
  return (
    <div
      className={`flex items-center w-full rounded-lg p-3 cursor-pointer ${
        isSelected
          ? "bg-primary-gray-480 text-white"
          : "bg-primary-gray-100 text-black"
      }`}
      onClick={onClick}
    >
      <div className="m-1 mr-2 w-8 h-8 relative flex justify-center items-center rounded-full bg-primary-gray-200 text-white uppercase text-sm">
        {project?.title?.charAt(0)}
      </div>
      <div className="flex flex-col items-start">
        <h2 className="font-bold">{project?.title}</h2>
        <p className={`text-[10px] font-normal ${isSelected === true ? 'text-white' : 'text-[#696763]'}`}>
          {project?.members?.length} Members {project?.goals?.length} Goals
        </p>
      </div>
    </div>
  );
};
export const Skeleton = () => {
  return (
    <div
      className={`animate-pulse flex items-center gap-4 w-full cursor-pointer bg-primary-gray-150 text-black rounded-lg p-2 hover:shadow`}
    >
      <div className="h-8 w-8 bg-gray-200 rounded-full"></div>
      <div className="h-2.5 bg-gray-200 rounded w-2/3"></div>
    </div>
  );
};
export default ProjectItem;
