import { User } from "@phosphor-icons/react";
import { useState } from "react";
import UserTasksDrawer from "./UserTasksDrawer";
import { Drawer } from "@mui/material";
import UserGoals from "components/TAM/Laptop/UserGoals";
import MembersTable from "./MembersTable";

export default function TeamWorkload({ data, totalGoals }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState();

  const showUserTasksDrawer = (member) => {
    setSelectedMember(member);
    setIsDrawerOpen(true);
  };

  return (
    <>
      <div className="p-3 border border-gray-200 w-full rounded-lg">
        <h2 className="font-poppins ">Team Workload</h2>
        <MembersTable />
      </div>

      <Drawer
        anchor={window.innerWidth < 1024 ? "bottom" : "right"}
        PaperProps={{
          style: {
            borderRadius:
              window.innerWidth < 1024 ? "20px 20px 0px 0px" : "0px",
            maxHeight: "100vh",
            width: window.innerWidth < 1024 ? "100%" : "516px",
          },
        }}
        open={isDrawerOpen}
        closeModal={() => setIsDrawerOpen(false)}
        transitionDuration={250}
      >
        <UserGoals
          selectedUser={selectedMember}
          closeModal={() => setIsDrawerOpen(false)}
        />
      </Drawer>
    </>
  );
}
