import { configureStore } from "@reduxjs/toolkit";

import navigationReducer from "./navigation";
import toasterReducer from "./toaster";
import userReducer from "./user";
import tasksReducer from "./task";
import collegeReducer from "./college";
import okrReducer from "./okrs";
import shareablesReducer from "./shareables";
import releasesReducer from "./release";
import tamReducer from "./tam";
import projectReducer from "./projects";
import dropdownReducer from "./dropdown";
import goalReducer from "./goals";
import trackReducer from "./tracks";
import productReducer from "./products";

const store = configureStore({
  reducer: {
    sideNav: navigationReducer,
    toaster: toasterReducer,
    user: userReducer,
    tasks: tasksReducer,
    college: collegeReducer,
    okr: okrReducer,
    shareables: shareablesReducer,
    tam: tamReducer,
    releases: releasesReducer,
    projects: projectReducer,
    dropdown: dropdownReducer,
    tracks: trackReducer,
    goals: goalReducer,
    products: productReducer,
  },
});

export default store;
