import React, { useState, useEffect } from "react";
import {
  CaretRight,
  Plus,
  FilePlus,
  RocketLaunch,
  Target,
} from "@phosphor-icons/react";
import { feBaseLink } from "config";
import ChronosButton from "components/Comman/Buttons";
import GoalCreateModal from "components/Goals/Modals/GoalCreate";
import { useDispatch } from "react-redux";
import { fetchAllUsers } from "redux/tam";

function SquadCard({ profile, onSelect, tam, project, owner }) {
  const dispatch = useDispatch();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [tasksCount, setTasksCount] = useState(0);
  const [goalsCount, setGoalsCount] = useState(0);

  useEffect(() => {
    setTasksCount(profile?.tasksCount || 0);
    setGoalsCount(profile?.individualGoals?.length || 0);
  }, [profile]);

  return (
    <>
      <GoalCreateModal
        ownerId={profile?.id}
        isOpen={openCreateModal}
        closeModal={() => setOpenCreateModal(false)}
        onCreate={(val) => {
          setOpenCreateModal(false);
          console.log(val);
          setGoalsCount(goalsCount + 1);
          dispatch(fetchAllUsers());
        }}
      />
      <div className="pb-[18px] pt-[25px] border-b border-primary-gray-150">
        <div className="flex flex-row items-center relative ">
          {tam && goalsCount == 0 && (
            <div className="absolute -top-[18px] right-0">
              <ChronosButton
                onClick={() => {
                  setOpenCreateModal(true);
                }}
                text="Add Goal"
                icon={
                  <div className="mr-1.5">
                    <Plus size={14} color="#fff" />
                  </div>
                }
                reverseIcon
                primary
              />
            </div>
          )}
          {!project && (
            <div
              onClick={() => {
                onSelect(profile);
              }}
              className={`flex flex-row items-center space-x-1 text-xs text-primary-indigo-650 cursor-pointer absolute bottom-[10%] right-0`}
            >
              <CaretRight size={24} color="#171A1FFF" />
            </div>
          )}
          <div
            className={`${
              project ? "h-[30px] w-[30px]" : "h-[60px] w-[60px]"
            } bg-primary-orange-lightest rounded-full shadow flex flex-row items-center justify-center relative`}
          >
            <img
              onClick={(e) => {
                if (tam) {
                  e.preventDefault();
                  window.open(
                    `${feBaseLink?.caaryaLive}/dailyReports/userStory?id=${profile?.id}`,
                    "__blank"
                  );
                }
              }}
              className={`${
                profile?.image
                  ? project
                    ? "h-[30px] w-[30px]"
                    : "h-[60px] w-[60px]"
                  : project
                  ? "h-4 w-4"
                  : "h-10 w-10"
              } rounded-full object-cover`}
              src={
                (profile?.image && profile?.image?.url) ||
                "/assets/svg/defaultUser.svg"
              }
              alt=""
            />
            {/* <div
            className={`${
              profile?.is_active ? "bg-active" : "bg-inactive"
            } w-2 h-2 rounded-full absolute top-0 right-0`}
          ></div> */}
          </div>

          <div className={`cursor-pointer w-8/12 ml-4`}>
            <h3 className="text-primary-gray-900 font-lato font-semibold text-sm">
              {profile?.first_name}
            </h3>
            {owner && (
              <h3 className="text-2xs text-primary-gray-350 font-lato">
                Project Owner
              </h3>
            )}{" "}
            <h3 className="text-2xs text-primary-gray-350 font-lato">
              {profile?.designation?.rank?.rank_name}
            </h3>
            <h3 className="text-2xs text-primary-gray-350 font-lato">
              {profile?.designation?.role?.role_name}
            </h3>
          </div>
        </div>
        {!project && (
          <div className="flex flex-row mt-2 items-center space-x-5 text-2xs font-bold font-lato text-primary-gray-600">
            <div
              className={`flex flex-row items-center space-x-0.5 ${
                profile?.projectsCount > 5 ? "text-primary-red-medium" : ""
              }`}
            >
              <RocketLaunch
                size={12}
                color={profile?.projectsCount > 5 ? "#ED4C41" : "#171A1FFF"}
              />
              {/* <img
              src="/assets/images/icons/rocketIndigo.svg"
              className="w-4 h-4 mr-0.5 mt-0.5"
              alt=""
            /> */}
              <p className="mt-0.5">
                {profile?.projectsCount || profile?.projects?.length || 0}{" "}
                Projects
              </p>
            </div>
            <div
              onClick={() => {
                if (tam && goalsCount == 0) setOpenCreateModal(true);
              }}
              className="flex flex-row items-center space-x-0.5"
            >
              <Target size={12} color="#171A1FFF" />
              {/* <img
              src="/assets/images/icons/taskIndigo.svg"
              className="w-4 h-4 mr-0.5 mt-0.5"
              alt=""
            /> */}
              <p className="mt-0.5">{goalsCount || 0} Goals</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SquadCard;

export function SquadCardLoader() {
  return (
    <div className="py-2.5 border-b border-primary-gray-50">
      <div className="flex flex-row items-center relative ">
        <div
          className={`flex flex-row items-center space-x-1 text-xs text-primary-indigo-650 cursor-pointer absolute bittom-[10%] right-0`}
        >
          <CaretRight size={24} color="#171A1FFF" />{" "}
        </div>
        <div className="h-16 w-16 bg-primary-orange-lightest rounded-full shadow flex flex-row items-center justify-center relative">
          <img
            className="h-10 w-10  rounded-full object-cover"
            src={"/assets/svg/defaultUser.svg"}
            alt=""
          />
        </div>

        <div className={`cursor-pointer w-10/12 ml-4`}>
          <p className="font-lato text-sm h-4 rounded bg-primary-gray-200 w-11/12 animate-pulse font-normal text-primary-gray-600"></p>
          <p className="font-lato text-sm h-2 mt-2 rounded bg-primary-gray-200 w-1/3 animate-pulse font-normal text-primary-gray-600"></p>{" "}
          <p className="font-lato text-sm h-2 mt-1 rounded bg-primary-gray-200 w-1/3 animate-pulse font-normal text-primary-gray-600"></p>
        </div>
      </div>
      <div className="flex ml-3 flex-row mt-2 items-center space-x-5 text-xs inter text-primary-gray-600">
        <div className="flex flex-row items-center">
          <FilePlus size={12} color="#171A1FFF" />
          {/* <img
            src="/assets/images/icons/taskIndigo.svg"
            className="w-4 h-4 mr-0.5 mt-0.5"
            alt=""
          /> */}
          0 tasks
        </div>
        <div className="flex flex-row items-center">
          <RocketLaunch size={12} color="#171A1FFF" />
          {/* <img
            src="/assets/images/icons/rocketIndigo.svg"
            className="w-4 h-4 mr-0.5 mt-0.5"
            alt=""
          /> */}
          0 projects
        </div>
      </div>
    </div>
  );
}
