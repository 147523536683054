import * as GoalsAPI from "config/APIs/task/goal";
import GoalItem, { Skeleton } from "./GoalItem";
import { useEffect, useState } from "react";
import * as GoalAPI from "config/APIs/task/goal";

import ConfirmModal from "components/Modals/Common/ConfirmModal";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import EmptyState from "components/Comman/EmptyState";
import { CheckCircle, PlusCircle, XCircle } from "@phosphor-icons/react";
import GoalCreate from "components/Modals/Goal/GoalCreate";
import EditGoal from "components/Modals/Goal/EditGoal";
import DropdownInput from "components/Comman/Inputs/DropdownInput";

export default function CurrentGoals({
  project,
  fetchGoals,
  fetching,
  goals,
  owner,
}) {
  const dispatch = useDispatch();
  const [goalToEdit, setGoalToEdit] = useState();
  const [selectedGoalToDelete, setSelectedGoalToDelete] = useState();
  const [openMobileCreateModal, setOpenMobileCreateModal] = useState(false);
  const [newGoal, setNewGoal] = useState({ title: "" });
  const [sortBy, setSortBy] = useState("");
  const [list, setList] = useState([]);

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  useEffect(() => {
    setList(goals);
  }, [goals]);

  const handleSortBy = (value) => {
    setSortBy(value);
    let sortedTasks = [...list];
    switch (value) {
      case "date":
        sortedTasks.sort((a, b) => {
          return new Date(a.dueDate) - new Date(b.dueDate);
        });
        break;
      case "created_at":
        sortedTasks.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        break;

      default:
        sortedTasks = list;
    }
    setList(sortedTasks);
  };
  const handleDelete = async () => {
    try {
      await GoalsAPI.deleteGoal(selectedGoalToDelete.id);
      showSuccessNotification("Goal deleted successfully!");
      fetchGoals();
      setSelectedGoalToDelete(null);
    } catch (err) {
      console.log("Goal delete error", err);
      showErrorNotification("Something went wrong!");
    }
  };
  const handleSubmit = async () => {
    try {
      let body = {
        ...newGoal,
        status: "NotStarted",
        projectId: project?.id,
      };

      const response = await GoalAPI.createGoal(body);

      setOpenMobileCreateModal(false);
      setNewGoal({ title: "" });
      fetchGoals();
    } catch (err) {
      console.log("Story delete error", err);
      showErrorNotification("Something went wrong!");
    }
  };
  console.log(project);
  return (
    <>
      <EditGoal
        editValues={goalToEdit}
        isEdit={true}
        projectType={project?.type}
        project={project}
        isOpen={goalToEdit ? true : false}
        closeModal={() => setGoalToEdit(null)}
        onUpdate={() => fetchGoals()}
        onDelete={(val) => {
          setSelectedGoalToDelete(val);
        }}
      />

      <GoalCreate
        projectType={project?.type}
        project={project}
        isOpen={openMobileCreateModal}
        closeModal={() => setOpenMobileCreateModal(null)}
        onCreate={() => fetchGoals()}
        owner={owner}
        ownerId={owner?.id}
      />

      <ConfirmModal
        isOpen={selectedGoalToDelete ? true : false}
        closeModal={() => setSelectedGoalToDelete(null)}
        onAccept={() => handleDelete()}
        text={
          <>
            {`Are you sure you want to delete the following goal: ${selectedGoalToDelete?.title}`}
          </>
        }
      />
      <div className="w-full h-[88vh] border border-gray-100 rounded-md p-4 px-6 shadow-md">
        <div className="flex flex-col">
          <div className="flex justify-between items-center">
            <h2 className="font-extrabold text-xl text-black">Goals</h2>
            <div className="flex flex-row items-center space-x-2">
              <DropdownInput
                list={[
                  {
                    label: "Sort By",
                    value: "",
                  },
                  {
                    label: "Latest",
                    value: "latest",
                  },
                  {
                    label: "Oldest",
                    value: "oldest",
                  },
                  {
                    label: "Due Date",
                    value: "date",
                  },
                  {
                    label: "Date Created",
                    value: "created_at",
                  },
                ]}
                value={sortBy}
                setValue={handleSortBy}
                label="Sort By"
                srOnly
              />{" "}
              {!openMobileCreateModal && (
                <button
                  onClick={() => {
                    setOpenMobileCreateModal(true);
                  }}
                  className="flex items-center gap-1 border p-1.5 border-black rounded text-xs text-black"
                >
                  <PlusCircle size={12} />
                  Add Goal
                </button>
              )}
            </div>
          </div>
          {project && (
            <div className="flex flex-col items-center justify-center mt-5">
              <p className="text-[#696763] text-[12px] font-inter font-semibold">
                Showing all goals for '{project?.title}'
              </p>
              <p className="text-[#9C9A96] text-[10px] font-normal font-inter">
                Select a member to view all their goals
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col mt-11 gap-6 h-[80vh] overflow-y-auto pb-28">
          {/* {openMobileCreateModal && (
            <div className="flex flex-col space-y-1">
              <div className="w-full flex cursor-pointer flex-row items-center space-x-1 justify-end">
                <CheckCircle
                  size={24}
                  onClick={() => {
                    handleSubmit();
                  }}
                />
                <XCircle
                  size={24}
                  onClick={() => {
                    setOpenMobileCreateModal(false);
                    setNewGoal({ title: "" });
                  }}
                />
              </div>
              <textarea
                placeholder="Add new Goal"
                value={newGoal?.title}
                onChange={(e) => {
                  setNewGoal({ ...newGoal, title: e.target.value });
                }}
                rows={3}
                className="bg-white border text-xs border-primary-gray-300 rounded"
              />
            </div>
          )} */}
          {!project ? (
            <EmptyState
              text="Select a project to view goals!"
              image="/assets/images/empty/goal.png"
            />
          ) : !fetching ? (
            list?.length > 0 ? (
              list?.map((goal, i) => (
                <GoalItem
                  project={project}
                  key={i}
                  goal={goal}
                  bold
                  onEdit={() => {
                    setGoalToEdit(goal);
                  }}
                  onDelete={() => {
                    setSelectedGoalToDelete(goal);
                  }}
                />
              ))
            ) : (
              <EmptyState
                text="This project has no goals for this week!"
                image="/assets/images/empty/goal.png"
              />
            )
          ) : (
            [1, 2, 3, 4, 5].map((_, i) => <Skeleton key={i} />)
          )}
        </div>
      </div>
    </>
  );
}
