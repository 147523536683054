import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

// Storing okrs
export const okr = createSlice({
  name: "okr",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list = action.payload.list;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList } = okr.actions;

export default okr.reducer;
