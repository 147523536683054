import { useState } from "react";
import EditBanner from "./EditBanner";
import ProjectOwner from "./ProjectOwner";
import ViewBanner from "./ViewBanner";

function ProjectBanner({ project, setProject, setViewAllTask }) {
  const [isEditable, setEditable] = useState(false);

  return (
    <div className="px-5 lg:px-0">
      {isEditable ? (
        <EditBanner
          project={project}
          setProject={setProject}
          isEditable={isEditable}
          setEditable={(val) => {
            setEditable(val);
          }}
        />
      ) : (
        <div className="grid grid-cols-1 xl:grid-cols-[1fr,2fr] gap-5 xl:gap-6">
          <ViewBanner
            project={project}
            isEditable={isEditable}
            setEditable={(val) => {
              setEditable(val);
            }}
            setViewAllTask={setViewAllTask}
          />
          {/* <ProjectOwner project={project} /> */}
        </div>
      )}
    </div>
  );
}

export default ProjectBanner;
