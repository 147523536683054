import { pmoInstance } from "config";
import { getHeader } from "config";

export const findOne = ({ projectId }) =>
  pmoInstance.get(`/project/${projectId}`, getHeader());

/**
 * The following API has Pagination
 * @param {} query adds queries to the endpoints
 * Queries --> size, page, searchItem, sort&sort, type, is_active
 * Value for searchItem --> Search strings
 * Value for type --> Project type
 * Value for is_active --> true/false
 * Values for sort --> | values from BE |&ASC/DESC
 */
export const findAll = (query = {}) =>
  pmoInstance.get(`/project?${new URLSearchParams(query)}`, getHeader());

export const create = (body) => pmoInstance.post("/project", body, getHeader());

export const update = (id, body) =>
  pmoInstance.patch(`/project/${id}`, body, getHeader());

export const destroy = ({ projectId }) =>
  pmoInstance.delete(`/project/${projectId}`, getHeader());

export const getSquadReport = (type) =>
  pmoInstance.get(`/stats/${type}`, getHeader());

export const getMemberReport = (id, query = {}) =>
  pmoInstance.get(
    `/stats/member/${id}?${new URLSearchParams(query)}`,
    getHeader()
  );

export const getProjectIssue = (query = {}) =>
  pmoInstance.get(`/project/issues?${new URLSearchParams(query)}`, getHeader());
