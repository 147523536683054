import { Skeleton } from "components/Comman/Cards/ProjectCard";
import EmptyState from "components/Comman/EmptyState";

export default function Products({
  fetching = false,
  list = [],
  selectedProduct,
  setSelectedProduct,
  showDocumentation,
  selectedDocumentation,
  setSelectedDocumentation,
}) {
  return (
    <div className="w-full h-[88vh] border border-gray-100 rounded-md py-4 pb-6 px-3">
      <h2 className="font-extrabold text-lg text-black border-b pb-3">
        Products
      </h2>

      <div className="flex flex-col mt-4 gap-3 h-[80vh] overflow-y-auto">
        {!fetching ? (
          list?.length > 0 ? (
            <div className="bg-primary-gray-50 p-3 pb-0 rounded-md">
              {list?.map((project, i) => {
                return (
                  <div className={`mb-3 ${i === 0 ? "mt-5" : ""}`}>
                    <div
                      className={`flex items-center w-full rounded-lg p-3 cursor-pointer ${
                        selectedProduct?.id === project?.id
                          ? "bg-primary-gray-480 text-white"
                          : "bg-primary-gray-100 text-black"
                      }`}
                      onClick={() => setSelectedProduct(project)}
                    >
                      <div className="m-1 mr-2 w-8 h-8 relative flex justify-center items-center rounded-full bg-primary-gray-200 text-black uppercase text-sm">
                        {project?.name?.charAt(0)}
                      </div>
                      <h2 className="font-bold max-w-[80%] line-clamp-1">
                        {project?.name}
                      </h2>
                    </div>{" "}
                    {selectedProduct?.id === project?.id &&
                      showDocumentation && (
                        <div className="pt-4 w-full">
                          <div
                            onClick={() => {
                              setSelectedDocumentation("default");
                            }}
                            className={`w-full cursor-pointer flex flex-row items-center pl-3 py-3 menuitem ${
                              selectedDocumentation == "default" ? "active" : ""
                            }`}
                          >
                            <p className="">Epics & Stories</p>
                          </div>
                          <div
                            onClick={() => {
                              setSelectedDocumentation("FRD");
                            }}
                            className={`w-full cursor-pointer flex flex-row items-center pl-3 py-3 menuitem ${
                              selectedDocumentation == "FRD" ? "active" : ""
                            }`}
                          >
                            <p className="">FRD</p>
                          </div>{" "}
                          <div
                            onClick={() => {
                              setSelectedDocumentation("BRD");
                            }}
                            className={`w-full cursor-pointer flex flex-row items-center pl-3 py-3 menuitem ${
                              selectedDocumentation == "BRD" ? "active" : ""
                            }`}
                          >
                            <p className="">BRD</p>
                          </div>
                        </div>
                      )}
                  </div>
                );
              })}
            </div>
          ) : (
            <EmptyState
              image="/assets/images/empty/tasks.png"
              text="YoNo Products to show!"
            />
          )
        ) : (
          [1, 2, 3, 4, 5].map((_, i) => <Skeleton key={i} />)
        )}
      </div>
    </div>
  );
}
