import * as ProjectAPI from "config/APIs/project";
import { useState } from "react";
import { Drawer } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import SimpleInput from "components/Comman/Inputs/SimpleInput";
import { PROJECT_CATEGORY, PROJECT_TYPES_BY_CATEGORY } from "helpers/projects";
import ChronosButton from "components/Comman/Buttons";
import { addAProject, fetchAllProjects } from "redux/projects";
import SimpleTextArea from "components/Comman/Inputs/SimpleTextArea";

function CreateEditBattle({
  isOpen,
  closeModal,
  editValues,
  category,
  type,
  handleDelete,
}) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message, type: "success" }));
  };
  const handleUpdate = async () => {};
  const handleCreate = async () => {
    setLoader(true);

    try {
      const response = await ProjectAPI.create({
        ...data,
      });
      const newProject = response.data.data;
      dispatch(addAProject({ project: newProject }));
      dispatch(fetchAllProjects());

      showSuccessNotification("Project Created Successfully!");

      closeModal();
      setData({});
    } catch (err) {
      console.log("Error", err);
      switch (err.response?.status) {
        case 422:

        case 401:
          console.log(err?.response);
        default:
          showErrorNotification(err.response?.data?.message);
      }
    }

    setLoader(false);
  };

  useEffect(() => {
    let obj = { category: category, type: type };
    setData(obj);
    if (editValues) {
      setData(editValues);
    }
  }, [editValues, category, type]);

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      PaperProps={{
        style: {
          borderRadius: window.innerWidth < 1024 ? "20px 20px 0px 0px" : "0px",
          maxHeight: "100vh",
          width: window.innerWidth < 1024 ? "100%" : "560px",
        },
      }}
      open={isOpen}
      onClose={() => {
        closeModal();
        setData({});
      }}
      transitionDuration={250}
    >
      <div className="h-[100vh] overflow-y-auto z-50 bg-white px-8 py-6">
        <div className="mt-4">
          <div className="flex flex-row items-center justify-between">
            <h2 className="text-base font-semibold text-black mb-1 mt-4">
              {editValues ? "Update " : "Add New "} Battle Card
            </h2>
          </div>
          <div className="flex flex-col gap-4 mt-5">
            <SimpleInput
              label="Title"
              details={data}
              setDetails={setData}
              field="title"
            />
            <SimpleTextArea
              label="Description"
              value={data?.description}
              setValue={(val) => {
                setData({ ...data, description: val });
              }}
              field="description"
            />
            <div className="flex flex-col w-full">
              <label className="input-label">Category</label>
              <div className="py-4 flex flex-row flex-wrap items-center justify-center -mt-2 -ml-2">
                {PROJECT_CATEGORY?.map((p) => {
                  return (
                    <div
                      onClick={() => {
                        setData({ ...data, category: p?.name });
                      }}
                      className={`cursor-pointer px-5 py-3 m-2 flex flex-row items-center rounded-full border space-x-2 ${
                        data?.category == p?.name
                          ? "text-primary-orange-medium bg-primary-orange-30 border-primary-orange-medium"
                          : "text-primary-neutral-800 border-primary-neutral-100"
                      }`}
                    >
                      <div className="w-5 h-5">{p?.svg}</div>
                      <p className=" font-inter font-semibold text-sm">
                        {p?.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            {data?.category && (
              <div className="flex flex-col w-full">
                <label className="input-label"> Type</label>
                <div className="flex flex-row py-2 items-center justify-center space-x-4">
                  {(
                    PROJECT_TYPES_BY_CATEGORY?.find(
                      (a) => a?.name == data?.category
                    )?.types || []
                  )?.map((p) => {
                    return (
                      <div
                        onClick={() => {
                          setData({ ...data, type: p?.name });
                        }}
                        className="flex flex-col items-center space-y-2"
                      >
                        <div
                          style={{
                            borderColor:
                              p?.name == data?.type
                                ? p?.image?.color
                                : "#CFCDC9",
                            color:
                              p?.name == data?.type
                                ? p?.image?.color
                                : "#CFCDC9",
                          }}
                          className={`cursor-pointer w-20 h-20 flex flex-row items-center justify-center rounded-full border`}
                        >
                          <div className="w-9 h-9">{p?.image?.svg}</div>
                        </div>
                        <div
                          style={{
                            color:
                              p?.name == data?.type
                                ? p?.image?.color
                                : "#CFCDC9",
                            fontWeight: p?.name == data?.type ? "600" : "400",
                          }}
                          className="text-sm font-inter leading-5 tracking-[0.28px]"
                        >
                          {p?.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {editValues ? (
              <div className="flex justify-between items-center gap-6">
                <ChronosButton
                  text="Delete"
                  tertiary
                  red
                  onClick={() => {
                    handleDelete();
                  }}
                />{" "}
                <button className="p-3 px-4 text-caarya-red-dark  font-inter text-sm ">
                  Delete
                </button>
                <ChronosButton
                  text="Save"
                  primary
                  onClick={() => {
                    handleUpdate();
                  }}
                />
              </div>
            ) : (
              <div className=" fixed bottom-0 right-6 w-full flex flex-row space-x-3 justify-end items-center py-6 ">
                <div
                  onClick={() => {
                    closeModal();
                  }}
                  className="py-3 px-4 text-primary-neutral-400 font-inter text-sm font-semibold leading-5 tracking-[0.28px] underline cursor-pointer"
                >
                  Cancel
                </div>
                <div
                  onClick={() => {
                    handleCreate();
                  }}
                  className="bg-primary-orange-medium rounded-lg py-3 px-4 text-white font-inter text-sm font-semibold leading-5 tracking-[0.28px] cursor-pointer"
                >
                  + Add Now
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default CreateEditBattle;
