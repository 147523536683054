import React, { useState } from "react";
import UserList from "./Laptop/UserList";
import UserGoals from "./Laptop/UserGoals";

function TAMLaptop() {
  const [selectedUser, setSelectedUser] = useState();
  return (
    <div className="hidden lg:block mt-5">
      <div className="grid grid-cols-2 gap-8 font-lato">
        <UserList
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />

        <UserGoals selectedUser={selectedUser} />
      </div>
    </div>
  );
}

export default TAMLaptop;
