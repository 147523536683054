import React from "react";
import { Redirect } from "react-router-dom";
import SquadUser from "pages/MySquad/SquadUser";
import Tasks from "pages/Tasks/IndividualPage";
import Project from "pages/Project/IndividualProject";
import MySquadPage from "pages/MySquad";
import Projects from "pages/Project";
import Today from "pages/Today";
import Reports from "pages/Reports";
import TAM from "pages/TAM";
import { checkAppAccess } from "helpers/utils/accessCheck";
import DailyPlanner from "pages/DailyPlanner";
import ProjectPlanner from "pages/ProjectPlanner";
import DeliveryPartner from "pages/DeliveryPartner";
import MemberControlPage from "pages/MemberControl";
import Document from "pages/Document";
import Releases from "pages/Release";
import GoalsPage from "pages/Goals/IndividualPage";
import SprintPlanner from "pages/SprintPlanner";
import ReportCard from "pages/ReportCard";
import EventPlanner from "pages/EventPlanner/EventPlanner";
// Authenticated Paths

// Array of routes only a logged in user can access
const privateRoutes = [
  {
    path: "/today",
    component: Today,
  },

  {
    path: "/home/tam",
    name: "Talent Allocation Matrix",
    component: TAM,
  },
  {
    path: "/home/mySquad",
    component: MySquadPage,
  },
  {
    path: "/home/project",
    component: Projects,
  },
  {
    path: "/home/releases",
    component: Releases,
  },
  {
    path: "/home/report",
    component: Reports,
  },
  {
    path: "/home/dailyPlanner",
    name: "Daily Planner",
    component: DailyPlanner,
  },
  {
    path: "/home/EventPlanner",
    name: "Daily Planner",
    component: EventPlanner,
  },
  {
    path: "/home/projectPlanner",
    component: ProjectPlanner,
  },
  {
    path: "/home/deliveryPlanner",
    name: "Delivery Planner",
    component: DeliveryPartner,
  },
  {
    name: "Members Control",
    path: "/home/memberControl",
    component: MemberControlPage,
  },

  {
    name: "Product Hub",
    path: "/home/document",
    component: Document,
  },
  {
    name: "Sprint Planner",
    path: "/home/SprintPlanner",
    component: SprintPlanner,
  },
  {
    name: "Report Card",
    path: "/home/ReportCard",
    component: ReportCard,
  },

  {
    path: "/squadUser/:id",
    component: SquadUser,
  },
  {
    path: "/project/:id",
    component: Project,
  },

  {
    path: "/tasks/:id",
    component: Tasks,
  },

  {
    path: "/goals/:id",
    component: GoalsPage,
  },

  {
    path: "/",
    exact: true,
    component: () =>
      localStorage.getItem("token") ? (
        checkAppAccess() ? (
          window.innerWidth < 1024 ? (
            <Redirect to="/today" />
          ) : (
            <Redirect to="/home/dailyPlanner" />
          )
        ) : (
          <Redirect to="/accessDenied" />
        )
      ) : (
        <Redirect to="/auth/signIn" />
      ),
  },
];

export { privateRoutes };
