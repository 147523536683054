import TAMLaptop from "components/TAM/TAMLaptop";
import TAMMobile from "components/TAM/TAMMobile";

function TAM() {
  return (
    <>
      <TAMMobile />
      <TAMLaptop />
    </>
  );
}

export default TAM;
