import { pmoInstance } from "config";
import { instance, getHeader } from "config";

export const createGoal = (body) =>
  pmoInstance.post(`/goals`, body, getHeader());

export const updateGoal = (id, body) =>
  pmoInstance.patch(`/goals/${id}`, body, getHeader());

export const bulkUpdateGoals = (ids, body) =>
  pmoInstance.patch(`/goals`, { ids, update: body }, getHeader());

export const getGoal = (goalId) =>
  pmoInstance.get(`/goals/${goalId}`, getHeader());

/**
 * The following API has Pagination
 * @param {} query adds queries to the endpoints
 * Queries --> size, page, searchItem, sort&sort, start_time, end_time, status, last_week, no_members, no_epics, no_due_date, to_self, admin, project, project_type
 * Value for searchItem --> Search strings
 * Values for sort --> | value from BE |&ASC/DESC
 */
export const getAllGoals = (query = {}) =>
  pmoInstance.get(
    `/goals${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );

/**
 * The following API has Pagination
 * @param goalId Goal ID
 * @param {} query adds queries to the endpoints
 * Queries --> size, page, searchItem, sort&sort
 * Value for searchItem --> Search strings
 * Values for sort --> college/rank_name/role_name/caarya_id/date_of_joining/discord_id/mentor&ASC/DESC
 */
export const getTasks = ({ goalId }, query = {}) =>
  instance.get(
    `admin/task/goal/task/${goalId}?${new URLSearchParams(query)}`,
    getHeader()
  );

export const deleteGoal = (goalId) =>
  pmoInstance.delete(`/goals/${goalId}`, getHeader());

export const bulkDeleteGoals = (ids) =>
  pmoInstance.patch(`/goals/delete`, { ids }, getHeader());

export const getGoalIssue = (query = {}) =>
  pmoInstance.get(`/goals/issues?${new URLSearchParams(query)}`, getHeader());

export const getDailyPlannerGoals = (query = {}) =>
  pmoInstance.get(
    `/goals/dailyPlanner?${new URLSearchParams(query)}`,
    getHeader()
  );

export const getUsersGoals = (userId, query = {}) =>
  pmoInstance.get(
    `/users/${userId}/goal${
      Object.keys(query)?.length > 0 ? `?${new URLSearchParams(query)}` : ""
    }`,
    getHeader()
  );
