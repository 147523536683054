import { useState, useEffect } from "react";

import SearchBox from "components/Comman/Inputs/SearchBox";
import Tabs from "components/Comman/Tabs";
import GoalsList from "components/Goals";
import { useDispatch, useSelector } from "react-redux";
import * as ProjectAPI from "config/APIs/project";
import { updateList } from "redux/projects";
import WideModalsWrapper from "components/Modals/ModalsWrapper/WideModalWrapper";
import GoalIssues from "components/Goals/Issues";
import IconFilter from "components/Comman/Inputs/IconFilter";
import { AdjustmentsIcon } from "@heroicons/react/solid";
import ReleaseList from "components/Releases";
import ReleaseIssues from "components/Releases/Issues";

const tabs = [
  { label: "This Week", value: "thisWeek" },
  { label: "This Month", value: "thisMonth" },
  { label: "This Quarter", value: "thisQuarter" },
];

function Releases() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);
  const projectsList = useSelector((state) => state.projects.list);
  const [search, setSearch] = useState({
    placeholder: tabs[0]?.placeholder,
    searchText: "",
    isSearch: false,
  });
  const [selectedTab, setSelectedTab] = useState("thisWeek");
  const [selectedIssue, setSelectedIssue] = useState();
  const [openIssue, setOpenIssue] = useState();

  const [filterList, setFilterList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    label: "All",
    value: null,
  });
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setSearch({
        placeholder: tabs?.find((i) => i?.value == selectedTab)?.placeholder,
        isSearch: false,
        searchText: "",
      });
    }

    return () => {
      isMounted = false;
    };
  }, [selectedTab]);

  const getProjects = async () => {
    if (projects?.list?.length > 0) {
      setFilterList(
        projects?.list?.map((i) => ({ label: i?.title, value: i?.id }))
      );
      return;
    }

    try {
      const response = await ProjectAPI.findAll({
        is_active: true,
        status: "Live",
      });
      let data = response.data.data;
      setFilterList(data.map((i) => ({ label: i?.title, value: i?.id })));

      dispatch(updateList({ list: data }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);
  console.log(projectsList);
  return (
    <>
      <WideModalsWrapper
        isOpen={openIssue}
        closeModal={() => {
          setOpenIssue(false);
        }}
        component={
          <ReleaseIssues
            time={selectedTab}
            project={selectedFilter?.value}
            setFilter={setSelectedIssue}
          />
        }
      />
      <div className="px-7.5 pt-2.5 flex flex-row items-center space-x-2.5">
        <div className="w-10/12">
          <SearchBox
            placeholder="Start typing to find...."
            search={search}
            setSearch={setSearch}
          />
        </div>
        <img
          src="/assets/images/icons/issues.svg"
          onClick={() => setOpenIssue(true)}
          className="text-primary-orange-medium w-6 h-6"
        />

        <IconFilter
          icon={AdjustmentsIcon}
          // list={[...projectsList]
          list={projectsList
            ?.concat([
              {
                label: "All",
                value: null,
              },
            ])
            .reverse()}
          iconCss="text-black h-4 w-5 transform rotate-90"
          onClick={(val) => {
            setSelectedFilter(val);
          }}
          selected={selectedFilter}
        />
      </div>
      <Tabs
        center
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <div className="max-h-70vh overflow-y-hidden">
        <ReleaseList
          time={selectedTab}
          search={search}
          selectedIssue={selectedIssue}
          setSelectedIssue={setSelectedIssue}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      </div>
    </>
  );
}

export default Releases;
