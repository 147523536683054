import { MdDone } from "react-icons/md";
import TrackCard from "./TrackCard";

const CommonTrackSection = ({ label, completed, placeholder }) => {
  return (
    <div className="space-y-2 max-w-[480p]">
      <div className="flex flex-row justify-between items-center">
        <div>
          <p className="font-medium text-xs font-poppins text-[#363430]">
            Monday
          </p>
        </div>
        {completed && (
          <div className="bg-[#363430] flex justify-center items-center p-[2px] rounded-[96px] h-4 w-4">
            <MdDone className="text-[white]" />
          </div>
        )}
      </div>
      <div>
        <TrackCard />
      </div>
    </div>
  );
};

export default CommonTrackSection;
