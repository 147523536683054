import { useEffect, useState } from "react";
import Number from "../Common/Number";
import SectionHeading from "../Common/SectionHeading";
import RadioInput from "components/Comman/Inputs/RadioInput";
import {
  PROJECT_CATEGORY_AGILE_SCRUM,
  PROJECT_CATEGORY_KANBAN,
} from "helpers/projects";
import SimpleTextArea from "components/Comman/Inputs/SimpleTextArea";
import { PlusCircle } from "@phosphor-icons/react";
import * as EpicsAPI from "config/APIs/task/epics";
import ChronosButton from "components/Comman/Buttons";
import EpicStories from "./EpicStories";

function ProjectFRD({ product, frd, setFrd, onUpdate, onRefresh }) {
  const [epics, setEpics] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [editable, setEditable] = useState(false);

  const fetchEpics = async () => {
    try {
      setFetching(true);
      let query = { product_id: product?.id };

      const response = await EpicsAPI.getAllEpics(query);
      setEpics(response.data.data?.tasks || response.data.data.response || []);
    } catch (err) {
      console.log("Fetch epic task error", err);
    }
    setFetching(false);
  };

  useEffect(() => {
    if (product) {
      fetchEpics();
    }
  }, [product]);
  return (
    <div className="space-y-8">
      <div className="flex flex-row items-center justify-between w-full space-x-2">
        <h2 className="font-extrabold text-lg text-black">
          {product?.name} FRD
        </h2>
        <div className="flex flex-row items-center justify-between space-x-2">
          {editable ? (
            <>
              <ChronosButton
                secondary
                text="Cancel"
                onClick={() => {
                  setEditable(false);
                  onRefresh();
                }}
              />
              <ChronosButton
                primary
                text="Save"
                onClick={() => {
                  setEditable(false);
                  onUpdate();
                }}
              />
            </>
          ) : (
            <>
              <ChronosButton
                primary
                text="Edit"
                onClick={() => setEditable(true)}
              />
            </>
          )}
        </div>
      </div>
      <div className=" flow-root ">
        <ul role="list" className="h-[85vh] overflow-auto space-y-8">
          <li className="flex flex-row items-start relative">
            <span
              className="absolute top-9 left-4 -ml-px h-full w-0.5 border-r border-primary-orange-lighter"
              aria-hidden="true"
            />
            <Number number={1} />
            <div className="space-y-3.5 w-10/12 ml-4">
              <SectionHeading heading="Methodology" />
              {editable ? (
                <RadioInput
                  value={frd?.category}
                  setValue={(val) => {
                    setFrd({ ...frd, category: val });
                  }}
                  srOnly
                  basic
                  field="category"
                  list={[
                    {
                      label: "Waterfall (Goal Based)",
                      value: PROJECT_CATEGORY_KANBAN,
                    },
                    {
                      label: "Aigle (Goal,Epics,Stories)",
                      value: PROJECT_CATEGORY_AGILE_SCRUM,
                    },
                  ]}
                />
              ) : (
                <p className="">
                  {frd?.category == PROJECT_CATEGORY_KANBAN
                    ? "Waterfall (Goal Based)"
                    : "Aigle (Goal,Epics,Stories)"}
                </p>
              )}
            </div>
          </li>
          <li className="flex flex-row items-start relative">
            <span
              className="absolute top-9 left-4 -ml-px h-full w-0.5 border-r border-primary-orange-lighter"
              aria-hidden="true"
            />
            <Number number={2} />
            <div className="space-y-3.5 w-10/12 ml-4">
              <SectionHeading heading="Project Goals" />
              {frd?.goals?.map((goal, index) => {
                return (
                  <SimpleTextArea
                    isDisabled={!editable}
                    value={goal}
                    setValue={(val) => {
                      let g = frd.goals ? [...frd.goals] : [];
                      g[index] = val;
                      setFrd({ ...frd, goals: g });
                    }}
                    rows={3}
                    label={index + 1 < 10 ? `0${index + 1}` : index + 1}
                  />
                );
              })}
              {editable && (
                <button
                  onClick={() => {
                    let g = frd.goals ? [...frd.goals] : [];
                    g.push(" ");
                    setFrd({ ...frd, goals: g });
                  }}
                  className="flex items-center gap-1 p-2 bg-primary-ash-50 text-xs rounded text-primary-gray-300"
                >
                  <PlusCircle size={12} />
                  Add Goal
                </button>
              )}
            </div>
          </li>
          {epics?.length > 0 && (
            <li className="flex flex-row items-start relative">
              {" "}
              <span
                className="absolute top-9 left-4 -ml-px h-full w-0.5 border-r border-primary-orange-lighter"
                aria-hidden="true"
              />
              <Number number={3} />
              <div className="space-y-3.5 w-10/12 ml-4">
                <SectionHeading heading="Epics & Stories" />

                {epics?.map((epic, index) => {
                  return <EpicStories epic={epic} stories={epic?.stories} />;
                })}
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default ProjectFRD;
