import { getSortedArray } from "helpers/array";
import Header from "../../Common/Header";
import ProjectDistribution from "./ProjectDistribution";
import ProjectsOverview from "./ProjectsOverview";
import TaskDistribution from "./GoalDistribution";
import TeamWorkload from "./TeamWorkload";

export default function SquadReport({ stats }) {
  const getTotalGoals = () => {
    let sum = 0;
    stats?.projectGoals?.map((p) => {
      sum += parseInt(p?.goalsCount);
    });
    return sum;
  };

  return (
    <div className="max-h-[90vh] overflow-y-auto relative space-y-14 bg-white rounded-2xl shadow-md py-6 px-4">
      <Header stats={stats?.stats} />
      <div className="grid grid-cols-1 gap-8 mt-8">
        <div className="flex gap-8">
          <ProjectsOverview
            data={stats?.projectGoals}
            goalsWithNoProject={stats?.goalsWithNoProject}
          />
          <TeamWorkload
            data={getSortedArray(
              stats?.membersGoals || [],
              "goalsCount"
            )?.reverse()}
            totalGoals={getTotalGoals()}
          />
        </div>
        {/* <ProjectDistribution data={stats?.projectGoals} />
        <TaskDistribution data={stats?.membersGoals} /> */}
      </div>
    </div>
  );
}
