import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import * as ProjectAPI from "config/APIs/project";
import ProjectBanner from "components/Projects/IndividualProject/ProjectBanner";
import ProjectTaskManagement from "components/Projects/IndividualProject/ProjectTaskManagement";

function Project({ projectId }) {
  const params = useParams();
  const { id } = params;
  const [project, setProject] = useState();
  const [fetching, setFetching] = useState(false);
  const [viewAllTask, setViewAllTask] = useState(false);

  const fetchProject = async () => {
    setFetching(true);
    try {
      const response = await ProjectAPI.findOne({
        projectId: id || projectId,
      });
      const fetchedProject = response.data.data;

      setProject(fetchedProject);
    } catch (err) {
      console.log("Fetch project error", err);
    }
    setFetching(false);
  };

  useEffect(() => {
    console.log(projectId);
    fetchProject();
  }, [id, projectId]);

  return (
    <>
      <div className="max-h-85vh overflow-y-auto pt-2.5 px-0 md:px-2.5">
        <ProjectBanner
          project={project}
          setProject={setProject}
          setViewAllTask={setViewAllTask}
        />

        <div className="mt-0 pb-5">
          <ProjectTaskManagement
            project={project}
            viewAllTask={viewAllTask}
            setViewAllTask={setViewAllTask}
          />
        </div>
      </div>
    </>
  );
}

export default Project;
