export default function EmptyState({
  text = "No Items Found!",
  smallImage,
  image = "/assets/images/empty/accessDenied.svg",
  imageSize = "h-[25vh]",
  height = "h-[60vh]",
}) {
  return (
    <div
      className={`flex justify-center items-center flex-col py-10 space-y-4 ${height}`}
    >
      <div className="relative max-w-sm overflow-hidden">
        <img
          src={image}
          alt=""
          className={
            smallImage
              ? "relative h-[5vh] w-full object-cover"
              : `relative ${imageSize} w-full object-cover`
          }
        />
      </div>{" "}
      <p className="text-gray-500 text-sm">{text}</p>
    </div>
  );
}
