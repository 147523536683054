import React from 'react'

const ResourceAllocationCard = () => {
  return (
    <>
      <div
        className="flex flex-col py-4 px-2 items-start gap-[16px] rounded-2xl"
        style={{
          color: "var(--Neutral-500, #696763)",
          background: "var(--Neutral-50, #FAFAFA)",
        }}
      >
        <div className="text-base font-semibold min-w-full">
          Battle Front Name
        </div>

        <div className="flex flex-wrap gap-[10px]">
          <div className="flex shrink gap-[8px]">
            <img className="rounded-full" src="#"></img>
            Member Name
          </div>

          <div className="flex shrink gap-[8px]">
            <img className="rounded-full" src="#"></img>
            Member Name
          </div>

          <div className="flex shrink gap-[8px]">
            <img className="rounded-full" src="#"></img>
            Member Name
          </div>
        </div>
      </div>
    </>
  )
}

export default ResourceAllocationCard