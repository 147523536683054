import React, { useState, useEffect } from "react";
import moment from "moment";
import StatusDropDown from "components/Comman/Inputs/StatusDropDown";
import { canDeleteGoal, canUpdateGoal } from "helpers/utils/permissions/goals";
import ChronosButton from "components/Comman/Buttons";

import ConfirmModal from "components/Modals/Common/ConfirmModal";
import * as GoalAPI from "config/APIs/task/goal";

import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import { MinusIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
/**
 * Goal Card Component under caarya.space
 * @param {String} title
 * @param {String} description
 * @param {Object} type
 * @param {Object} creator -Object containing creators details
 * @param {Function} onClick
 * @returns
 */

function GoalCard({ item, onUpdate, onClick }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (status !== item?.status) {
      setStatus(item?.status);
    }
  }, [item]);
  const onDelete = async () => {
    try {
      const response = await GoalAPI.deleteGoal(item?.id);
      if (response.data) {
        dispatch(showToast({ message: "Goal Deleted!" }));
        onUpdate("delete", item?.id);
      }
    } catch (err) {
      console.log("Delete task error", err);
      switch (err.response?.status) {
        case 401:
          dispatch(showToast({ message: "Unauthorized!", type: "error" }));
          break;
        default:
          dispatch(
            showToast({ message: "Something went wrong!", type: "error" })
          );
      }
    }
  };

  const update = async (field, value) => {
    try {
      const response = await GoalAPI.updateGoal(item?.id, { [field]: value });
      if (response.data) {
        dispatch(showToast({ message: "Goal Deleted!" }));
        onUpdate(field, value, item?.id);
      }
    } catch (err) {
      console.log("Update task error", err);
      switch (err.response?.status) {
        case 401:
          dispatch(showToast({ message: "Unauthorized!", type: "error" }));
          break;
        default:
          dispatch(
            showToast({
              message: err?.response?.data?.message || "Something went wrong!",
              type: "error",
            })
          );
      }
    }
  };
  return (
    <>
      <ConfirmModal
        isOpen={deleteModal}
        closeModal={() => setDeleteModal(false)}
        onAccept={() => {
          onDelete();
          setDeleteModal(false);
        }}
        text={<>Are you sure you want to delete the Goal: {item?.title}?</>}
      />{" "}
      <div className={`cursor-pointer bg-white rounded-20px outline-none `}>
        <div className="relative group py-5 px-5 focus-within:ring-0">
          <div
            onClick={(e) => {
              e.preventDefault();
              setDeleteModal(true);
            }}
            className="absolute top-4 right-4"
          >
            {/* <ChronosButton
                text="Delete"
                onClick={(e) => {
                  e.preventDefault();
                  setDeleteModal(true);
                }}
                tertiary
                red
              /> */}
            <MinusIcon className="w-3 h-3 text-primary-red-500" />
          </div>

          {item?.dueDate && (
            <p
              onClick={() => {
                history.push(`/goals/${item?.id}`);
              }}
              className={`block text-2xs max-w-max font-lato cursor-pointer ${
                moment(item?.dueDate).unix() < moment().add(7, "days").unix()
                  ? "text-primary-orange-darker font-normal bg-primary-orange-lightest"
                  : "text-primary-indigo-700 font-normal bg-primary-indigo-100"
              } px-4 py-1 rounded-full`}
            >
              {moment(item?.dueDate).format("ll")}
            </p>
          )}
          <div className="mt-2.5 flex flex-col items-start w-full">
            <p
              onClick={() => {
                history.push(`/goals/${item?.id}`);
              }}
              className="w-full text-sm leading-4 font-lato text-primary-gray-1000 cursor-pointer break-words font-bold"
            >
              {item?.title}
            </p>
            <p
              className={`text-xs font-lato cursor-pointer text-primary-orange-darker underline font-normal`}
            >
              {item?.project?.title}
            </p>
            <p className="mt-1 text-2xs text-primary-gray-1000 opacity-70 line-clamp-2 inter">
              {item?.description}
            </p>
            <div className="mt-4 font-lato text-2xs text-primary-gray-300 border-primary-orange-lighter break-all border-t pt-2 w-full flex flex-row items-center justify-between">
              <div
                onClick={() => {
                  history.push(`/goals/${item?.id}`);
                }}
                className="flex flex-row items-center space-x-1"
              >
                <div className="flex flex-row items-center space-x-2">
                  {item?.owner ? (
                    <img
                      src={
                        item?.owner?.image?.url ||
                        "/assets/images/defaultUser.png"
                      }
                      alt=""
                      className="w-4 h-4 rounded-full"
                    />
                  ) : (
                    "NA"
                  )}{" "}
                  <p>{item?.owner ? item?.owner?.first_name : ""} </p>
                </div>
              </div>
              <StatusDropDown
                disabled={!onUpdate || !canUpdateGoal(item)}
                status={status}
                setStatus={(val) => {
                  setStatus(val);

                  update("status", val, item?.id);
                }}
                index={0}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function GoalLoader() {
  return (
    <div className=" cursor-pointer bg-white shadow-container rounded-20px">
      <div>
        <div className="relative group py-5 px-5 focus-within:ring-0 animate-pulse">
          <div>
            <span className="bg-primary-orange-dark text-2xs text-white rounded-full inline-flex px-2 py-0.5"></span>
          </div>
          <div className="mt-2 flex flex-col items-start w-full">
            <h3 className="">
              <div className="focus:outline-none w-full">
                <p className="w-10/12 text-sm line-clamp-2 leading-4 font-lato text-primary-gray-1000 cursor-pointer break-words font-bold">
                  <p className="font-lato text-sm h-2 rounded bg-primary-orange-lighter animate-pulse w-full font-normal text-primary-gray-600"></p>
                </p>
              </div>
            </h3>
            <p className="font-lato text-sm h-2 mt-1 rounded w-7/12 bg-primary-orange-lighter animate-pulse w-full font-normal text-primary-gray-600"></p>

            <a
              target="_blank"
              className="mt-4 text-xs font-bold text-primary-orange-darkest border-primary-orange-lighter break-all inter border-t pt-2 w-full"
            >
              <p className="font-lato text-sm h-2 rounded w-20 bg-primary-orange-lighter animate-pulse w-full font-normal text-primary-gray-600"></p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoalCard;
export { GoalLoader };
