import { BookOpen } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import * as TasksAPI from "config/APIs/task/task";
import TaskCard from "components/Tasks/Card";
import TaskLoaderCard from "components/Tasks/Card/TaskLoader";
import EmptyTasks from "assets/icons/EmptyTasks.png";

export default function Modal({ isOpen = false, setIsOpen, epic, story }) {
  const modalRef = useRef();
  const outsideClick = (e) => {
    if (modalRef.current === e.target) {
      setIsOpen(false);
    }
  };
  const [fetching, setFetching] = useState(false);
  const [tasks, setTasks] = useState([]);
  const getTasks = async () => {
    setFetching(true);
    const response = await TasksAPI.getAllTasks({ story_id: story?.id });
    const fetchedSubTasks =
      response.data.data.tasks || response.data.data.response;
    setTasks(fetchedSubTasks);
    setFetching(false);
  };
  useEffect(() => {
    if (isOpen) {
      getTasks();
    }
  }, [isOpen]);

  return (
    <div
      className={`bg-[rgba(0,0,0,0.3)] fixed top-0 left-0 h-full w-full font-lato z-50 ${
        isOpen ? "block" : "hidden"
      }`}
      onClick={outsideClick}
      ref={modalRef}
    >
      <div className="bg-white h-screen w-1/2 ml-auto my-auto rounded-lg p-6">
        <div className="border p-4 rounded-md mb-5">
          <h2 className="text-primary-yellow-300 font-semibold mb-3">
            <strong>Epic :</strong> {epic?.title}
          </h2>

          <div className="border border-primary-yellow-300 text-primary-yellow-300 rounded-full inline-flex gap-2 items-center p-1 px-2 text-xs">
            <BookOpen />
            Story
          </div>
          <p className="mt-2 text-xs text-primary-gray-300">{story?.title}</p>
        </div>
        {fetching ? (
          <div className="grid grid-cols-2 gap-5">
            {[1, 2, 3, 4, 5, 6].map((item) => {
              return <TaskLoaderCard key={item} />;
            })}
          </div>
        ) : tasks.length > 0 ? (
          <div className="grid grid-cols-2 gap-5">
            {tasks?.map((task) => {
              return <TaskCard task={task} />;
            })}
          </div>
        ) : (
          <div className="flex justify-center items-center flex-col pt-6 gap-2 mt-10">
            <div className="relative h-[149px] w-[192px] overflow-hidden">
              <img
                src={EmptyTasks}
                alt="empty epics"
                className="relative h-full w-full object-contain"
              />
            </div>
            <p className="text-gray-500 text-sm">
              You have no tasks to show here!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
