import { useEffect, useState, useRef } from "react";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "helpers/utils/classNames";

function Tabs({ tabs, selectedTab, setSelectedTab, center }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const tabsRef = useRef([]);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      console.log(currentTab?.offsetLeft, currentTab?.clientWidth);
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  useEffect(() => {
    if (activeTabIndex !== tabs.findIndex((i) => i?.value == selectedTab)) {
      console.log(
        ":::",
        activeTabIndex,
        selectedTab,
        tabs.findIndex((i) => i?.value == selectedTab)
      );
      setActiveTabIndex(tabs.findIndex((i) => i?.value == selectedTab));
    }
  }, [selectedTab]);

  return (
    <div className="px-5 lg:px-0 pt-5 pb-2 mb-1.5 relative overflow-x-auto">
      <div
        className={`flex flex-row items-center ${
          center ? "justify-center" : "justify-start"
        } space-x-4 lg:justify-start`}
      >
        {tabs.map((item, idx) => {
          return (
            <div
              key={idx}
              id={`selected-${item.value}`}
              ref={(el) => (tabsRef.current[idx] = el)}
              onClick={() => {
                setSelectedTab(item?.value);
                setActiveTabIndex(idx);
              }}
              className="min-w-max"
            >
              <div
                className={`flex px-3 pb-1 flex-row cursor-pointer text-xs lg:text-base items-end justify-center font-inter hover:font-semibold text-center ${
                  selectedTab == item?.value
                    ? "font-semibold text-primary-neutral-800"
                    : "text-primary-neutral-400 font-semibold"
                }`}
              >
                {item?.label}
              </div>
            </div>
          );
        })}
      </div>

      <span
        className="absolute bottom-1 rounded-sm block h-[3px] bg-primary-neutral-800 transition-all duration-300"
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      />
    </div>
  );
}

export default Tabs;

function BoxedTabs({ tabs, selectedTab, setSelectedTab }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (activeTabIndex !== tabs.findIndex((i) => i?.value == selectedTab)) {
      console.log(
        ":::",
        activeTabIndex,
        selectedTab,
        tabs.findIndex((i) => i?.value == selectedTab)
      );
      setActiveTabIndex(tabs.findIndex((i) => i?.value == selectedTab));
    }
  }, [selectedTab]);

  return (
    <div className="mb-1.5 relative bg-gray-50 px-2 py-1 rounded-lg border border-gray-200 max-w-max">
      <div
        className={`flex flex-row items-center justify-start space-x-4 overflow-x-auto`}
      >
        {tabs.map((item, idx) => {
          return (
            <div
              key={idx}
              id={`selected-${item.value}`}
              onClick={() => {
                setSelectedTab(item?.value);
                setActiveTabIndex(idx);
              }}
              className="min-w-max"
            >
              <div
                className={`flex px-6 py-2 flex-row cursor-pointer text-primary-gray-600 font-karla hover:font-semibold text-center ${
                  selectedTab == item?.value
                    ? "text-primary-orange-medium bg-primary-orange-lightest rounded-lg"
                    : "font-normal"
                }`}
              >
                {item?.label}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function RadioTabs({ tabs, selectedTab, setSelectedTab }) {
  return (
    <>
      <RadioGroup
        value={selectedTab}
        onChange={(e) => {
          setSelectedTab(e);
        }}
        className="mt-2"
      >
        <div
          className={`flex flex-row items-center overflow-x-auto -ml-3 -mt-3`}
        >
          {tabs.map((option) => (
            <RadioGroup.Option
              key={option.value}
              value={option?.value}
              className={({ active, checked }) =>
                classNames(
                  active
                    ? "ring-0 ring-offset-2 ring-primary-orange-medium outline-none"
                    : "",
                  option?.value == selectedTab
                    ? "bg-primary-orange-lightest font-semibold border-primary-orange-medium text-primary-orange-medium "
                    : "bg-primary-gray-50 border-gray-200 text-primary-gray-400 font-light hover:bg-primary-orange-lightest",
                  "m-3 min-w-max cursor-pointer border rounded-full px-3 lg:px-6 py-1 lg:py-2 flex items-center justify-center text-xs lg:text-sm font-lato"
                )
              }
            >
              <RadioGroup.Label as="p">{option.label}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </>
  );
}

export { BoxedTabs, RadioTabs };
