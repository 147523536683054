import { getHeader } from "config";
import { pmoInstance } from "config";

export const getReports = (query) => {
  return pmoInstance.get(
    `/stats/reports${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};
