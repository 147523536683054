import PriorityDropdown from "components/Tasks/Card/PriorityDropdown";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as API from "config/APIs/task/goal";
import { showToast } from "redux/toaster";
import { ArrowRight } from "@phosphor-icons/react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import StatusDropDown from "components/Comman/Inputs/StatusDropDown";

export default function Item({
  idx,
  task,
  setSelectedTaskId,
  onSelect,
  onDeselect,
  selectedTask,
}) {
  const dispatch = useDispatch();
  const [isCheck, setIsCheck] = useState(selectedTask ? true : false);
  const [priority, setPriority] = useState();
  const [status, setStatus] = useState();
  const [hoverState, setHoverState] = useState(false);

  useEffect(() => {
    if (selectedTask) {
      setIsCheck(true);
    } else {
      setIsCheck(false);
    }

    setPriority(task?.priority);
    setStatus(task?.status);
  }, [task]);

  const update = async (body) => {
    try {
      const response = await API.updateGoal(task?.id, body);
    } catch (err) {
      dispatch(
        showToast({ message: err.response?.data?.message, type: "error" })
      );
    }
  };
  const handleMouseOver = () => {
    setHoverState(true);
  };

  const handleMouseOut = () => {
    setHoverState(false);
  };

  return (
    <div
      className={`relative group flex flex-row items-center space-x-4 cursor-pointer ${
        idx === 0 ? "" : ""
      } `}
    >
      {/* <div className="flex flex-row space-x-4 items-center w-6 justify-start">
        <input
          type="checkbox"
          name={task?.id}
          id=""
          className="rounded-sm text-black focus:outline-black"
          onChange={() => {
            setIsCheck(!isCheck);
            if (!isCheck) {
              onSelect();
            }
            if (isCheck) {
              onDeselect();
            }
          }}
          checked={isCheck}
        />
      </div> */}
      <div
        className={`grid grid-cols-12 w-full py-2 rounded-lg border ${
          isCheck
            ? "bg-primary-orange-30 border-primary-orange-medium"
            : "border-primary-neutral-200"
        }`}
      >
        <div className="col-span-6 px-4 py-2 flex flex-row items-center ">
          <div
            className="max-w-[80%] font-light text-xs leading-[18px] tracking-[0.24px] font-inter text-black"
            htmlFor={task?.id}
            onClick={() => {
              setSelectedTaskId(task.id);
              setIsCheck(!isCheck);
              if (!isCheck) {
                onSelect();
              }
              if (isCheck) {
                onDeselect();
              }
            }}
          >
            <span className="flex flex-col">
              <p>{task?.title}</p>
              <p className="text-neutral-400">Project Name</p>
            </span>
          </div>
        </div>
        <div className="col-span-6 grid grid-cols-8 gap-4">
          <div className="col-span-3 p-2 flex flex-row items-center justify-center">
            <StatusDropDown
              status={status}
              setStatus={(val) => {
                setStatus(val);
                update({ status: val });
              }}
            />
          </div>

          <span className="col-span-3 p-2 flex flex-row items-center justify-center text-2xs text-black font-inter font-semibold leading-4 tracking-[0.5px]">
            {task?.owner?.first_name} {task?.owner?.last_name}
          </span>
          <div className="col-span-2 flex flex-row items-center justify-center">
            <div className="rounded-lg border border-primary-neutral-200 flex flex-row items-center justify-center p-2 hover:border-primary-red-500 hover:shadow-lg transition duration-150">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M12.986 8.7331C11.9393 6.0131 8.21268 5.86643 9.11268 1.9131C9.17935 1.61977 8.86602 1.3931 8.61268 1.54643C6.19268 2.9731 4.45268 5.8331 5.91268 9.57977C6.03268 9.88643 5.67268 10.1731 5.41268 9.9731C4.20602 9.05977 4.07935 7.74643 4.18602 6.80643C4.22602 6.45977 3.77268 6.2931 3.57935 6.57977C3.12602 7.2731 2.66602 8.3931 2.66602 10.0798C2.91935 13.8131 6.07268 14.9598 7.20602 15.1064C8.82602 15.3131 10.5793 15.0131 11.8393 13.8598C13.226 12.5731 13.7327 10.5198 12.986 8.7331ZM6.79935 12.0864C7.75935 11.8531 8.25268 11.1598 8.38602 10.5464C8.60602 9.5931 7.74602 8.65977 8.32602 7.1531C8.54602 8.39977 10.506 9.17977 10.506 10.5398C10.5593 12.2264 8.73268 13.6731 6.79935 12.0864Z"
                  stroke="#696763"
                />
              </svg>
            </div>
          </div>
          {/* <span
            className={`col-span-2 p-2 flex flex-row items-center font-inter font-semibold justify-center text-2xs text-[#F57D34] underline`}
          >
            Request ETA
          </span> */}
        </div>
      </div>
    </div>
  );
}

function ItemLoader({ idx }) {
  const [isCheck, setIsCheck] = useState(false);
  return (
    <div
      className={`relative p-4 border border-gray-100 flex items-center justify-between gap-4 cursor-pointer ${
        idx === 0 ? "rounded-t-md" : ""
      }`}
    >
      <div className="flex gap-8 items-center">
        <input
          type="checkbox"
          id=""
          className="rounded-sm text-black focus:outline-black"
          checked={isCheck}
        />
        <span className="lg:w-[18rem] xl:w-[24rem] line-clamp-1 font-normal">
          <div className="h-4 w-8/12 bg-primary-ash-180 animate-pulse rounded"></div>
        </span>
      </div>
      <PriorityDropdown />
      <span className="w-[100px]">
        <div className="h-4 w-full bg-primary-ash-180 animate-pulse rounded"></div>
      </span>
      <span className="w-[100px]">
        <div className="h-4 w-full bg-primary-ash-180 animate-pulse rounded"></div>
      </span>
    </div>
  );
}

export { ItemLoader };
