import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { TaskStatus } from "helpers/task";

const getStatusColor = (status) => {
  switch (status) {
    case "NotStarted":
      return "bg-red-200 text-red-700";
    case "InProgress":
      return "bg-blue-50 text-blue-600";
    case "InReview":
      return "bg-orange-100 text-orange-600";
    case "Completed":
      return "bg-green-100 text-green-600";
    default:
      return "bg-red-200 text-red-800";
  }
};

export default function StatusDropDown({ status, setStatus, index, disabled }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button disabled={disabled} className="">
          <div className="flex items-center">
            <div
              className={`StatusDropdown ${getStatusColor(
                status
              )} px-[6px] py-[3px] rounded-[4px] font-semibold text-2xs text-center`}
            >
              {TaskStatus.find((e) => e.value == status)?.label}
            </div>
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{
            borderRadius: 8,
            // Index === 0 ? "20px 0px 20px 20px" : "20px 20px 0px 20px",
          }}
          className={`${
            index === 0
              ? "origin-top-right top-7"
              : "origin-bottom-right bottom-6"
          } right-0 absolute  w-28 z-30 shadow-lg px-3 py-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1 max-h-64 overflow-y-auto space-y-1.5">
            {TaskStatus.map((item) => {
              return (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        setStatus(item?.value);
                      }}
                      className={`status-dark-${item?.value} text-center StatusDropdownItem cursor-pointer`}
                    >
                      {item.label}
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
