import React, { useState, useEffect } from "react";
import Details from "components/Tasks/IndividualTask";
import { useHistory } from "react-router-dom";
import * as TaskAPI from "config/APIs/task/task";
import { XIcon } from "@heroicons/react/solid";

// Used as a page in mobile and modal in laptop

function TasksEdit({ taskId, closeModal }) {
  const history = useHistory();
  const [details, setDetails] = useState({});

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchTask();
    }

    return () => {
      isMounted = false;
    };
  }, [taskId]);

  const fetchTask = async () => {
    try {
      const response = await TaskAPI.getTasksById(taskId);
      const task = response.data.data;
      setDetails(task);
    } catch (err) {
      console.log("Fetch task error", err);
    }
  };

  return (
    <>
      <div className="px-2.5 py-5">
        <div className="px-2 flex flex-row items-center justify-between space-x-2.5">
          <p className="text-sm lg:text-2xl line-clamp-2 leading-4 font-lato text-primary-gray-1000 cursor-pointer break-words font-bold">
            Edit {details?.title}
          </p>
          <XIcon
            onClick={() => {
              closeModal();
            }}
            className="w-4 text-black ml-2 cursor-pointer"
          />
        </div>
        <p
          onClick={() =>
            history.push({
              pathname: `/tasks/${details?.id}`,
              state: {
                task: details,
              },
            })
          }
          className="text-xs px-2 pt-1 underline line-clamp-2 leading-4 font-lato text-primary-yellow-darkest cursor-pointer break-words"
        >
          View full details
        </p>
      </div>
      <div className="overflow-y-auto">
        <Details
          taskId={taskId}
          taskDetails={details}
          onUpdate={() => {
            // closeModal();
          }}
        />
      </div>
    </>
  );
}

export default TasksEdit;
