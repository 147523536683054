import { useState, useEffect } from "react";

import moment from "moment";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";

import { updateList } from "redux/projects";
import * as ProjectAPI from "config/APIs/project";

const Project = ({
  project,
  setSelectedProject,
  selectedProjectId,
  setEdit,
}) => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  let temp = project?.owners.concat(project?.members);
  const allMembers = [
    ...new Map(temp.map((item) => [item?.id, item])).values(),
  ];
  useEffect(() => {
    if (open) {
      setSelectedProject(project?.id);
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  }, [open]);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setOpen(false);
      }, 50);
    }
  }, [show]);

  useEffect(() => {
    if (selectedProjectId !== project?.id) {
      setShow(false);
    }
  }, [selectedProjectId]);

  return (
    <>
      {/* <WideModalsWrapper
        component={
          <ProjectDetails
            isOpen={openDescription ? true : false}
            closeModal={() => setOpenDescription(null)}
            item={openDescription}
          />
        }
        isOpen={openDescription ? true : false}
        closeModal={() => setOpenDescription(null)}
      />
      <WideModalsWrapper
        height="100vh"
        component={
          <ProjectModal
            isOpen={selectedProject ? true : false}
            closeModal={() => setSelectedProject(null)}
            onUpdate={() => fetchAllProjects()}
            isEdit={true}
            editValues={selectedProject}
          />
        }
        isOpen={selectedProject ? true : false}
        closeModal={() => setSelectedProject(null)}
      /> */}
      <div
        className={`relative mb-2.5 bg-primary-orange-lightest rounded-[25px] ${
          open ? "project-box" : "project-box"
        }`}
      >
        <div className="flex flex-row w-full items-stretch justify-between h-full">
          <div
            onClick={() => {
              if (open) {
                setSelectedProject(project);
                setShow(false);
              } else {
                setOpen(true);
              }
            }}
            className={`${
              open ? "bg-primary-orange-darkest" : "bg-primary-orange-lighter"
            } ${
              open ? "rounded-l-[25px] rounded-r-[25px]" : "rounded-[25px]"
            } h-full p-5 project-card relative`}
          >
            <div className="flex flex-col relative space-y-1.5 items-start justify-center h-full">
              <img
                src={project?.image?.url || "/assets/svg/icon/rocket.svg"}
                alt=""
                className="w-14 h-14 rounded"
              />

              <p
                className={`font-lato text-left font-bold line-clamp-2 ${
                  open ? "text-white" : "text-primary-orange-darkest"
                }`}
              >
                {project?.title}
              </p>
            </div>{" "}
            {/* {!open && (
              <ChevronRightIcon className="text-primary-orange-darkest w-5 absolute right-2 top-[40%]" />
            )} */}
          </div>
          {/* <div className={`${open ? "show-project" : "hide-project"} `}>
            {show && (
              <div className="h-full relative py-5 pr-5 pl-2.5 project-details flex flex-col items-start justify-between space-y-2">
                <div className="w-full flex flex-col items-start space-y-2">
                  <div className="w-full flex flex-row items-center justify-between font-lato font-normal text-primary-gray-250 text-2xs">
                    <div className="flex flex-row items-center font-lato font-normal text-primary-gray-250 text-2xs">
                      <img
                        className="mr-1.5 h-2.5 w-2.5"
                        src="/assets/svg/icon/calendarGray.svg"
                        alt=""
                      />
                      {`${moment(project?.start_date).format("ll")}`}
                    </div>
                    <img
                      onClick={() => {
                        setEdit(project);
                      }}
                      className="h-2.5 w-2.5"
                      src="/assets/svg/icon/editRed.svg"
                      alt=""
                    />
                  </div>
                  <p className="text-left my-1 pr-2 font-lato font-normal line-clamp-3 text-2xs text-primary-gray-350 leading-3">
                    {project?.description}
                  </p>
                </div>

                <div className="flex flex-row items-center justify-between w-full">
                  <div className="px-3 py-1 rounded-full bg-primary-orange-dark font-bold font-lato text-white text-2xs">
                    {project?.is_active ? "Active" : "Inactive"}
                  </div>
                  <div className="flex flex-row items-center -space-x-3">
                    {project?.members?.length > 0 &&
                      project?.members?.map((item) => {
                        return (
                          <>
                            <img
                              src={
                                item?.image?.url ||
                                "/assets/images/defaultUser.png"
                              }
                              alt="member"
                              className="w-4 h-4 bg-primary-orange-dark rounded-full flex-shrink-0 shadow-lg"
                            />
                          </>
                        );
                      })}
                    {project?.owners?.length > 0 &&
                      project?.owners?.map((item) => {
                        return (
                          <>
                            <img
                              src={
                                item?.image?.url ||
                                "/assets/images/defaultUser.png"
                              }
                              alt="member"
                              className="w-4 h-4 bg-primary-orange-dark rounded-full flex-shrink-0 shadow-lg"
                            />
                          </>
                        );
                      })}
                  </div>
                </div>
                <ChevronLeftIcon
                  onClick={() => {
                    if (open) {
                      setSelectedProject();
                      setShow(false);
                    } else {
                      setOpen(true);
                    }
                  }}
                  className="text-primary-orange-darkest w-5 absolute right-3 top-1/3"
                />
              </div>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

/**
 * Displays the Project List Component
 * @param {Function} setSelectedProject
 * @param {Object} selectedProject - Object containing selected Project Details
 * @returns
 */

function ProjectsList({ selectedProject, setSelectedProject, setEdit }) {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects?.list);
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  const fetchAll = async () => {
    if (projects?.length > 0) {
      return;
    }

    setFetching(true);
    try {
      const response = await ProjectAPI.findAll({
        // is_active: true,
        // status: "Live",
      });
      let data = response.data.data;

      dispatch(updateList({ list: data }));
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => fetchAll(), [projects?.list]);

  useEffect(() => {
    if (projects?.length > 0) {
      setEdit(projects[0]?.id);
      setShow(false);
    }
  }, []);
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  }, [open]);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setOpen(false);
      }, 50);
    }
  }, [show]);
  return (
    <div
      className={`${
        open ? "project-container-box-expanded" : "project-container-box"
      }`}
    >
      <div className={`${!open ? "show-projects" : "hide-projects"} `}>
        {!show && (
          <div className="flex flex-row justify-between items-center md:mb-8 space-x-6 w-full">
            <p className="font-lato text-sm">
              Showing:{" "}
              <b>{projects?.find((p) => p?.id == selectedProject)?.title}</b>
            </p>
            <p
              onClick={() => {
                setOpen(true);
              }}
              className="text-primary-orange-dark underline font-karla text-sm cursor-pointer"
            >
              Show all projects
            </p>
          </div>
        )}
      </div>
      <div className={`${open ? "show-projects" : "hide-projects"} `}>
        {show && (
          <div className="flex flex-row items-stretch md:mb-8 space-x-6 max-w-full overflow-x-auto">
            {projects?.length > 0 &&
              projects?.map((item) => {
                return (
                  <div className="cursor-pointer">
                    <Project
                      selectedProjectId={selectedProject}
                      project={item}
                      setSelectedProject={(val) => {
                        setSelectedProject(val);
                        setEdit(val);
                        setShow(false);
                      }}
                      setEdit={(val) => {
                        setEdit(val);
                        setShow(false);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectsList;
