import { Checks, Clock } from "@phosphor-icons/react";

function ReportsCard({ type, stats }) {
  const { icon, bg, title } = getClassName(type);
  return (
    <div className={`${bg} rounded-md p-3`}>
      <div className="flex gap-4 items-center mb-2">
        {icon}
        <strong>{title}</strong>
      </div>
      <p className="text-4xl mb-2 font-bold">{stats[type] || 0}</p>
      {/* <p className="text-xs">Increased by 6 this week</p> */}
    </div>
  );
}

export default ReportsCard;

const getClassName = (type) => {
  switch (type) {
    case "completed":
      return {
        icon: (
          <Checks
            size={35}
            className="text-white p-2 rounded-md bg-primary-red-500"
          />
        ),
        bg: "bg-secondary-red-100",
        title: "Completed Goals",
      };
    case "overdue":
      return {
        icon: (
          <Clock
            size={35}
            className="text-white p-2 rounded-md bg-primary-yellow-700"
          />
        ),
        bg: "bg-primary-yellow-50",
        title: "Overdue Goals",
      };
    case "incomplete":
      return {
        icon: (
          <Clock
            size={35}
            className="text-white p-2 rounded-md bg-primary-green-600"
          />
        ),
        bg: "bg-primary-green-200",
        title: "Incomplete Goals",
      };
    case "total":
      return {
        icon: (
          <Clock
            size={35}
            className="text-white p-2 rounded-md bg-primary-blue-300"
          />
        ),
        bg: "bg-primary-blue-50",
        title: "Total Goals",
      };
    default:
      return "";
  }
};
