import React, { useState } from "react";
import ChronosButton from "components/Comman/Buttons";
import { PlusIcon } from "@heroicons/react/solid";
import EmptyState from "components/Comman/EmptyState";
import ProjectList, {
  ProjectListLoader,
} from "components/Comman/Lists/ProjectLists";
import { ProjectTypes } from "helpers/projects";
import AddProjectModal from "./Modals/AddProject";
import { updateUsersProjects } from "config/APIs/users";
import ConfirmModal from "components/Modals/Common/ConfirmModal";

function UsersProjects({
  typeWiseProject,
  fetching,
  projects,
  userId,
  setProjects,
  setTypeWiseProject,
  details,
}) {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [removeModal, setRemoveModal] = useState();
  const handleRemove = async (id) => {
    let body = { projectIds: [id], remove: true };
    try {
      const response = await updateUsersProjects(userId, body);
      let val = projects?.filter((p) => p?.id !== id);
      setRemoveModal();
      let p = val;
      let types = [];
      let temp = {};
      p?.map((i) => {
        if (types?.includes(i?.type)) {
          temp[i.type].push(i);
        } else {
          types.push(i?.type);
          temp[i.type] = [i];
        }
      });
      setProjects(p);
      setTypeWiseProject(temp);
    } catch (err) {
      console.log("Error", err);
      switch (err.response?.status) {
        case 422:
          let error = {},
            { data } = err.response.data;
          for (let key of Object.keys(data)) {
            if (key.split(".")[0] === "task")
              error[key.split(".")[1]] = data[key];
          }

        case 401:
          console.log(err?.response);
        default:
      }
    }
  };
  return (
    <>
      <ConfirmModal
        isOpen={removeModal ? true : false}
        closeModal={() => setRemoveModal()}
        onAccept={() => handleRemove(removeModal?.id)}
        text={
          <>
            Are you sure you want to remove {details?.first_name} from{" "}
            {removeModal?.title}? All goals allocated to the person will be
            unassigned.
          </>
        }
      />
      <AddProjectModal
        closeModal={() => setOpenAddModal(false)}
        isOpen={openAddModal}
        onAdd={(val) => {
          setProjects(val);
          let temp = { ...typeWiseProject };
          val?.map((p) => {
            if (
              Object?.keys(temp)?.includes(p?.type) &&
              !temp[p?.type]?.find((p1) => p1?.id == p?.id)
            ) {
              temp[p?.type]?.push(p);
            } else {
              temp[p?.type] = [p];
            }
          });
          setTypeWiseProject(temp);
        }}
        userId={userId}
        userProjects={projects}
      />
      <div className="w-full px-5 py-4 space-y-5">
        <div className="w-full flex flex-row items-center justify-end mb-2.5 z-30 relative">
          <ChronosButton
            onClick={() => {
              setOpenAddModal(true);
            }}
            text="Add Projects"
            icon={<PlusIcon className="icon w-4 h-4 mr-1.5" />}
            reverseIcon
            primary
          />
        </div>{" "}
        {!fetching ? (
          projects.length > 0 ? (
            Object.keys(typeWiseProject)?.map((key) => {
              return (
                <ProjectList
                  category={ProjectTypes.find((e) => e.value == key)?.label}
                  projects={typeWiseProject[key]}
                  onRemove={(val) => setRemoveModal(val)}
                />
              );
            })
          ) : (
            <div className="h-40vh w-full flex flex-row items-center justify-center">
              <EmptyState text="The user doesn't have any projects yet!" />
            </div>
          )
        ) : (
          [1, 2]?.map((item) => (
            <>
              <ProjectListLoader />
            </>
          ))
        )}
      </div>
    </>
  );
}

export default UsersProjects;
