// participantsData.js
const participants = [
  {
    name: "Ronald Richards",
    imageSrc: "path/to/ronalds-image.jpg",
  },
  {
    name: "Ralph Edwards",
    imageSrc: "path/to/ralphs-image.jpg",
  },
  {
    name: "Marvin McKinney",
    imageSrc: "path/to/marvins-image.jpg",
  },
  {
    name: "Jenny Wilson",
    imageSrc: "path/to/jennys-image.jpg",
  },
  {
    name: "Arlene McCoy",
    imageSrc: "path/to/arlenes-image.jpg",
  },
  {
    name: "Darrell Steward",
    imageSrc: "path/to/darrells-image.jpg",
  },
  // Add more as needed
];

const optedOutParticipants = [
  {
    name: "Robert Fox",
    imageSrc: "path/to/dummy-image.jpg",
    time: "Mon, 4 March 10:00 am",
  },
  {
    name: "Wade Warren",
    imageSrc: "path/to/dummy-image.jpg",
    time: "Mon, 4 March 10:30 am",
  },
  {
    name: "Jerome Bell",
    imageSrc: "path/to/dummy-image.jpg",
    time: "Mon, 4 March 11:00 am",
  },
  {
    name: "Fiona Green",
    imageSrc: "path/to/dummy-image.jpg",
    time: "Mon, 4 March 11:30 am",
  },
  // Add more as needed
];

export const attendanceList = [
  // ... your participants data with an additional 'attendance' field
  { name: "Robert Fox", attendance: "" },
  // ... more participants
];

export { participants, optedOutParticipants };
