import MembersTable from "components/Planner/DailyPlanner/SquadReport/MembersTable";
import React from "react";
import ReactApexChart from "react-apexcharts";

const TeamPerformance = () => {
  return (
    <div className="p-4 grid grid-cols-3 gap-6">
      <div className="flex flex-col border rounded-[16px] border-neutral-300 p-4">
        <p className="text-[14px] font-semibold font-inter text-[#363430] pb-14">
          Hours Clocked In
        </p>
        <ReactApexChart
          options={{
            colors: [
              "#8B2F8A",
              "#CA498C",
              "#FDE3DF",
              "#E6BFCE",
              "#CF9BBD",
              "#B977AC",
              "#A2539B",
            ],
            chart: {
              width: 380,
              type: "pie",
            },
            labels: [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thrusday",
              "Friday",
              "Saturday",
            ],
            dataLabels: {
              enabled: false,
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          }}
          series={[44, 55, 13, 43, 22]}
          type="pie"
          width={380}
        />
      </div>
      <div className="border rounded-[16px] border-neutral-300 flex-col p-4 col-span-2">
        <p className="text-[14px] font-semibold font-inter text-[#363430]">
          Hours Clocked In
        </p>
        <MembersTable />
      </div>
    </div>
  );
};

export default TeamPerformance;
