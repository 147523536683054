import { ReactComponent as EpicIcon } from "assets/icons/Epic.svg";
import { ReactComponent as StoryIcon } from "assets/icons/Story.svg";
import { ReactComponent as TaskIcon } from "assets/icons/Task.svg";
import { ReactComponent as BugIcon } from "assets/icons/Bug.svg";
import {
  ProjectTypes,
  ProjectCategories,
  PROJECT_CATEGORY_KANBAN,
} from "helpers/projects";
import { useEffect, useState } from "react";
import { UserGroupIcon } from "@heroicons/react/outline";

function ViewBanner({ project, setEditable, setViewAllTask }) {
  const [viewMore, setViewMore] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setViewAllTask(true);
      setViewMore(true);
    } else {
      setViewAllTask(false);
    }
  }, []);
  return (
    <div className="mb-5 h-full">
      <div className="hidden lg:flex flex-row items-end justify-end">
        <div
          onClick={() => {
            setViewMore(!viewMore);
          }}
          className="text-xs font-medium underline mb-2 cursor-pointer"
        >
          {viewMore ? "Hide" : "View"} project details
        </div>
      </div>
      <div className="rounded-xl h-full">
        <div className="px-5 py-3 h-full bg-white lg:bg-primary-gray-150 rounded-xl relative transition-all transform ease-in-out duration-150">
          <div className="flex flex-col space-y-3">
            <div>
              <h1 className="text-2xl font-lato text-gray-900 font-bold lg:text-3xl">
                {project?.title}
              </h1>
              {viewMore && (
                <div className="flex flex-row items-center pt-2 font-lato lg:pt-4">
                  {project?.is_active && (
                    <div className="bg-green-50 text-green-800 text-2xs lg:text-sm px-3 mr-1 py-0.5 rounded">
                      {project?.is_active && "Active"}
                    </div>
                  )}
                  {project?.category && (
                    <div className="bg-yellow-50 text-yellow-700 text-2xs lg:text-sm px-3 mx-1 py-0.5 rounded">
                      {project?.category}
                    </div>
                  )}
                  {project?.type && (
                    <div className="bg-blue-50 text-blue-800 text-2xs lg:text-sm px-3 mx-1 py-0.5 rounded">
                      {project?.type}
                    </div>
                  )}
                </div>
              )}
              <p
                className={`text-xs font-lato lg:text-sm mt-2 ${
                  !viewMore && "line-clamp-4"
                }`}
              >
                {project?.description}
              </p>
            </div>
            {viewMore && (
              <div className="flex flex-col gap-2">
                <h2 className="flex justify-between items-center text-md md:text-xl lg:text-2xl font-lato text-gray-900 font-bold ">
                  Project Summary
                  {/* <span
                    onClick={() => {
                      setViewAllTask(true);
                    }}
                    className="xl:hidden font-semibold underline text-sm cursor-pointer"
                  >
                    View all tasks
                  </span> */}
                </h2>

                <div className="flex flex-wrap gap-y-2 font-lato">
                  <div className="flex items-center gap-2 border border-primary-yellow-dark text-primary-yellow-dark text-2xs lg:text-sm px-2 lg:px-3 mx-1 py-2  rounded">
                    <UserGroupIcon className="h-4 fill-primary-yellow-dark" />
                    Members ({project?.members?.length || 0})
                  </div>
                  <div className="flex items-center gap-2 border border-blue-500 text-blue-500 text-2xs lg:text-sm px-2 lg:px-3 mx-1 py-2  rounded">
                    <TaskIcon className="h-4 fill-blue-500" />
                    Goals ({project?.goals?.length || 0})
                  </div>

                  {/* {project?.category !== PROJECT_CATEGORY_KANBAN && (
                    <>
                      <div className="flex items-center gap-2 border border-primary-yellow-dark text-primary-yellow-dark text-2xs lg:text-sm px-2 lg:px-3 mx-1 py-2  rounded">
                        <StoryIcon className="h-4 fill-primary-yellow-dark" />
                        Stories ({project?.stats?.stories || 0})
                      </div>

                      <div className="flex items-center gap-2 border border-green-500 text-green-700 text-2xs lg:text-sm px-2 lg:px-3 mx-1 py-2  rounded">
                        <EpicIcon className="h-4 fill-green-500" />
                        Epics ({project?.stats?.epics || 0})
                      </div>
                    </>
                  )} */}

                  {/* <div className="flex items-center gap-2 border border-red-500 text-red-500 text-2xs lg:text-sm px-2 lg:px-3 mx-1 py-2  rounded">
                    <BugIcon className="h-4 fill-red-500" />
                    Bugs ({project?.stats?.bugs || 0})
                  </div> */}
                </div>
                <span
                  onClick={() => {
                    setViewAllTask(true);
                  }}
                  className="hidden xl:block font-semibold underline text-sm cursor-pointer ml-auto"
                >
                  View all tasks
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewBanner;
