import { useEffect, useState } from "react";
import BacklogGrooming from "./BacklogGrooming";
import Header from "./Header";
import SquadReport from "./SquadReport";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "components/Comman/EmptyState";
import { getSquadReport } from "config/APIs/project";
import { fetchDailyPlannerGoals } from "redux/goals";

const tabs = [
  {
    label: "Priority Planner",
    value: "Backlog grooming",
  },
  {
    label: "Today's Report",
    value: "Squad Report",
  },
];

export default function DailyPlannerLaptop() {
  const dispatch = useDispatch();
  const { dailyPlannerGoals } = useSelector((state) => state.goals);
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const [tasks, setTasks] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [stats, setStats] = useState();
  const [groupBy, setGroupBy] = useState("");

  useEffect(() => {
    fetchStat();
  }, []);

  useEffect(() => {
    getTasks();
  }, [dailyPlannerGoals]);

  const getTasks = async (refresh) => {
    if (dailyPlannerGoals?.fetching || refresh) {
      dispatch(fetchDailyPlannerGoals());
    } else {
      setTasks({
        pendingTasks: dailyPlannerGoals?.pendingTasks,
        dueTodayTasks: dailyPlannerGoals?.dueTodayTasks,
        dueTomorrowTasks: dailyPlannerGoals?.dueTomorrowTasks,
        otherTasks: dailyPlannerGoals?.otherTasks,

        inFocus: dailyPlannerGoals?.inFocus,
        notInFocus: dailyPlannerGoals?.notInFocus,
      });
      setSelectedTaskId(dailyPlannerGoals?.list[0].id);
    }
  };

  const fetchStat = async () => {
    try {
      const response = await getSquadReport("daily");
      let data = response.data.data;
      setStats(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className="font-lato space-y-6 pb-6 max-h-[90vh] overflow-y-auto">
      <Header
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        groupBy={groupBy}
        setGroupBy={setGroupBy}
      />
      {selectedTab === "Backlog grooming" &&
        (tasks?.pendingTasks?.length > 0 ||
        tasks?.dueTodayTasks?.length > 0 ||
        tasks?.dueTomorrowTasks?.length > 0 ||
        tasks?.otherTasks?.length > 0 ? (
          <BacklogGrooming
            groupBy={groupBy}
            tasks={tasks}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={setSelectedTaskId}
            getTasks={getTasks}
            fetching={dailyPlannerGoals.fetching}
          />
        ) : (
          <div className="flex flex-row items-center justify-center h-50vh">
            <EmptyState
              text="No tasks found for your projects"
              image="/assets/images/empty/tasks.png"
            />
          </div>
        ))}
      {selectedTab === "Squad Report" && <SquadReport stats={stats} />}
    </section>
  );
}
