import MemberControl from "components/MemberControl";
import React from "react";

function MemberControlPage() {
  return (
    <>
      <div className="block lg:hidden"></div>
      <div className="hidden lg:block">
        <MemberControl />
      </div>
    </>
  );
}

export default MemberControlPage;
