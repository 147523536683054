import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
let days = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

const generateData = (count, yrange) => {
  var i = 0;
  var series = [];
  count = 7;
  while (i < count) {
    var x = "w" + (i + 1).toString();
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push({
      x: days[i],
      y,
    });
    i++;
  }
  return series;
};
const options = {
  chart: {
    height: 350,
    type: "heatmap",
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#0350A7"],
  title: {
    text: "",
  },
};
export default function TaskDistribution({ list }) {
  const [data, setData] = useState([
    {
      name: "Morning",
      data: generateData(9, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: "Day",
      data: generateData(9, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: "Night",
      data: generateData(9, {
        min: 0,
        max: 90,
      }),
    },
  ]);

  useEffect(() => {
    if (list) {
      let data1 = [];
      Object.keys(list)?.map((key) => {
        let o = {
          name: key,
          data: [],
        };
        days?.map((item) => {
          if (list[key]?.find((a) => a?.day == item)) {
            o.data.push({
              x: item,
              y: list[key]?.find((a) => a?.day == item)?.count,
            });
          } else {
            o.data.push({
              x: item,
              y: 0,
            });
          }
        });

        data1.push(o);
      });

      setData(data1);
    }
    console.log(list);
  }, [list]);

  return (
    <div className="p-4 px-5 border border-gray-200 w-full rounded bg-neutral-50 overflow-x-scroll">
      <div className="w-full flex flex-row gap-7">
        {/* {list && (
          <ReactApexChart
            options={options}
            series={data}
            type="heatmap"
            height={350}
          />
        )} */}
        <GraphContainer
          title="Battle Field Name (Project Category)"
          description="Battle Front (CGC,Core)"
        >
          <BattleFieldCharts />
        </GraphContainer>

        <GraphContainer
          title="Battle Field Name (Project Category)"
          description="Battle Front (CGC,Core)"
        >
          <BattleFieldCharts />
        </GraphContainer>

        <GraphContainer
          title="Battle Field Name (Project Category)"
          description="Battle Front (CGC,Core)"
        >
          <BattleFieldCharts />
        </GraphContainer>
      </div>
    </div>
  );
}

export const BattleFieldCharts = ({ series }) => {
  return (
    <ReactApexChart
      options={{
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Project 1",
            "Project 2",
            "Project 3",
            "Project 4",
            "Project 5",
          ],
        },
        /* yaxis: {
                title: {
                  text: "$ (thousands)",
                },
              }, */
        fill: {
          opacity: 1,
        },
        /* tooltip: {
                y: {
                  formatter: function (val) {
                    return "$ " + val + " thousands";
                  },
                },
              }, */
      }}
      series={[
        {
          name: "Projects Worked On",
          data: [44, 55, 57, 56, 70],
        },
        {
          name: "No. of projects Completed",
          data: [76, 85, 101, 98, 87],
        },
      ]}
      type="bar"
      //height={350}
    />
  );
};

export const GraphContainer = ({ title, description, children }) => {
  return (
    <div className="w-full h-full border border-neutral-200 p-4 px-6 bg-white rounded-[16px]">
      <h2 className="font-semibold text-[16px] font-inter">{title}</h2>
      <p className="text-sm text-neutral-400 -mt-[0.5px] mb-4 font-inter">
        {description}
      </p>
      <div className="flex flex-col mt-3 gap-3 h-56 overflow-y-auto">
        {children}
      </div>
    </div>
  );
};
