import DropdownInput from "components/Comman/Inputs/DropdownInput";
import Tabs from "components/Comman/Tabs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDailyPlannerGoals } from "redux/goals";

export default function Header({ tabs, selectedTab, setSelectedTab }) {
  const [groupBy, setGroupBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const {
    tasks: { dailyPlannerTasks, tempDailyPlannerTasks },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleSortBy = (value) => {
    setSortBy(value);
    let sortedTasks = [...dailyPlannerTasks.list];
    switch (value) {
      case "date":
        sortedTasks.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        break;
      case "created_at":
        sortedTasks.sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        });
        break;
      case "priority":
        sortedTasks.sort((a, b) => {
          return a.priority - b.priority;
        });
        break;
      default:
        sortedTasks = tempDailyPlannerTasks;
    }
    dispatch(
      updateDailyPlannerGoals({
        list: sortedTasks,
      })
    );
  };

  return (
    <div className="flex justify-between items-center">
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {/* <div className="flex gap-4">
        <DropdownInput
          list={[
            {
              label: "Sort By",
              value: "",
            },
            {
              label: "Latest",
              value: "latest",
            },
            {
              label: "Oldest",
              value: "oldest",
            },
            {
              label: "Due Date",
              value: "date",
            },
            {
              label: "Date Created",
              value: "created_at",
            },
          ]}
          value={sortBy}
          setValue={handleSortBy}
          label="Sort By"
          srOnly
        />
      </div> */}
    </div>
  );
}
