// TODO : Update documentation
import { useState } from "react";

/**
 * Simple Input field for changing values in an object
 * @param { String } label
 * @param { String } field - Key in the details oject
 * @param { Object } details - Object containing the details of the entity
 * @param { Function } setDetails - Function to changed the value of the given field in details object
 * @param { Boolean } srOnly - To display the label or not
 * @returns
 */
function SimpleTextArea({
  label,

  value,
  setValue,
  srOnly = false,
  isDisabled,
  showError,
  required = false,
  errorMessage = "Please fill in the details",
  customError,
  bigText,
  placeholder,
  type,
  boldText,
  autoFocus = false,
  rows = 3,
}) {
  const [error, setError] = useState();
  return (
    <>
      <div className="flex flex-col items-start w-full">
        <label htmlFor={label} className={srOnly ? "sr-only" : "input-label"}>
          {required ? label + "*" : label}
        </label>
        <textarea
          rows={rows}
          autoFocus={autoFocus}
          id={label}
          name={label}
          disabled={isDisabled}
          placeholder={placeholder ? placeholder : `Enter ${label}`}
          value={value || ""}
          onChange={(e) => {
            setError();
            setValue(e.target.value);
          }}
          className={`w-full appearance-none pb-1.5 px-0 border-t-0 border-r-0 border-l-0 border-b border-primary-gray-200 text-primary-gray-600 font-lato  ${
            boldText ? "font-bold" : "font-normal"
          }  ${
            bigText ? "text-2xl" : "text-base"
          } leading-6 placeholder:text-gray-200 bg-transparent focus:outline-none focus:ring-transparent`}
        ></textarea>
        {((showError && (!value || (value && value == ""))) || error) && (
          <p className="text-alert text-2xs flex flex-row items-center mt-1 ml-0.5">
            {error || errorMessage}
          </p>
        )}
        {showError && customError && errorMessage && (
          <p className="text-alert text-2xs flex flex-row items-center mt-1 ml-0.5">
            {errorMessage}
          </p>
        )}
      </div>
    </>
  );
}

export default SimpleTextArea;
