import TaskLoaderCard from "components/Tasks/Card/TaskLoader";

function TaskListLoader() {
  return (
    <div className="space-y-5">
      {[1, 2].map((task) => {
        return (
          <>
            <TaskLoaderCard />
          </>
        );
      })}
    </div>
  );
}

export default TaskListLoader;
