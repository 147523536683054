import { Transition, Disclosure } from "@headlessui/react";
import { BiChevronUp } from "react-icons/bi";

function EpicStories({ epic, stories }) {
  return (
    <Disclosure className="w-full">
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full bg-primary-gray-100 rounded p-2.5">
            <div className="pb-2 flex items-start justify-between cursor-pointer">
              <div className="flex flex-col items-start justify-start space-y-2.5 font-lato text-primary-gray-600">
                <div className="bg-white rounded px-2 py-0.5 text-xs">Epic</div>
                <p className="text-sm">{epic?.title}</p>
              </div>

              <BiChevronUp
                className={
                  open ? "transform w-6 scale-125" : "-rotate-180 w-6 scale-125"
                }
              />
            </div>
          </Disclosure.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="w-full flex flex-col space-y-2.5 -mt-5 bg-primary-gray-100 rounded p-2.5">
              <div className="bg-white rounded px-2 py-0.5 text-xs max-w-max">
                Stories
              </div>
              {stories?.length > 0 ? (
                stories?.map((story, index) => {
                  return (
                    <p className="text-sm">
                      {index + 1}. {story?.title}
                    </p>
                  );
                })
              ) : (
                <p className="text-sm">{epic?.title} has no stories!</p>
              )}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}

export default EpicStories;
