import React from "react";
import ResourceAllocationCard from "./ResourceAllocationCard"
const ResourceAllocationMatrix = () => {
  return (
    <>
      <div className="w-full flex flex-col h-[88vh] border border-gray-100 rounded-2xl py-8 px-4 gap-[40px]">
        <div
          style={{ lineHeight: "150%" }}
          className="  text-xl text-black font-bold "
        >
          Resource Allocation Matrix
        </div>

      <ResourceAllocationCard />
      </div>
    </>
  );
};

export default ResourceAllocationMatrix;
