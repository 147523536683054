import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import EditTask from "components/Modals/EditTask";
import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "redux/toaster";

function Insights({ onUpdate, details, setDetails, setTab }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const [missing, setMissing] = useState([]);
  const [showEditModal, setShowEditModal] = useState({
    isOpen: false,
    type: "",
  });
  useEffect(() => {
    let temp = [];

    if (details?.tShirtSize == null || details?.tShirtSize == "R") {
      temp.push({ name: "Missing Tshirt size", type: "tShirtSize" });
    }
    if (details?.priority == null || details?.priority == 0) {
      temp.push({ name: "Missing priority", type: "priority" });
    }
    if (moment(details?.dueDate).unix() < moment().unix()) {
      temp.push({ name: "Past due date", type: "dueDate" });
    }

    setMissing(temp);
  }, [details]);

  return (
    <div className="lg:px-2.5">
      <EditTask
        isOpen={showEditModal?.isOpen}
        closeModal={() => setShowEditModal({ isOpen: false, type: "" })}
        task={details}
        details={details}
        setDetails={setDetails}
        type={showEditModal?.type}
        onUpdate={() => {
          onUpdate();
          setShowEditModal({ isOpen: false, type: "" });
        }}
      />
      <div className={`px-5 py-2.5 mt-6 rounded-10px space-y-5`}>
        <div className="flex flex-col space-y-5 font-bold">
          {moment().diff(moment(details?.dueDate), "days", false) > 0 && (
            <div className="font-lato text-xs lg:text-sm text-center flex flex-row items-center space-x-2.5 text-secondary-red-550">
              <ExclamationCircleIcon className=" w-4 h-4" />
              <p>
                This task has been due since
                {` ${moment().diff(
                  moment(details?.dueDate),
                  "days",
                  false
                )} day${
                  moment().diff(moment(details?.dueDate), "days", false) > 1
                    ? "s"
                    : ""
                }`}
              </p>
            </div>
          )}
          {moment().diff(moment(details?.dueDate), "days", false) == 0 && (
            <div className="font-lato text-xs lg:text-sm text-center flex flex-row items-center space-x-2.5 text-secondary-green-550">
              <img src="/assets/images/logos/live.svg" className=" w-6 h-6" />
              <p>This task is due today </p>
            </div>
          )}
          {moment().diff(moment(details?.dueDate), "days", false) < 0 &&
            moment().diff(moment(details?.dueDate), "days", false) > -8 && (
              <div className="font-lato text-xs lg:text-sm text-center flex flex-row items-center space-x-2.5 text-secondary-orange-550">
                <p>💪🏻 This task is due this week </p>
              </div>
            )}
        </div>
        <div className="flex flex-col space-y-5">
          <div className="font-lato text-xs lg:text-sm text-center flex flex-col items-start space-y-1.5 text-primary-gray-1000">
            <p>
              <b>0 Tasks</b> have been completed in this project
            </p>
            {/* <p
              onClick={() => {
                handleUpdate();
              }}
              className="text-secondary-green-550 cursor-pointer hover:underline font-lato"
            >
              {details?.in_focus == 1
                ? "You are already focusing on this"
                : "Be the first one to do it ->"}{" "}
            </p> */}
          </div>
          <div className="font-lato text-xs lg:text-sm text-center flex flex-col items-start space-y-1.5 text-primary-gray-1000">
            <p>
              <b>X Tasks</b> have been completed in this project
            </p>
            {/* <p
              onClick={() => {
                handleUpdate();
              }}
              className="text-secondary-orange-550 flex flex-row items-center cursor-pointer hover:underline font-lato"
            >
              <InformationCircleIcon className="w-4 h-4 mr-1.5" />{" "}
              {details?.in_focus == 1
                ? "You are already focusing on this"
                : "Consider focusing on it ->"}
            </p> */}
          </div>
        </div>
      </div>

      <div
        className={`${
          missing?.length == 0 ? "bg-secondary-green-50" : "bg-secondary-red-50"
        } px-5 py-2.5 mt-6 rounded-10px`}
      >
        <h1
          className={`${
            missing?.length == 0
              ? "text-secondary-green-550"
              : "text-secondary-red-550"
          } text-sm font-bold font-lato`}
        >
          Task Details that require your immediate attention
        </h1>
        <div className="mt-5 grid grid-cols-2 gap-x-2.5 gap-y-5">
          {missing?.length > 0 ? (
            <>
              {missing?.map((i, index) => {
                return (
                  <div
                    className={`flex flex-col space-y-2.5 ${
                      (index + 1) % 2 == 0 ? "items-end" : "items-start"
                    }`}
                  >
                    <h1 className="text-primary-gray-450 text-2xs font-lato">
                      {i?.name}
                    </h1>
                    <div
                      onClick={() => {
                        if (i?.type == "dueDate") {
                        } else {
                          if (
                            i?.type == "tShirtSize" &&
                            user?.id !== details?.ownerId
                          ) {
                            dispatch(
                              showToast({
                                message: "Can only be changed by owner!",
                                type: "error",
                              })
                            );
                            return;
                          }
                          if (
                            i?.type == "priority" &&
                            user?.id !== details?.creatorId
                          ) {
                            dispatch(
                              showToast({
                                message: "Can only be changed by manager!",
                                type: "error",
                              })
                            );
                            return;
                          }
                          setShowEditModal({
                            isOpen: true,
                            type:
                              i?.type == "tShirtSize"
                                ? "T-Shirt Size"
                                : "Priority",
                          });
                        }
                      }}
                      className={`w-full flex flex-row ${
                        (index + 1) % 2 == 0 ? "justify-end" : "justify-start"
                      }`}
                    >
                      {i?.type == "priority" ? (
                        <div className={`priority-light-0 lg:text-sm`}>P?</div>
                      ) : i?.type == "dueDate" ? (
                        <div
                          className={`bg-secondary-red-200 text-secondary-red-550 text-xs lg:text-sm font-lato font-normal py-1 px-2 rounded-full`}
                        >
                          {moment(details?.dueDate).format("LL")}
                        </div>
                      ) : i?.type == "tShirtSize" ? (
                        <div
                          className={` text-xs lg:text-sm font-lato font-normal py-1 px-2 rounded-full`}
                        >
                          <img
                            src="/assets/svg/noTSize.png"
                            alt=""
                            className="w-6"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="col-span-2 font-lato text-xs lg:text-sm text-center flex flex-row items-center space-x-2.5 text-primary-gray-1000">
              <p>All task details are already updated </p>
              <CheckCircleIcon className="text-symmetry-green w-4 h-4" />
            </div>
          )}
        </div>
      </div>
      <div className={`px-5 py-2.5 mt-6 rounded-10px space-y-5`}>
        <div className="flex flex-col space-y-5">
          {user?.id == details?.creator?.id &&
            user?.id == details?.owner?.id && (
              <div className="font-lato text-xs lg:text-sm text-center flex flex-col items-start space-y-1.5 text-primary-gray-1000">
                <p>
                  You <b>created</b> this task and you <b>own</b> it
                </p>
                <p
                  onClick={() => {
                    setTab("details");
                  }}
                  className="text-secondary-green-550 cursor-pointer hover:underline font-lato"
                >
                  Are you sure you don't want to delegate it?
                </p>
              </div>
            )}
          {user?.id == details?.owner?.id && (
            <div className="font-lato text-xs lg:text-sm text-center flex flex-col items-start space-y-1.5 text-primary-gray-1000">
              <p>
                You <b>own</b> this task
              </p>
              {/* {details?.students?.length > 0 ? (
                <p className="text-2xs lg:text-xs text-left">
                  You currently have {details?.length} collaborators on this
                  task
                </p>
              ) : (
                <p
                  onClick={() => {
                    setTab("details");
                  }}
                  className="text-secondary-green-550 cursor-pointer hover:underline font-lato"
                >
                  Add a collaborator ->
                </p>
              )} */}
              {/* <p className="text-secondary-orange-550 cursor-pointer hover:underline font-lato">
              Contribute more ->
            </p> */}
            </div>
          )}

          {details?.tShirtSize && details?.tShirtSize !== "R" && (
            <div className="font-lato text-xs lg:text-sm text-center flex flex-col items-start space-y-1.5 text-primary-gray-1000">
              <p>
                The T-shirt size of this task is <b>{details?.tShirtSize}</b>
              </p>
              <p className="text-2xs lg:text-xs text-left">
                This means that the task will take{" "}
                {details?.tShirtSize == "XS"
                  ? "less than a day"
                  : details?.tShirtSize == "S"
                  ? "a day"
                  : details?.tShirtSize == "M"
                  ? "3 days"
                  : details?.tShirtSize == "L"
                  ? "1 week"
                  : "2 weeks"}{" "}
                to complete
              </p>
              {/* <p className="text-secondary-orange-550 cursor-pointer hover:underline font-lato">
              Contribute more ->
            </p> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Insights;
