import { Doughnut } from "react-chartjs-2";

function PerformanceCard({ data }) {
  return (
    <div className="border border-gray-100 py-4 rounded-md">
      <h3 className="text-sm font-bold text-center">
        Goals allocation for the week
      </h3>
      <div className="flex p-2 mt-5">
        <Doughnut
          data={data}
          style={{
            width: "160px",
            height: "160px",
          }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
                align: "center",
                labels: {
                  boxWidth: 10,
                  boxHeight: 10,
                  padding: 10,
                  font: {
                    size: 10,
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default PerformanceCard;
