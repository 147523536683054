export const ALL_ACCESS = "all-access";
export const PMO = "pmo";
export const CPO = "cpo";
export const VMO = "vmo";
export const CAARYA_DIRECTOR = "caarya-director";
export const ALL_DELETE = "all-delete";
export const GENERAL_ACCESS = "general-access";
export const LEARNING_ADMIN = "learning-admin";
export const HR_ADMIN = "hr-admin";
export const RECRUITER = "recruiter";
export const LEARNING_COORDINATOR = "learning-coordinator";
export const HR_COORDINATOR = "hr-coordinator";
export const TEST_PASS = "test-pass";
export const PROJECT_OWNER = "project-owner";
export const PROJECT_MEMBER = "project-member";
export const PROJECT_MANAGER = "project-manager";
export const TEAM_LEAD = "team-lead";
export const TASK_OWNER = "task-owner";
export const MEDIA_MANAGER = "media-manager";
export const MEDIA_COORDINATOR = "media-coordinator";
export const NODE_MANAGER = "node-manager";
export const MARKETING_MANAGER = "marketing-manager";
export const NODE_COORDINATOR = "node-coordinator";
export const STARGAZER = "stargazer";
