import { classNames } from "helpers/utils/classNames";
import { RadioGroup } from "@headlessui/react";

function RadioInput({
  value,
  setValue,
  list,
  label = "",
  srOnly,
  basic,
  field,
}) {
  return (
    <>
      {basic ? (
        <>
          <label LabelclassName={srOnly ? "sr-only" : "input-label"}>
            {label}
          </label>
          <div className="grid grid-cols-2 gap-3">
            {list.map((option) => (
              <div className="flex flex-row items-center space-x-2.5">
                <input
                  type="radio"
                  id={option.value}
                  value={option?.value}
                  name={field}
                  onChange={(e) => {
                    setValue(option?.value);
                  }}
                  checked={option?.value == value}
                  className={({ active, checked }) =>
                    classNames(
                      option?.value == value
                        ? "bg-primary-orange-medium border-transparent text-black "
                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                      "border rounded-md p-2 flex items-center justify-center text-2xs font-lato font-medium sm:flex-1"
                    )
                  }
                ></input>
                <label
                  for={option?.value}
                  className="text-primary-gray-900 text-xs font-lato"
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </>
      ) : (
        <RadioGroup
          value={value}
          onChange={(e) => {
            console.log(e);
            setValue(e);
            // setItem(e);
          }}
          className="mt-2"
        >
          <RadioGroup.Label className={srOnly ? "sr-only" : "input-label"}>
            {label}
          </RadioGroup.Label>
          <div className="grid grid-cols-2 gap-3">
            {list.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option?.value}
                className={({ active, checked }) =>
                  classNames(
                    active
                      ? "ring-2 ring-offset-2 ring-primary-orange-medium"
                      : "",
                    option?.value == value
                      ? "bg-primary-orange-medium border-transparent text-black "
                      : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                    "border rounded-md p-2 flex items-center justify-center text-2xs font-lato font-medium sm:flex-1"
                  )
                }
              >
                <RadioGroup.Label as="p">{option.label}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      )}
    </>
  );
}

export default RadioInput;
