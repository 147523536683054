import { pmoInstance } from "config";
import { getHeader } from "config";

export const createEpics = (body) => {
  return pmoInstance.post(`/epic`, body, getHeader());
};

/**
 * To delete Epics
 * @param id --> id of Epics
 */
export const deleteEpics = (id) => {
  return pmoInstance.delete(`/epic/${id}`, getHeader());
};

/**
 * To get all Epics
 * @param type --> Epics
 */
export const getAllEpics = (query) => {
  return pmoInstance.get(
    `/epic${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

/**
 * Gives a Epics
 * @param id --> id of Epics
 */
export const getEpicsById = (id, query) => {
  return pmoInstance.get(
    `/epic/${id}${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};
/**
 * To update Epics
 * @param id --> id of Epics
 */
export const updateEpics = (id, body) => {
  return pmoInstance.patch(`/epic/${id}`, body, getHeader());
};
