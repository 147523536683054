import React from "react";
import { MdOutlineDateRange } from "react-icons/md";

const GoalCard = () => {
  return (
    <div className="goal-card flex flex-col space-y-4">
      <div className="flex flex-row justify-between items-center w-full">
        <div className="goal-card-header">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M12.9866 8.2331C11.94 5.5131 8.21329 5.36643 9.11329 1.4131C9.17996 1.11977 8.86663 0.893101 8.61329 1.04643C6.19329 2.4731 4.45329 5.3331 5.91329 9.07977C6.03329 9.38643 5.67329 9.6731 5.41329 9.4731C4.20663 8.55977 4.07996 7.24643 4.18663 6.30643C4.22663 5.95977 3.77329 5.7931 3.57996 6.07977C3.12663 6.7731 2.66663 7.8931 2.66663 9.57977C2.91996 13.3131 6.07329 14.4598 7.20663 14.6064C8.82663 14.8131 10.58 14.5131 11.84 13.3598C13.2266 12.0731 13.7333 10.0198 12.9866 8.2331ZM6.79996 11.5864C7.75996 11.3531 8.25329 10.6598 8.38663 10.0464C8.60663 9.0931 7.74663 8.15977 8.32663 6.6531C8.54663 7.89977 10.5066 8.67977 10.5066 10.0398C10.56 11.7264 8.73329 13.1731 6.79996 11.5864Z"
              stroke="#696763"
            />
          </svg>
          Prioritize
        </div>

        <div className="flex gap-[8px] items-center">
          <div className="in-progress">In Progress</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M6.33317 2C6.33317 2.53043 6.54388 3.03914 6.91896 3.41421C7.29403 3.78929 7.80274 4 8.33317 4C8.8636 4 9.37231 3.78929 9.74739 3.41421C10.1225 3.03914 10.3332 2.53043 10.3332 2H14.3332C14.51 2 14.6796 2.07024 14.8046 2.19526C14.9296 2.32029 14.9998 2.48986 14.9998 2.66667V7.33333C14.9998 7.51014 14.9296 7.67971 14.8046 7.80474C14.6796 7.92976 14.51 8 14.3332 8H12.9992L12.9998 13.3333C12.9998 13.5101 12.9296 13.6797 12.8046 13.8047C12.6796 13.9298 12.51 14 12.3332 14H4.33317C4.15636 14 3.98679 13.9298 3.86177 13.8047C3.73674 13.6797 3.6665 13.5101 3.6665 13.3333L3.66584 7.99933L2.33317 8C2.15636 8 1.98679 7.92976 1.86177 7.80474C1.73674 7.67971 1.6665 7.51014 1.6665 7.33333V2.66667C1.6665 2.48986 1.73674 2.32029 1.86177 2.19526C1.98679 2.07024 2.15636 2 2.33317 2H6.33317ZM13.6665 3.33267H11.3885L11.3772 3.36C11.1243 3.92523 10.7191 4.40891 10.2069 4.75685C9.69475 5.10479 9.09579 5.30325 8.47717 5.33L8.33317 5.33333C7.68944 5.33339 7.05945 5.14708 6.51929 4.79691C5.97913 4.44674 5.55189 3.94768 5.28917 3.36L5.27717 3.33333H2.99984V6.66667L4.99917 6.666V12.6667H11.6665L11.6658 6.66667L13.6665 6.666V3.33267Z"
              fill="#4CA6E5"
            />
          </svg>
          <span className="task-time">1h</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M10.3332 2.5C9.4165 2.5 8.6665 3.25 8.6665 4.16667C8.6665 5.08333 9.4165 5.83333 10.3332 5.83333C11.2498 5.83333 11.9998 5.08333 11.9998 4.16667C11.9998 3.25 11.2498 2.5 10.3332 2.5ZM10.3332 14.1667C9.4165 14.1667 8.6665 14.9167 8.6665 15.8333C8.6665 16.75 9.4165 17.5 10.3332 17.5C11.2498 17.5 11.9998 16.75 11.9998 15.8333C11.9998 14.9167 11.2498 14.1667 10.3332 14.1667ZM10.3332 8.33333C9.4165 8.33333 8.6665 9.08333 8.6665 10C8.6665 10.9167 9.4165 11.6667 10.3332 11.6667C11.2498 11.6667 11.9998 10.9167 11.9998 10C11.9998 9.08333 11.2498 8.33333 10.3332 8.33333Z"
              fill="#696763"
            />
          </svg>
        </div>
      </div>
      <div>
        <p className="text-base font-semibold">Some Random Goal</p>
        <p className="font-inter text-xs font-light">
          Goal Description here in few words
        </p>
      </div>

      <div className="flex gap-[8px] items-center justify-start">
        <div className="assigned-to">Assigned To</div>
        <div className="member-avatar"></div>
        <div
          style={{
            color: "var(--Neutral-400, #696763)",
            fontSize: "10px",
            fontFamily: "Inter",
          }}
          className="font-light"
        >
          Member Name
        </div>
      </div>

      <div className="flex justify-between items-center w-full">
        <div className="flex gap-[10px] justify-center items-center">
          <input
            type="checkbox"
            id="coding"
            name="interest"
            value="coding"
            className="checkbox"
          />
          <span className="battle-name">Battle Name</span>
        </div>
        <div className="font-light flex gap-[10px] justify-center items-center">
          <MdOutlineDateRange />
          <span className="task-date">dd-mm-yy</span>
        </div>
      </div>
    </div>
  );
};

export default GoalCard;
