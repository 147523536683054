// Participants.js
import { MdNavigateBefore } from "react-icons/md";

import {
  participants,
  optedOutParticipants,
} from "../../helpers/constants/EventPlanner"; // import your data
import SingleParticipant from "./SinlgeParticipant";
import OptedOutParticipant from "./OptedOutParticipant";

const Participants = ({ toggleDrawer }) => {
  return (
    <div className="w-[480px] bg-white space-y-6 flex flex-col">
      <div className="p-4">
        <button
          onClick={toggleDrawer(false)}
          className="flex space-x-2 justify-center items-center"
        >
          <MdNavigateBefore className="text-black" />
          <p className="font-inter font-semibold underline-offset-4 underline">
            Back
          </p>
        </button>
      </div>
      <div className="px-6 space-y-4">
        <div className="space-y-2 flex flex-col">
          <div>
            <p className="font-poppins font-medium text-base ">
              All Participants ({participants.length})
            </p>
          </div>
          {participants.map((participant) => (
            <SingleParticipant
              key={participant.name}
              name={participant.name}
              imageSrc={participant.imageSrc}
            />
          ))}
        </div>
        <div className="space-y-2 flex flex-col">
          <div>
            <p className="font-poppins font-medium text-base ">
              Opted Out ({optedOutParticipants.length})
            </p>
          </div>
          {optedOutParticipants.map((participant) => (
            <OptedOutParticipant
              key={participant.name}
              name={participant.name}
              time={participant.time}
              imageSrc={participant.imageSrc}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Participants;
