import { useEffect, useState } from "react";
import { Skeleton, StoryItem } from "./Item";
import { CheckCircle, PlusCircle, XCircle } from "@phosphor-icons/react";
import * as StoriesAPI from "config/APIs/task/stories";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import ConfirmModal from "components/Modals/Common/ConfirmModal";
import EditStory from "components/Modals/Story/EditStory";

export default function Stories({
  selectedProduct,
  setIsOpen,
  selectedEpic,
  setSelectedStory,
}) {
  const dispatch = useDispatch();
  const [stories, setStories] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [openMobileCreateModal, setOpenMobileCreateModal] = useState(false);
  const [newStory, setNewStory] = useState({ title: "" });
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState(false);

  const fetchStories = async () => {
    try {
      setFetching(true);
      let query = { product_id: selectedProduct?.id };
      if (selectedEpic) {
        query["epic_id"] = selectedEpic.id;
      }
      const response = await StoriesAPI.getAllStories(query);
      let d = response.data.data.response || [];
      setStories(d);
      console.log(d);
    } catch (err) {
      console.log("Fetch goal task error", err);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (selectedEpic) {
      fetchStories();
    }
  }, [selectedProduct, selectedEpic]);

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleDelete = async () => {
    try {
      await StoriesAPI.deleteStories(selectedToDelete?.id);
      showSuccessNotification("Story deleted successfully!");
      fetchStories();
      setSelectedToDelete(null);
    } catch (err) {
      console.log("Story delete error", err);
      showErrorNotification("Something went wrong!");
    }
  };

  const handleSubmit = async () => {
    try {
      let body = {
        ...newStory,
        epic_id: selectedEpic?.id,
        product_id: selectedProduct?.id,
      };

      const response = await StoriesAPI.createStories(body);

      setOpenMobileCreateModal(false);
      setNewStory({ title: "" });
      fetchStories();
    } catch (err) {
      console.log("Story delete error", err);
      showErrorNotification("Something went wrong!");
    }
  };

  return (
    <div className="w-full h-[88vh] epicStoryList p-4 px-6 shadow-md">
      {/* <StoryCreate
        epic={selectedEpic}
        product={selectedProduct}
        isOpen={openMobileCreateModal}
        closeModal={() => setOpenMobileCreateModal(false)}
        onCreate={() => fetchStories()}
      /> */}

      <EditStory
        epic={selectedEpic}
        product={selectedProduct}
        isOpen={openEditModal ? true : false}
        closeModal={() => setOpenEditModal()}
        onUpdate={() => fetchStories()}
        editValues={openEditModal}
        goal={selectedEpic?.goal}
        onDelete={(val) => {
          setSelectedToDelete(val);
          setOpenEditModal();
        }}
      />
      <ConfirmModal
        isOpen={selectedToDelete ? true : false}
        closeModal={() => setSelectedToDelete(null)}
        onAccept={() => handleDelete()}
        text={
          <>
            {`Are you sure you want to delete the following story: ${selectedToDelete?.title}`}
          </>
        }
      />
      <div className="flex justify-between items-center">
        <h2 className="font-extrabold text-xl text-black">Stories</h2>
        {/* {!openMobileCreateModal && (
          <button
            className="flex items-center gap-1 p-1.5 text-xs text-black"
            onClick={() => setOpenMobileCreateModal(true)}
          >
            <PlusCircle size={12} />
            Add Story
          </button>
        )} */}
      </div>
      <div className="flex flex-col mt-3 gap-6 h-[80vh] overflow-y-auto">
        {/* {openMobileCreateModal && (
          <div className="flex flex-col space-y-1">
            <div className="w-full flex cursor-pointer flex-row items-center space-x-1 justify-end">
              <CheckCircle
                size={24}
                onClick={() => {
                  handleSubmit();
                }}
              />
              <XCircle
                size={24}
                onClick={() => {
                  setOpenMobileCreateModal(false);
                  setNewStory({ title: "" });
                }}
              />
            </div>
            <textarea
              placeholder="Add new Story"
              value={newStory?.title}
              onChange={(e) => {
                setNewStory({ ...newStory, title: e.target.value });
              }}
              rows={3}
              className="bg-white border text-xs border-primary-gray-300 rounded"
            />
          </div>
        )} */}
        {!fetching ? (
          stories?.length > 0 ? (
            stories.map((story, i) => (
              <StoryItem
                key={i}
                story={story}
                onClick={() => {
                  setIsOpen(true);
                  setSelectedStory(story);
                }}
                onEdit={() => {
                  setOpenEditModal(story);
                }}
                onUpdate={() => fetchStories()}
              />
            ))
          ) : (
            <>
              {/* <EmptyState
                image="/assets/images/empty/story.png"
                text="The epic doesn't have any story!"
              /> */}
            </>
          )
        ) : (
          [1, 2, 3, 4, 5].map((_, i) => <Skeleton key={i} />)
        )}
        {openMobileCreateModal ? (
          <div className="z-20 flex flex-col space-y-1">
            <textarea
              placeholder="Add new Story"
              autoFocus
              value={newStory?.title}
              onChange={(e) => {
                setNewStory({ ...newStory, title: e.target.value });
              }}
              rows={3}
              className="bg-white border text-xs border-primary-gray-300 rounded"
            />
            <div className="w-full flex cursor-pointer flex-row items-center space-x-1 justify-end">
              <CheckCircle
                size={24}
                onClick={() => {
                  handleSubmit();
                }}
              />
              <XCircle
                size={24}
                onClick={() => {
                  setOpenMobileCreateModal(false);
                  setNewStory({ title: "" });
                }}
              />
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              setOpenMobileCreateModal(true);
            }}
            className="z-20 bg-white border text-sm cursor-pointer p-3 border-primary-gray-300 rounded-lg flex flex-row items-center text-black space-x-1"
          >
            <PlusCircle size={20} />
            <p>Add New Story</p>
          </div>
        )}
      </div>
    </div>
  );
}
