import React, { useState } from "react";
import TaskListLoader from "components/Comman/Loaders/TaskListLoader";
import EmptyState from "components/Comman/EmptyState";
import { PlusIcon, XIcon } from "@heroicons/react/solid";
import ChronosButton from "components/Comman/Buttons";
import GoalCreateModal from "components/Goals/Modals/GoalCreate";
import GoalCard from "components/Goals/Card/GoalCard";
import GoalPageLaptop from "components/Goals/Modals/GoalPageLaptop";
import { useHistory } from "react-router-dom";

function UsersGoal({
  goals,
  fetching,
  getGoals,
  details,
  projects,
  statsList,
  goalQuery,
  setGoalQuery,
}) {
  const history = useHistory();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [recentlyAdded, setRecentlyAdded] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(false);
  return (
    <>
      <GoalCreateModal
        ownerId={details?.id}
        isOpen={openCreateModal}
        userProjects={projects}
        closeModal={() => setOpenCreateModal(false)}
        onCreate={(val) => {
          setOpenCreateModal(false);
          console.log(val, recentlyAdded);

          let temp = [val, ...recentlyAdded];
          setRecentlyAdded(temp.slice(0, 3));
          if (temp?.length > 3 || goals?.length <= 3) {
            getGoals();
          }
        }}
      />
      <GoalPageLaptop
        isOpen={selectedGoal}
        closeModal={() => setSelectedGoal()}
        id={selectedGoal?.id}
      />
      <div className="w-full px-5 pb-32">
        <div className="w-full flex flex-row items-center justify-end mb-2.5">
          <ChronosButton
            onClick={() => {
              setOpenCreateModal(true);
            }}
            text="Add Goal"
            icon={<PlusIcon className="icon w-4 h-4 mr-1.5" />}
            reverseIcon
            primary
          />
        </div>

        {recentlyAdded?.length > 0 && (
          <>
            <h1 className="text-xs ml-3 mb-2.5 font-lato font-semibold leading-4 text-primary-gray-900">
              Recently added
            </h1>
            <div className="space-y-5">
              {recentlyAdded?.map((item, idx) => {
                return (
                  <GoalCard
                    onUpdate={() => getGoals()}
                    index={idx}
                    item={item}
                  />
                );
              })}
            </div>
          </>
        )}
        {!fetching && goals?.length > 0 && (
          <>
            <div className="flex flex-row items-center space-x-3 pl-3 mb-2.5 ">
              <h1 className="text-xs font-lato font-semibold leading-4 text-primary-gray-900">
                {Object.keys(goalQuery)?.length > 0
                  ? `Showing: ${
                      statsList?.find((item) =>
                        JSON.stringify(goalQuery)?.includes(
                          JSON.stringify(item?.condition)
                        )
                      )?.label
                    }`
                  : "Other Goals"}
              </h1>
              {Object.keys(goalQuery)?.length > 0 && (
                <XIcon
                  className="w-3 h-3 text-primary-red-medium cursor-pointer"
                  onClick={() => {
                    setGoalQuery({});
                  }}
                />
              )}
            </div>
            <div className="space-y-5">
              {goals?.map((item, idx) => {
                return (
                  <GoalCard
                    onClick={() => {
                      localStorage.removeItem("goalTab");
                      localStorage.setItem("userTab", "goals");
                      window.innerWidth < 1024
                        ? history.push(`/goals/${item?.id}`)
                        : setSelectedGoal(item);
                    }}
                    onUpdate={() => getGoals()}
                    index={idx}
                    item={item}
                  />
                );
              })}
            </div>
          </>
        )}

        {fetching && <TaskListLoader />}
        {goals?.length === 0 && (
          <div className="h-40vh w-full flex flex-row items-center justify-center">
            <EmptyState
              imageMedium
              image={"/assets/images/empty/goal.png"}
              text="The user doesn't have any goals yet!"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default UsersGoal;
