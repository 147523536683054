import React from 'react'

const GraphCard = () => {
  return (
    <>
      <div
        style={{
          color: "var(--Neutral-500, #696763)",
          letterSpacing: "0.32px",
        }}
        className="flex gap-[10px]"
      >

        <img src="#" className="rounded-full" />
        <div className="flex flex-col">
          <span className="text-base font-semibold"> Broklyn Simons </span>
          <span className="text-xs font-light"> Designation </span>

        </div>

      </div>
      <div className='text-sm font-semibold text-neutral-500'>This Week</div>
    </>
  )
}

export default GraphCard