// SingleParticipant.js
import { RxAvatar } from "react-icons/rx";

const SingleParticipant = ({ name, imageSrc }) => {
  return (
    <div className="px-6 py-4 space-x-4 flex flex-row items-center">
      <div>
        <RxAvatar className="h-[32px] w-[32px]" />
        {/* <img src={imageSrc} alt={name} className="h-10 w-10 rounded-full" />   */}
      </div>
      <div>
        <p className="font-inter font-normal text-base text-[#363430]">
          {name}
        </p>
      </div>
    </div>
  );
};

export default SingleParticipant;
