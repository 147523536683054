import { getHeader } from "config";
import { pmoInstance } from "config";

/**
 * To get token after number verification
 */
export const loginWithFirebaseAuthToken = (token) => {
  return pmoInstance.post(`/auth/mobile/token`, { token });
};

/**
 * To verify if Phone Number exists in DB and is a Chronos user (admin)
 */
export const verifyPhoneNumberForOTP = (phone_number) => {
  return pmoInstance.post(`/auth/mobile/verify`, { phone_number });
};

export const refreshToken = () => pmoInstance.get("/auth/token", getHeader());
