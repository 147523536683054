import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { TaskStatus } from "helpers/task";

export default function StatusDropDown({ status, setStatus, index, disabled }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button disabled={disabled} className="">
          <span className="h-10 w-8 rounded-full flex items-center justify-center py-2 bg-primary-gray-50">
            <img
              src={`/assets/images/icons/goal${status || "Gray"}.svg`}
              alt=""
              className={`h-6 w-6`}
            />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{
            borderRadius:
              index === 0 ? "0px 20px 20px 20px" : "20px 20px 20px 0px",
          }}
          className={`${
            index === 0
              ? "origin-top-left top-5"
              : "origin-bottom-left bottom-8"
          } left-8 absolute  w-28 z-30 shadow-lg px-3 py-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1 max-h-64 overflow-y-auto space-y-1.5">
            {TaskStatus.map((item) => {
              return (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        setStatus(item?.value);
                      }}
                      className={`status-dark-${item?.value} text-center`}
                    >
                      {item.label}
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
