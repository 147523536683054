import {
  Calendar,
  CalendarBlank,
  Clock,
  Dot,
  DotsThreeOutlineVertical,
  TShirt,
  Timer,
} from "@phosphor-icons/react";
import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { getTotalTime } from "helpers/utils/common/clock";
import StatusDropDown from "components/Tasks/Card/StatusDropDown";
import { getGoalPriority } from "helpers/utils/goal";
import { deleteGoal, updateGoal } from "config/APIs/task/goal";
import { TRACK_CATEGORY } from "helpers/constants/tracks";
import AddProjectModal from "components/SquadUser/Modals/AddProject";
import ConfirmModal from "components/Modals/Common/ConfirmModal";
import PriorityDropdown from "components/Tasks/Card/PriorityDropdown";
import AddMembers from "components/Modals/AddMembers";

function GoalHubCard({ item, onUpdate, type }) {
  const [focusTime, setFocusTime] = useState();
  const [status, setStatus] = useState();
  const [priority, setPriority] = useState();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openAddOwnerModal, setOpenAddOwnerModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    setStatus(item?.status);
    setPriority(item?.priority);

    setFocusTime(item?.focusIn);
  }, [item]);

  const handleUpdate = async ({
    status,
    priority,
    imp,
    ur,
    focus,
    projectId,
    ownerId,
  }) => {
    try {
      let body = {};
      if (status) body["status"] = status;
      if (priority) body["priority"] = priority;
      if (ownerId) body["ownerId"] = ownerId;
      if (focus) body["focusIn"] = focus;
      if (imp || ur) body["priority"] = getGoalPriority(imp, ur);
      if (projectId) body["projectId"] = projectId;
      await updateGoal(item?.id, body);
      onUpdate();
    } catch (err) {
      console.log("Error", err);
      console.log(err?.response);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteGoal(item?.id);
      setOpenDeleteModal();
      onUpdate();
    } catch (err) {
      console.log("Error", err);
      console.log(err?.response);
    }
  };

  return (
    <>
      <ConfirmModal
        isOpen={openDeleteModal}
        closeModal={() => {
          setOpenDeleteModal(false);
        }}
        onAccept={() => {
          handleDelete();
        }}
        text={<>Are you sure you want to delete {item?.title}</>}
      />
      <AddMembers
        isOpen={openAddOwnerModal}
        closeModal={() => setOpenAddOwnerModal()}
        id={item?.id}
        onUpdate={(id, val) => {
          if (val?.length > 0) handleUpdate({ ownerId: val[0]?.id });

          setOpenAddOwnerModal(null);
        }}
        selectOne
        allUsers
        type="Owner"
        goal={item}
      />{" "}
      <AddProjectModal
        closeModal={() => setOpenAddModal(false)}
        isOpen={openAddModal}
        forGoals
        onAdd={(val) => {
          if (val?.length > 0) {
            let p = val[0];
            handleUpdate({ projectId: p?.id });
          }
        }}
      />
      <div
        className={`border relative space-y-6 border-primary-gray-200 rounded-tl-lg rounded-b-lg rounded-tr-lg bg-white p-4`}
      >
        <div className="flex flex-row items-center justify-between">
          {item?.track ? (
            <div className="flex flex-row items-center space-x-1 text-primary-gray-300 text-3xs font-lato">
              <Dot
                weight="fill"
                size={10}
                className="text-caarya-red-lighter"
              />
              <p className="font-semibold">{item?.track?.category} / </p>
              <p className="font-light">{item?.track?.title}</p>
            </div>
          ) : (
            <div>
              {" "}
              {/* {item?.sessions?.length > 0 && status !== "NotStarted" && (
                <div className="flex pt-2.5 flex-row items-center space-x-2 px-2">
                  <div className="px-2 py-1 flex flex-row items-center space-x-2 rounded border border-primary-gray-200 text-primary-gray-600 text-2xs font-lato font-light">
                    <Timer size={16} color="#816FE9" />
                    <p>
                      Total:{" "}
                      <span className="font-medium">
                        {getTotalTime(item?.sessions)}
                      </span>
                    </p>
                  </div>{" "}
                </div>
              )}
               */}
              <div className="flex flex-row items-center space-x-4">
                {item?.priority ? (
                  <div
                    style={{
                      background:
                        "linear-gradient(180deg, #FFD844 0%, #F57D34 100%)",
                    }}
                    className="rounded w-6 h-6 flex flex-row items-center justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M6.00017 11.5C6.99473 11.5 7.94856 11.1049 8.65182 10.4017C9.35508 9.69839 9.75017 8.74456 9.75017 7.75C9.75017 7.317 9.63517 6.9015 9.50017 6.515C8.66667 7.3385 8.03367 7.75 7.60017 7.75C9.59767 4.25 8.50017 2.75 5.50017 0.75C5.75017 3.25 4.10217 4.387 3.43117 5.0185C2.88383 5.53334 2.50379 6.20074 2.34031 6.93416C2.17683 7.66759 2.23746 8.43321 2.51434 9.13177C2.79122 9.83032 3.27157 10.4296 3.89312 10.8519C4.51467 11.2741 5.24875 11.4999 6.00017 11.5ZM6.35517 2.6175C7.97567 3.9925 7.98367 5.061 6.73167 7.2545C6.35117 7.921 6.83267 8.75 7.60017 8.75C7.94417 8.75 8.29217 8.65 8.65967 8.4525C8.55074 8.86399 8.34777 9.24458 8.06675 9.56429C7.78572 9.884 7.43432 10.1341 7.0402 10.2949C6.64609 10.4558 6.22002 10.5229 5.79555 10.491C5.37107 10.4592 4.95979 10.3293 4.59407 10.1115C4.22836 9.89364 3.91819 9.59391 3.68801 9.23585C3.45783 8.87779 3.31391 8.47118 3.26758 8.04805C3.22125 7.62491 3.27378 7.1968 3.42104 6.79741C3.56829 6.39803 3.80625 6.03829 4.11617 5.7465C4.17917 5.6875 4.49867 5.404 4.51267 5.3915C4.72467 5.2015 4.89917 5.033 5.07167 4.8485C5.68667 4.1895 6.12867 3.4585 6.35467 2.6175H6.35517Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="rounded w-6 h-6 border border-primary-neutral-200 flex flex-row items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M6.00017 11.5C6.99473 11.5 7.94856 11.1049 8.65182 10.4017C9.35508 9.69839 9.75017 8.74456 9.75017 7.75C9.75017 7.317 9.63517 6.9015 9.50017 6.515C8.66667 7.3385 8.03367 7.75 7.60017 7.75C9.59767 4.25 8.50017 2.75 5.50017 0.75C5.75017 3.25 4.10217 4.387 3.43117 5.0185C2.88383 5.53334 2.50379 6.20074 2.34031 6.93416C2.17683 7.66759 2.23746 8.43321 2.51434 9.13177C2.79122 9.83032 3.27157 10.4296 3.89312 10.8519C4.51467 11.2741 5.24875 11.4999 6.00017 11.5ZM6.35517 2.6175C7.97567 3.9925 7.98367 5.061 6.73167 7.2545C6.35117 7.921 6.83267 8.75 7.60017 8.75C7.94417 8.75 8.29217 8.65 8.65967 8.4525C8.55074 8.86399 8.34777 9.24458 8.06675 9.56429C7.78572 9.884 7.43432 10.1341 7.0402 10.2949C6.64609 10.4558 6.22002 10.5229 5.79555 10.491C5.37107 10.4592 4.95979 10.3293 4.59407 10.1115C4.22836 9.89364 3.91819 9.59391 3.68801 9.23585C3.45783 8.87779 3.31391 8.47118 3.26758 8.04805C3.22125 7.62491 3.27378 7.1968 3.42104 6.79741C3.56829 6.39803 3.80625 6.03829 4.11617 5.7465C4.17917 5.6875 4.49867 5.404 4.51267 5.3915C4.72467 5.2015 4.89917 5.033 5.07167 4.8485C5.68667 4.1895 6.12867 3.4585 6.35467 2.6175H6.35517Z"
                        fill="#CFCDC9"
                      />
                    </svg>
                  </div>
                )}
                <div className="flex flex-row items-center space-x-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M6.75 2.25C6.75 2.84674 6.98705 3.41903 7.40901 3.84099C7.83097 4.26295 8.40326 4.5 9 4.5C9.59674 4.5 10.169 4.26295 10.591 3.84099C11.0129 3.41903 11.25 2.84674 11.25 2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V8.25C16.5 8.44891 16.421 8.63968 16.2803 8.78033C16.1397 8.92098 15.9489 9 15.75 9H14.2493L14.25 15C14.25 15.1989 14.171 15.3897 14.0303 15.5303C13.8897 15.671 13.6989 15.75 13.5 15.75H4.5C4.30109 15.75 4.11032 15.671 3.96967 15.5303C3.82902 15.3897 3.75 15.1989 3.75 15L3.74925 8.99925L2.25 9C2.05109 9 1.86032 8.92098 1.71967 8.78033C1.57902 8.63968 1.5 8.44891 1.5 8.25V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25H6.75ZM15 3.74925H12.4373L12.4245 3.78C12.1401 4.41589 11.6842 4.96003 11.108 5.35146C10.5318 5.74289 9.85795 5.96616 9.162 5.99625L9 6C8.2758 6.00006 7.56707 5.79047 6.95938 5.39653C6.3517 5.00259 5.87106 4.44114 5.5755 3.78L5.562 3.75H3V7.5L5.24925 7.49925V14.25H12.75L12.7493 7.5L15 7.49925V3.74925Z"
                      fill="#4CA6E5"
                    />
                  </svg>
                  <p className="text-primary-neutral-500 font-lato text-xs font-semibold">
                    {item?.metaData?.size}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-row items-center space-x-2">
            <StatusDropDown
              status={status}
              setStatus={(val) => {
                handleUpdate({ status: val });
                setStatus(val);
              }}
            />

            <Menu as="div" className="relative block text-left">
              <Menu.Button className="">
                <DotsThreeOutlineVertical size={16} weight="fill" />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  style={{ borderRadius: "20px 0px 20px 20px" }}
                  className={`origin-top-right absolute right-0 w-24 z-10 shadow-lg bg-white ring-1 ring-primary-gray-1000 ring-opacity-5 focus:outline-none `}
                >
                  <div className="py-1 max-h-64 overflow-y-auto relative z-50">
                    {[1].map((item) => {
                      return (
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={() => {
                                setOpenDeleteModal(true);
                              }}
                              className="text-red-500 font-lato font-normal block px-4 py-2 text-2xs cursor-pointer"
                            >
                              Delete
                            </div>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="flex flex-col items-start space-y-2 px-2">
          <h1 className="text-black font-lato text-base leading-6 font-semibold">
            {item?.title}
          </h1>
          <p className="text-primary-gray-800 font-lato text-xs leading-[18px] font-light">
            {item?.description}
          </p>
        </div>

        {item?.sessions?.length > 0 && status !== "NotStarted" && (
          <div className="grid grid-cols-2 gap-6">
            <div className="px-2 py-1 flex flex-row items-center justify-center space-x-2 rounded border border-primary-gray-200 text-primary-gray-600 text-2xs font-lato font-light">
              <Timer size={16} color="#816FE9" />
              <p>
                Total:{" "}
                <span className="font-medium">
                  {getTotalTime(item?.sessions)}
                </span>
              </p>
            </div>{" "}
            <div className="px-2 py-1 flex flex-row items-center justify-center space-x-2 rounded border border-primary-gray-200 text-primary-gray-600 text-2xs font-lato font-light">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
              >
                <path
                  d="M4.375 9.6875C4.375 9.57147 4.42109 9.46019 4.50314 9.37814C4.58519 9.29609 4.69647 9.25 4.8125 9.25H6.5625C6.67853 9.25 6.78981 9.29609 6.87186 9.37814C6.95391 9.46019 7 9.57147 7 9.6875C7 9.80353 6.95391 9.91481 6.87186 9.99686C6.78981 10.0789 6.67853 10.125 6.5625 10.125H4.8125C4.69647 10.125 4.58519 10.0789 4.50314 9.99686C4.42109 9.91481 4.375 9.80353 4.375 9.6875ZM4.375 7.9375C4.375 7.82147 4.42109 7.71019 4.50314 7.62814C4.58519 7.54609 4.69647 7.5 4.8125 7.5H9.1875C9.30353 7.5 9.41481 7.54609 9.49686 7.62814C9.57891 7.71019 9.625 7.82147 9.625 7.9375C9.625 8.05353 9.57891 8.16481 9.49686 8.24686C9.41481 8.32891 9.30353 8.375 9.1875 8.375H4.8125C4.69647 8.375 4.58519 8.32891 4.50314 8.24686C4.42109 8.16481 4.375 8.05353 4.375 7.9375ZM4.375 6.1875C4.375 6.07147 4.42109 5.96019 4.50314 5.87814C4.58519 5.79609 4.69647 5.75 4.8125 5.75H9.1875C9.30353 5.75 9.41481 5.79609 9.49686 5.87814C9.57891 5.96019 9.625 6.07147 9.625 6.1875C9.625 6.30353 9.57891 6.41481 9.49686 6.49686C9.41481 6.57891 9.30353 6.625 9.1875 6.625H4.8125C4.69647 6.625 4.58519 6.57891 4.50314 6.49686C4.42109 6.41481 4.375 6.30353 4.375 6.1875ZM4.375 4.4375C4.375 4.32147 4.42109 4.21019 4.50314 4.12814C4.58519 4.04609 4.69647 4 4.8125 4H9.1875C9.30353 4 9.41481 4.04609 9.49686 4.12814C9.57891 4.21019 9.625 4.32147 9.625 4.4375C9.625 4.55353 9.57891 4.66481 9.49686 4.74686C9.41481 4.82891 9.30353 4.875 9.1875 4.875H4.8125C4.69647 4.875 4.58519 4.82891 4.50314 4.74686C4.42109 4.66481 4.375 4.55353 4.375 4.4375Z"
                  fill="#ABE085"
                />
                <path
                  d="M2.625 0.5H11.375C11.8391 0.5 12.2842 0.684374 12.6124 1.01256C12.9406 1.34075 13.125 1.78587 13.125 2.25V12.75C13.125 13.2141 12.9406 13.6592 12.6124 13.9874C12.2842 14.3156 11.8391 14.5 11.375 14.5H2.625C2.16087 14.5 1.71575 14.3156 1.38756 13.9874C1.05937 13.6592 0.875 13.2141 0.875 12.75V11.875H1.75V12.75C1.75 12.9821 1.84219 13.2046 2.00628 13.3687C2.17038 13.5328 2.39294 13.625 2.625 13.625H11.375C11.6071 13.625 11.8296 13.5328 11.9937 13.3687C12.1578 13.2046 12.25 12.9821 12.25 12.75V2.25C12.25 2.01794 12.1578 1.79538 11.9937 1.63128C11.8296 1.46719 11.6071 1.375 11.375 1.375H2.625C2.39294 1.375 2.17038 1.46719 2.00628 1.63128C1.84219 1.79538 1.75 2.01794 1.75 2.25V3.125H0.875V2.25C0.875 1.78587 1.05937 1.34075 1.38756 1.01256C1.71575 0.684374 2.16087 0.5 2.625 0.5Z"
                  fill="#ABE085"
                />
                <path
                  d="M0.875 4.875V4.4375C0.875 4.32147 0.921094 4.21019 1.00314 4.12814C1.08519 4.04609 1.19647 4 1.3125 4C1.42853 4 1.53981 4.04609 1.62186 4.12814C1.70391 4.21019 1.75 4.32147 1.75 4.4375V4.875H2.1875C2.30353 4.875 2.41481 4.92109 2.49686 5.00314C2.57891 5.08519 2.625 5.19647 2.625 5.3125C2.625 5.42853 2.57891 5.53981 2.49686 5.62186C2.41481 5.70391 2.30353 5.75 2.1875 5.75H0.4375C0.321468 5.75 0.210188 5.70391 0.128141 5.62186C0.0460936 5.53981 0 5.42853 0 5.3125C0 5.19647 0.0460936 5.08519 0.128141 5.00314C0.210188 4.92109 0.321468 4.875 0.4375 4.875H0.875ZM0.875 7.5V7.0625C0.875 6.94647 0.921094 6.83519 1.00314 6.75314C1.08519 6.67109 1.19647 6.625 1.3125 6.625C1.42853 6.625 1.53981 6.67109 1.62186 6.75314C1.70391 6.83519 1.75 6.94647 1.75 7.0625V7.5H2.1875C2.30353 7.5 2.41481 7.54609 2.49686 7.62814C2.57891 7.71019 2.625 7.82147 2.625 7.9375C2.625 8.05353 2.57891 8.16481 2.49686 8.24686C2.41481 8.32891 2.30353 8.375 2.1875 8.375H0.4375C0.321468 8.375 0.210188 8.32891 0.128141 8.24686C0.0460936 8.16481 0 8.05353 0 7.9375C0 7.82147 0.0460936 7.71019 0.128141 7.62814C0.210188 7.54609 0.321468 7.5 0.4375 7.5H0.875ZM0.875 10.125V9.6875C0.875 9.57147 0.921094 9.46019 1.00314 9.37814C1.08519 9.29609 1.19647 9.25 1.3125 9.25C1.42853 9.25 1.53981 9.29609 1.62186 9.37814C1.70391 9.46019 1.75 9.57147 1.75 9.6875V10.125H2.1875C2.30353 10.125 2.41481 10.1711 2.49686 10.2531C2.57891 10.3352 2.625 10.4465 2.625 10.5625C2.625 10.6785 2.57891 10.7898 2.49686 10.8719C2.41481 10.9539 2.30353 11 2.1875 11H0.4375C0.321468 11 0.210188 10.9539 0.128141 10.8719C0.0460936 10.7898 0 10.6785 0 10.5625C0 10.4465 0.0460936 10.3352 0.128141 10.2531C0.210188 10.1711 0.321468 10.125 0.4375 10.125H0.875Z"
                  fill="#ABE085"
                />
              </svg>
              <p>
                Session:{" "}
                <span className="font-medium">
                  {item?.sessions?.length || 0}
                </span>
              </p>
            </div>
          </div>
        )}
        <div className="px-2 py-2 flex flex-row items-stretch justify-between">
          <div className="flex flex-row items-center space-x-2">
            {item?.project?.image && (
              <img
                src={item?.project?.image?.url}
                alt=""
                className="w-4 h-4 rounded border border-primary-neutral-50"
              />
            )}
            <p className="text-primary-neutral-500 font-lato leading-4 text-2xs">
              {item?.project?.title}
            </p>
          </div>
          <p className="text-primary-neutral-500 flex items-center font-lato text-2xs">
            <CalendarBlank size={10} />
            <span className="ml-2">
              {moment(item?.createdAt).format("DD-MM-YYYY")}
            </span>
          </p>
        </div>

        <div
          onClick={() => {
            setOpenAddOwnerModal(true);
          }}
          className="flex flex-row items-center space-x-2 text-primary-gray-600 text-2xs font-lato font-light"
        >
          <p className="font-lato text-primary-neutral-500">Assigned To:</p>
          {item?.owner ? (
            <>
              <img
                src={item?.owner?.image?.url}
                alt=""
                className="w-4 h-4 rounded-full bg-primary-gray-100"
              />
              <p className="font-semibold">{item?.owner?.first_name}</p>
            </>
          ) : (
            <p>No owner assigned</p>
          )}
        </div>

        {type == "clockedIn" && (
          <div className="flex flex-row items-center justify-between space-x-4">
            <div className="w-full bg-secondary-indigo-30 py-3 px-5 flex flex-row items-center space-x-2 text-primary-indigo-500 font-lato text-xs font-semibold">
              <Clock size={16} />
              <p>Clocked In</p>
            </div>
            {/* <div className="flex flex-col items-start">
              <h1 className="text-primary-neutral-500 font-lato font-semibold text-2xs">
                Session:
              </h1>
              <p className="text-primary-gray-800 font-lato text-xs font-light"></p>
            </div> */}
          </div>
        )}

        {type && type !== "week" && (
          <div className="flex flex-row items-center space-x-2 px-2 justify-between">
            <div className="py-1 flex flex-row items-center space-x-2 rounded text-primary-gray-280 text-xs font-lato font-light">
              <Calendar size={16} />
              <p>{moment(item?.dueDate).format("ll")}</p>
            </div>
            <div className="py-1 flex flex-row items-center space-x-2 rounded text-primary-gray-280 text-xs font-lato font-light">
              <TShirt size={16} />
              <p>{item?.metaData?.hours} h</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default GoalHubCard;
