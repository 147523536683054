import Header from "./Header";
import GoalSetting from "./GoalSetting";

export default function DeliveryPlannerLaptop() {
  return (
    <section className="font-lato">
      <Header />
      <GoalSetting />
    </section>
  );
}
