import { createSlice } from "@reduxjs/toolkit";
import { getTAMList } from "config/APIs/users";
import { logError } from "helpers/utils/common/logError";
import {
  USER_TYPE_CUSTOMER_FWS,
  USER_TYPE_CAARYA_CORE,
  USER_TYPE_CGC_LEADER,
  USER_TYPE_YLAP,
  USER_TYPE_CGC_CORE,
} from "helpers/constants/users";
const initialState = {
  list: [],
  rankWiseList: [],
  typeWiseList: {
    USER_TYPE_CUSTOMER_FWS: [],
    USER_TYPE_CAARYA_CORE: [],
    USER_TYPE_CGC_LEADER: [],
    USER_TYPE_YLAP: [],
    USER_TYPE_CGC_CORE: [],
    all: [],
  },
};

// Storing members
export const tam = createSlice({
  name: "tam",
  initialState,
  reducers: {
    updateList: (state, action) => {
      let list = action.payload.list;
      let userType = [];
      let mem = {};
      list.map((item) => {
        if (userType.find((e) => e == item?.user_type)) {
          mem[item?.user_type]?.push(item);
        } else {
          mem[item?.user_type] = [item];
          userType.push(item?.user_type);
        }
      });
      mem["all"] = list;

      state.typeWiseList = mem;
      state.list = list;
    },
    updateRankWise: (state, action) => {
      let list = action.payload.list;

      let ranks = [];
      let mem = [];
      list.map((item) => {
        let rank = item?.designation?.rank;
        if (ranks.find((e) => e?.id == rank?.id)) {
          mem.map((item1) => {
            if (item1?.id == rank?.id) {
              item1.members.push(item);
            }
          });
        } else {
          let obj = { ...rank };
          obj["members"] = [item];
          ranks.push(rank);
          mem.push(obj);
        }
      });

      state.rankWiseList = mem;
    },
    updateTaskCount: (state, action) => {
      let list = state.list;
      let id = action.payload.id;

      let idx = list?.findIndex((i) => i?.id == id);
      list[idx]["tasksCount"] = list[idx]["tasksCount"] + 1;

      let ranks = [];
      let mem = [];
      list.map((item) => {
        let rank = item?.designation?.rank;
        if (ranks.find((e) => e?.id == rank?.id)) {
          mem.map((item1) => {
            if (item1?.id == rank?.id) {
              item1.members.push(item);
            }
          });
        } else {
          let obj = { ...rank };
          obj["members"] = [item];
          ranks.push(rank);
          mem.push(obj);
        }
      });

      state.rankWiseList = mem;
      state.list = list;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList, updateRankWise, updateTaskCount } = tam.actions;

export default tam.reducer;

export function fetchAllUsers() {
  return async (dispatch) => {
    try {
      const response = await getTAMList({ is_active: true });
      if (response.status === 200) {
        let data = response.data.data;

        dispatch(
          updateList({
            list: data?.filter(
              (i) =>
                !i?.caaryaDetails ||
                (i?.caaryaDetails && !i?.caaryaDetails?.onLeave)
            ),
          })
        );
        dispatch(updateRankWise({ list: data }));
      }
    } catch (err) {
      logError("Fetch all users: ", err);
    }
  };
}
