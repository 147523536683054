import React, { useState, useEffect } from "react";
import Details from "components/Goals/Individual";
import { useHistory, useParams } from "react-router-dom";
import * as GoalsAPI from "config/APIs/task/goal";
import { useLocation } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import Insights from "components/Goals/Individual/Insights";
import Tabs from "components/Comman/Tabs";
import { getAllComments, getAllTasks } from "config/APIs/task/task";
import GoalsChat from "components/Goals/Individual/Chat";
import GoalDocuments from "components/Goals/Individual/Documents";
import GoalsTask from "components/Goals/Individual/Tasks";

const tabs = [
  { label: "Insights", value: "insights" },
  { label: "Tasks", value: "tasks" },
  // { label: "Chats", value: "chats" },
  { label: "Documents", value: "documents" },
  { label: "Details", value: "details" },
];

// Used as a page in mobile and modal in laptop

function GoalsPage({ goalId, closeModal, onUpdate }) {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("insights");
  const [fetching, setFetching] = useState(true);
  const [comments, setComments] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [details, setDetails] = useState({});

  useEffect(() => {
    let isMounted = true;

    if (isMounted && (id || goalId)) {
      fetchGoal();
      fetchComments();
      getTasks();
    }

    return () => {
      isMounted = false;
    };
  }, [id, goalId]);

  useEffect(() => {
    localStorage.setItem("goalTab", selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (localStorage.getItem("goalTab") != "insights") {
      setSelectedTab(localStorage.getItem("goalTab"));
      localStorage.removeItem("goalTab");
    }
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && location?.state?.tab) {
      setSelectedTab(location?.state?.tab);
    }

    return () => {
      isMounted = false;
    };
  }, [location?.state?.tab]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const fetchGoal = async () => {
    try {
      const response = await GoalsAPI.getGoal(id || goalId);
      const goal = response.data.data;
      setDetails(goal);
      setFetching(false);
    } catch (err) {
      console.log("Fetch goal error", err);
    }
  };

  const fetchComments = async () => {
    try {
      const response = await getAllComments({ goalId: id || goalId });
      const comments = response.data.data.response;
      setComments(comments);
    } catch (err) {
      console.log("Fetch comments error", err);
    }
  };

  const getTasks = async () => {
    try {
      const response = await getAllTasks({ goalId: id || goalId });
      const tasks = response.data.data.response;
      setTasks(tasks);
    } catch (err) {
      console.log("Fetch comments error", err);
    }
  };

  return (
    <>
      <div className="px-0">
        <div className="w-full px-5 relative">
          <div className="absolute top-3 right-5">
            <ArrowLeftIcon
              onClick={() => {
                if (window.innerWidth < 1024) history.goBack();
                else closeModal();
              }}
              className="w-4 text-black mr-2"
            />
          </div>
          <div
            className="p-5 flex flex-col w-full bg-white"
            style={{ borderRadius: "20px" }}
          >
            <div className="flex flex-row items-center space-x-2.5">
              {fetching ? (
                <p className="font-lato text-sm h-4 rounded bg-primary-gray-200 w-7/12 animate-pulse font-normal text-primary-gray-600"></p>
              ) : (
                <p className="text-xs font-lato text-primary-gray-900 font-normal leading-4 cursor-pointer break-all">
                  {details?.title}
                </p>
              )}
            </div>
          </div>
        </div>

        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="max-h-75vh lg:max-h-90vh overflow-y-auto pt-1.5">
        {selectedTab == "insights" && (
          <Insights
            setTab={setSelectedTab}
            fetchGoal={fetchGoal}
            details={details}
            setDetails={setDetails}
            onUpdate={() => {
              fetchGoal();
              onUpdate();
            }}
          />
        )}
        {selectedTab == "chats" && (
          <GoalsChat
            taskComments={comments}
            goalId={id || goalId}
            onUpdate={() => {
              fetchComments();
            }}
          />
        )}
        {selectedTab == "tasks" && (
          <GoalsTask
            tasks={tasks}
            goal={details}
            onUpdate={() => {
              getTasks();
            }}
          />
        )}
        {selectedTab == "documents" && (
          <GoalDocuments
            goalId={id}
            GoalDocuments={comments?.filter(
              (i) => i?.attachments?.length !== 0
            )}
          />
        )}
        {selectedTab == "details" && (
          <Details
            goalId={id}
            goalDetails={details}
            onUpdate={() => {
              fetchGoal();
            }}
          />
        )}
      </div>
    </>
  );
}

export default GoalsPage;
