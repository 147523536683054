import { CheckCircle, PlusCircle, XCircle } from "@phosphor-icons/react";
import { EpicItem, Skeleton } from "./Item";
import { useEffect, useState } from "react";
import * as EpicsAPI from "config/APIs/task/epics";
import EditEpic from "components/Modals/Epic/EditEpic";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import ConfirmModal from "components/Modals/Common/ConfirmModal";

export default function Epics({
  selectedProduct,
  selectedEpic,
  setSelectedEpic,
}) {
  const dispatch = useDispatch();
  const [epics, setEpics] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [openMobileCreateModal, setOpenMobileCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState(false);
  const [newEpic, setNewEpic] = useState({ title: "" });
  const fetchGoalTasks = async () => {
    try {
      setFetching(true);
      let query = { product_id: selectedProduct?.id };

      const response = await EpicsAPI.getAllEpics(query);
      setEpics(response.data.data?.tasks || response.data.data.response || []);
      if (response.data.data?.response?.[0])
        setSelectedEpic(response.data.data.response[0]);
    } catch (err) {
      console.log("Fetch goal task error", err);
    }
    setFetching(false);
  };
  useEffect(() => {
    if (selectedProduct) {
      fetchGoalTasks();
    }
  }, [selectedProduct]);

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleDelete = async () => {
    try {
      await EpicsAPI.deleteEpics(selectedToDelete?.id);
      showSuccessNotification("Epic deleted successfully!");
      fetchGoalTasks();
      setSelectedToDelete(null);
    } catch (err) {
      console.log("Epic delete error", err);
      showErrorNotification("Something went wrong!");
    }
  };
  const handleSubmit = async () => {
    try {
      let body = {
        ...newEpic,

        product_id: selectedProduct?.id,
      };

      const response = await EpicsAPI.createEpics(body);

      setOpenMobileCreateModal(false);
      setNewEpic({ title: "" });
      fetchGoalTasks();
    } catch (err) {
      console.log("Story delete error", err);
      showErrorNotification("Something went wrong!");
    }
  };
  return (
    <>
      {/* <EpicCreateEdit
        project={selectedProduct}
        isOpen={openMobileCreateModal}
        closeModal={() => setOpenMobileCreateModal(false)}
        onCreate={() => fetchGoalTasks()}
      /> */}

      <EditEpic
        product={selectedProduct}
        isOpen={openEditModal ? true : false}
        closeModal={() => setOpenEditModal()}
        onUpdate={() => fetchGoalTasks()}
        editValues={openEditModal}
        goal={openEditModal?.goal}
        onDelete={(val) => {
          setSelectedToDelete(val);
          setOpenEditModal();
        }}
      />
      <ConfirmModal
        isOpen={selectedToDelete ? true : false}
        closeModal={() => setSelectedToDelete(null)}
        onAccept={() => handleDelete()}
        text={
          <>
            {`Are you sure you want to delete the following epic: ${selectedToDelete?.title}`}
          </>
        }
      />
      <div className="w-full h-[88vh] overflow-auto border epicStoryList shadow-md p-4 px-6">
        <div className="flex justify-between items-center">
          <h2 className="font-extrabold text-xl text-black">Epics</h2>
          {/* {!openMobileCreateModal && (
            <button
              onClick={() => {
                setOpenMobileCreateModal(true);
              }}
              className="flex items-center gap-1 p-1.5 text-xs text-black"
            >
              <PlusCircle size={12} />
              Add Epic
            </button>
          )} */}
        </div>
        <div className="flex flex-col mt-3 gap-6 h-[80vh] overflow-y-auto">
          {/* {openMobileCreateModal && (
            <div className="flex flex-col space-y-1">
              <div className="w-full flex cursor-pointer flex-row items-center space-x-1 justify-end">
                <CheckCircle
                  size={24}
                  onClick={() => {
                    handleSubmit();
                  }}
                />
                <XCircle
                  size={24}
                  onClick={() => {
                    setOpenMobileCreateModal(false);
                    setNewEpic({ title: "" });
                  }}
                />
              </div>
              <textarea
                placeholder="Add new Epic"
                value={newEpic?.title}
                onChange={(e) => {
                  setNewEpic({ ...newEpic, title: e.target.value });
                }}
                rows={3}
                className="bg-white border text-xs border-primary-gray-300 rounded"
              />
            </div>
          )} */}
          {!fetching ? (
            epics?.length > 0 ? (
              epics.map((epic, i) => (
                <EpicItem
                  key={i}
                  epic={epic}
                  bold
                  isSelected={selectedEpic?.id === epic?.id}
                  onClick={() => setSelectedEpic(epic)}
                  onEdit={() => setOpenEditModal(epic)}
                  onUpdate={() => fetchGoalTasks()}
                />
              ))
            ) : (
              <>
                {/* <EmptyState
                  image="/assets/images/empty/epic.png"
                  text="The project doesn't have any epic!"
                /> */}
              </>
            )
          ) : (
            [1, 2, 3, 4, 5].map((_, i) => <Skeleton key={i} />)
          )}
          {openMobileCreateModal ? (
            <div className="z-20 flex flex-col space-y-1">
              <textarea
                placeholder="Add new Epic"
                autoFocus
                value={newEpic?.title}
                onChange={(e) => {
                  setNewEpic({ ...newEpic, title: e.target.value });
                }}
                rows={3}
                className="bg-white border text-xs border-primary-gray-300 rounded"
              />
              <div className="w-full flex cursor-pointer flex-row items-center space-x-1 justify-end">
                <CheckCircle
                  size={24}
                  onClick={() => {
                    handleSubmit();
                  }}
                />
                <XCircle
                  size={24}
                  onClick={() => {
                    setOpenMobileCreateModal(false);
                    setNewEpic({ title: "" });
                  }}
                />
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                setOpenMobileCreateModal(true);
              }}
              className="bg-white z-20 border text-sm cursor-pointer p-3 border-primary-gray-300 rounded-lg flex flex-row items-center text-black space-x-1"
            >
              <PlusCircle size={20} />
              <p>Add New Epic</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
