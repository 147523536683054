import { ChevronDownIcon } from "@heroicons/react/outline";
import ProjectItem, { Skeleton } from "components/Comman/Cards/ProjectCard";
import EmptyState from "components/Comman/EmptyState";
import Tabs from "components/Comman/Tabs";
import {
  PROJECT_CATEGORY_INNOVATION,
  PROJECT_TYPES_BY_CATEGORY,
} from "helpers/projects";
import { useEffect, useState } from "react";
import CreateEditBattle from "./Modals/CreateEditBattle";

export default function Projects({
  fetching = false,
  projects = [],
  selectedProject,
  setSelectedProject,
  showDocumentation,
  selectedDocumentation,
  setSelectedDocumentation,
  selectedGoalsCount,
}) {
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedTab, setSelectedTab] = useState(PROJECT_CATEGORY_INNOVATION);
  const [projectTypesByCategory, setProjectTypesByCategory] = useState(
    PROJECT_TYPES_BY_CATEGORY
  );
  const projectTypes = [...new Set(projects.map((project) => project.type))];
  const projectsByType = projectTypes.reduce((acc, type) => {
    return {
      ...acc,
      [type]: projects.filter((project) => project.type === type),
    };
  }, {});
  const [expandProjects, setExpandProjects] = useState(
    projectTypes.reduce((acc, type) => {
      return {
        ...acc,
        [type]: selectedProject?.type == type,
      };
    }, {})
  );

  const setExpandedProject = (project) => {
    setExpandProjects(
      projectTypes.reduce((acc, type) => {
        return {
          ...acc,
          [type]: project?.type == type,
        };
      }, {})
    );
  };

  return (
    <>
      <CreateEditBattle isOpen={openAdd} closeModal={() => setOpenAdd(false)} />
      <div className="w-full h-[88vh] border border-gray-100 rounded-md py-4 pb-6 px-3">
        <div className="flex flex-row items-center justify-between">
          <h2 className="font-extrabold text-lg text-black border-b pb-3">
            Projects
          </h2>
          <div
            onClick={() => setOpenAdd(true)}
            className="bg-primary-orange-medium rounded-lg py-3 px-4 text-white font-inter text-sm font-semibold leading-5 tracking-[0.28px] cursor-pointer"
          >
            + Add Project
          </div>
        </div>
        <Tabs
          tabs={projectTypesByCategory?.map((a) => ({
            label: a?.name,
            value: a?.name,
          }))}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <div className="flex flex-col mt-4 gap-3 h-[80vh] overflow-y-auto">
          {!fetching
            ? projectTypesByCategory?.find((p) => p?.name === selectedTab) &&
              projectTypesByCategory
                ?.find((p) => p?.name === selectedTab)
                ?.types?.map((projectCategory, i) => {
                  return (
                    <div
                      key={i}
                      className="bg-primary-gray-50 p-3 pb-0 rounded-md"
                    >
                      <h2
                        className={
                          "font-extrabold text-lg text-[#ce8151] pb-3 flex justify-between items-center" +
                          (expandProjects?.[projectCategory?.name]
                            ? " border-b-2 border-gray-200 mb-2"
                            : "")
                        }
                        onClick={() => {
                          setExpandProjects({
                            ...expandProjects,
                            [projectCategory?.name]:
                              !expandProjects[projectCategory?.name],
                          });
                        }}
                      >
                        <span className="flex flex-row justify-between items-center w-full">
                          <p>{projectCategory?.name}</p>
                          <span className="bg-[#F3F2F2] rounded px-[4px]  text-[#696763] text-[9px] font-inter">
                            {`(${
                              projectsByType?.[projectCategory?.name]?.length ||
                              0
                            } Projects, 0 Goals)`}
                          </span>
                        </span>
                        {!expandProjects[projectCategory?.name] ? (
                          <ChevronDownIcon className="ml-2 w-5 h-5 cursor-pointer" />
                        ) : (
                          <ChevronDownIcon className="ml-2 w-5 h-5 transform rotate-180 cursor-pointer" />
                        )}
                      </h2>
                      {expandProjects?.[projectCategory?.name] &&
                        (projectsByType?.[projectCategory?.name] &&
                        projectsByType?.[projectCategory?.name]?.length ? (
                          projectsByType?.[projectCategory?.name]?.map(
                            (project, index) =>
                              project?.category === selectedTab && (
                                <div
                                  className={`mb-3 ${
                                    index === 0 ? "mt-5" : ""
                                  }`}
                                >
                                  <ProjectItem
                                    key={i}
                                    project={project}
                                    selectedGoalsCount={selectedGoalsCount}
                                    isSelected={
                                      selectedProject?.id === project?.id
                                    }
                                    onClick={() => {
                                      setSelectedProject(project);
                                      setExpandedProject(project);
                                    }}
                                  />

                                  {selectedProject?.id === project?.id &&
                                    showDocumentation && (
                                      <div className="pt-4 w-full">
                                        <div
                                          onClick={() => {
                                            setSelectedDocumentation("FRD");
                                          }}
                                          className={`w-full cursor-pointer flex flex-row items-center pl-3 py-3 menuitem ${
                                            selectedDocumentation == "FRD"
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <p className="">FRD</p>
                                        </div>{" "}
                                        <div
                                          onClick={() => {
                                            setSelectedDocumentation("BRD");
                                          }}
                                          className={`w-full cursor-pointer flex flex-row items-center pl-3 py-3 menuitem ${
                                            selectedDocumentation == "BRD"
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <p className="">BRD</p>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )
                          )
                        ) : (
                          <EmptyState
                            image="/assets/images/empty/tasks.png"
                            text="No projects in this category!"
                            imageSize="h-[12vh]"
                            height="h-[30vh]"
                          />
                        ))}
                    </div>
                  );
                })
            : [1, 2, 3, 4, 5].map((_, i) => <Skeleton key={i} />)}
        </div>
      </div>
    </>
  );
}
