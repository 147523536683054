import Documentation from "components/Document";
import React from "react";

function Document() {
  return (
    <>
      <div className="block lg:hidden"></div>
      <div className="hidden lg:block">
        <Documentation />
      </div>
    </>
  );
}

export default Document;
