import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        display: false,
      },
    },
  },
};

const labels = [
  "Backbone",
  "Vapra",
  "Forge",
  "Stargazer",
  "Forge",
  "Stargazer",
  "Backbone",
  "Forge",
  "Stargazer",
  "Forge",
  "Stargazer",
  "Backbone",
];

const chartData = {
  labels,
  datasets: [
    {
      label: "Goals",
      data: labels.map(() => Math.random() * 1000),
      backgroundColor: "rgb(255,180,0)",
    },
  ],
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function ProjectDistribution({ data }) {
  const [stats, setStats] = useState(chartData);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    setFetching(true);
    let projects = [];
    let goals = [];
    data?.map((p) => {
      projects?.push(p?.title);
      goals?.push(parseInt(p?.goalsCount));
    });

    chartData.labels = projects;
    chartData.datasets[0].data = goals;
    setStats(chartData);
    setFetching(false);
  }, [data]);
  return (
    <div className="p-4 px-5 border border-gray-200 w-full">
      <h2 className="text-[16px] font-bold">Project distribution</h2>
      <div className="flex p-8">
        {!fetching && <Bar options={options} data={stats} />}
      </div>
    </div>
  );
}
