import {
  NumberCircleEight,
  NumberCircleFive,
  NumberCircleFour,
  NumberCircleNine,
  NumberCircleOne,
  NumberCircleSeven,
  NumberCircleSix,
  NumberCircleThree,
  NumberCircleTwo,
} from "@phosphor-icons/react";
import React from "react";

function Number({ number }) {
  const getNumber = () => {
    switch (number) {
      case 1:
        return <NumberCircleOne size={28} />;
      case 2:
        return <NumberCircleTwo size={28} />;
      case 3:
        return <NumberCircleThree size={28} />;
      case 4:
        return <NumberCircleFour size={28} />;
      case 5:
        return <NumberCircleFive size={28} />;
      case 6:
        return <NumberCircleSix size={28} />;
      case 7:
        return <NumberCircleSeven size={28} />;
      case 8:
        return <NumberCircleEight size={28} />;
      case 9:
        return <NumberCircleNine size={28} />;
      default:
        return <NumberCircleOne size={28} />;
    }
  };

  return (
    <div className="w-9 h-9 rounded-full bg-primary-orange-medium flex flex-row items-center justify-center text-white">
      {getNumber()}
    </div>
  );
}

export default Number;
