import { useState, useEffect } from "react";

// Redux
import { useSelector } from "react-redux";
import MembersList from "components/Comman/Lists/MembersList";
import EmptyState from "components/Comman/EmptyState";
import Toggle from "components/Comman/Inputs/Toggle";

function MySquad({ search, onSelect, selectedMember }) {
  const squad = useSelector((state) => state.user.squadList);
  const [active, setActive] = useState(true);

  const getList = (list) => {
    let t = active ? list?.filter((i) => i?.is_active == active) : [...list];

    t = search?.isSearch
      ? t.filter((e) =>
          e?.first_name.toLowerCase().includes(search?.searchText.toLowerCase())
        )
      : t;

    return t;
  };

  useEffect(() => {
    if (squad?.length > 0 && window.innerWidth > 1024) onSelect(squad[0]);
  }, []);

  return (
    <>
      <div className="py-5 px-7.5 flex flex-row items-center justify-between">
        <h1 className="text-xs font-lato font-normal w-full text-primary-gray-900 flex flex-row items-center">
          Showing:{" "}
          <p className="font-bold ml-0.5">
            {active
              ? `${getList(squad)?.length} active squad members`
              : "Your all time squad"}
          </p>
        </h1>
        <Toggle
          label="Active"
          enabled={active}
          setEnabled={setActive}
          labelLeft
        />
      </div>
      <div className="w-full bg-white lg:bg-transparent lg:rounded-b-[40px] pt-2">
        <div className="space-y-5 max-h-70vh overflow-y-auto pb-16 lg:pb-5 overflow-x-hidden">
          {squad.length > 0 && (
            <MembersList
              selectedMember={selectedMember}
              onSelect={onSelect}
              category="All"
              members={getList(squad)}
            />
          )}
          {/* {squad.mentors.length > 0 && (
            <MembersList
              selectedMember={selectedMember}
              onSelect={onSelect}
              category="Mentors"
              members={getList(squad?.mentors)}
            />
          )}
          {squad.teamMembers.length > 0 && (
            <MembersList
              selectedMember={selectedMember}
              onSelect={onSelect}
              category="Team Members"
              members={getList(squad?.teamMembers)}
            />
          )}
          {squad.mentees.length > 0 && (
            <MembersList
              selectedMember={selectedMember}
              onSelect={onSelect}
              category="Mentees"
              members={getList(squad?.mentees)}
            />
          )} */}
        </div>
        {squad.length == 0 && (
          <EmptyState text="You don't have any team mates yet!" />
        )}
      </div>
    </>
  );
}

export default MySquad;
