import React, { useState } from "react";

import TasksList from "components/Comman/Lists/TaskList";
import TaskListLoader from "components/Comman/Loaders/TaskListLoader";
import EmptyState from "components/Comman/EmptyState";
import TaskCreateModal from "components/Tasks/Modals/TaskCreate/TaskCreate";
import { PlusIcon, XIcon } from "@heroicons/react/solid";
import ChronosButton from "components/Comman/Buttons";

function UsersTask({
  tasks,
  fetching,
  getTasks,
  details,
  projects,
  statsList,
  taskQuery,
  setTaskQuery,
}) {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [recentlyAdded, setRecentlyAdded] = useState([]);
  return (
    <>
      <TaskCreateModal
        ownerId={details?.id}
        isOpen={openCreateModal}
        userProjects={projects}
        closeModal={() => setOpenCreateModal(false)}
        onCreate={(val) => {
          setOpenCreateModal(false);
          console.log(val, recentlyAdded);

          let temp = [val, ...recentlyAdded];
          setRecentlyAdded(temp.slice(0, 3));
          if (temp?.length > 3) {
            getTasks();
          }
        }}
      />
      <div className="w-full px-5 pb-4">
        <div className="w-full flex flex-row items-center justify-end mb-2.5">
          <ChronosButton
            onClick={() => {
              setOpenCreateModal(true);
            }}
            text="Add Task"
            icon={<PlusIcon className="icon w-4 h-4 mr-1.5" />}
            reverseIcon
            primary
          />
        </div>

        {recentlyAdded?.length > 0 && (
          <>
            <h1 className="text-xs ml-3 mb-2.5 font-lato font-semibold leading-4 text-primary-gray-900">
              Recently added
            </h1>
            <TasksList
              onUpdate={() => getTasks()}
              tasks={recentlyAdded}
              squadUser={details}
            />
          </>
        )}
        {!fetching && tasks.length > 0 && (
          <>
            <div className="flex flex-row items-center space-x-3 pl-3 mb-2.5 ">
              <h1 className="text-xs font-lato font-semibold leading-4 text-primary-gray-900">
                {Object.keys(taskQuery)?.length > 0
                  ? `Showing: ${
                      statsList?.find((item) =>
                        JSON.stringify(taskQuery)?.includes(
                          JSON.stringify(item?.condition)
                        )
                      )?.label
                    }`
                  : "Other Tasks"}
              </h1>
              {Object.keys(taskQuery)?.length > 0 && (
                <XIcon
                  className="w-3 h-3 text-primary-red-medium cursor-pointer"
                  onClick={() => {
                    setTaskQuery({});
                  }}
                />
              )}
            </div>
            <TasksList
              onUpdate={() => getTasks()}
              tasks={tasks}
              squadUser={details}
            />
          </>
        )}

        {fetching && <TaskListLoader />}
        {tasks.length === 0 && (
          <div className="h-40vh w-full flex flex-row items-center justify-center">
            <EmptyState
              imageMedium
              image={"/assets/images/empty/task.svg"}
              text="The user doesn't have any tasks yet!"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default UsersTask;
