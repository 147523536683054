import React from 'react'
import ReportCardMobile from "components/ReportCard/ReportCardMobile";
import ReportCardLaptop from "components/ReportCard/ReportCardLaptop";

const index = () => {
  return (
    <>
    <div className="block lg:hidden">
      <ReportCardMobile />
    </div>

    <div className="hidden lg:block mt-2.5">
      <ReportCardLaptop />
    </div>
  </>
  )
}

export default index