import { useHistory } from "react-router-dom";
import SquadCard, {
  SquadCardLoader,
} from "components/MySquad/SquadCard/index.js";

function MembersList({ category, members, tam }) {
  const history = useHistory();
  if (members?.length > 0)
    return (
      <div className="space-y-2.5">
        <h1 className="text-xs px-7.5 font-lato font-semibold leading-4 text-primary-gray-1000">
          {category}
        </h1>
        <div className="px-7.5 py-1.5 flex flex-col w-full bg-white">
          {members.map((person, index) => {
            return (
              <SquadCard
                tam={tam}
                profile={person}
                onSelect={() => {
                  localStorage.removeItem("userTab");
                  history.push({
                    pathname: `/squadUser/${person?.id}`,
                    state: { user: person },
                  });
                }}
              />
            );
          })}
        </div>
      </div>
    );

  return <></>;
}

export default MembersList;

export const MembersListLoader = () => {
  return (
    <div className="space-y-2.5">
      <p className="font-lato text-sm h-4 mx-7.5 w-20 rounded bg-primary-gray-200 animate-pulse font-normal text-primary-gray-600"></p>

      <div className="px-10 py-1.5 flex flex-col w-full bg-white">
        {[1, 2, 3, 4].map((person, index) => {
          return <SquadCardLoader profile={person} />;
        })}
      </div>
    </div>
  );
};
