import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  typeWiseList: {
    Live: [],
    Pipeline: [],
  },
};

// Storing colleges
export const college = createSlice({
  name: "college",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list = action.payload.list;
    },
    updateTypeWise: (state, action) => {
      let list = action.payload.list;

      let types = ["Live", "Pipeline"];

      let temp = { Live: [], Pipeline: [] };

      list?.map((i) => {
        if (types?.includes(i?.type)) {
          temp[i.type].push(i);
        } else {
          types.push(i?.type);
          temp[i.type] = [i];
        }
      });
      state.typeWiseList = temp;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList, updateTypeWise } = college.actions;

export default college.reducer;
