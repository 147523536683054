import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchAllUsersList } from "redux/dropdown";

function MembersList() {
  const users = useSelector((state) => state?.dropdown?.users);

  useEffect(() => {
    fetchAllUsersList();
  }, []);

  const getUsers = (type) => {
    return type == "online"
      ? users?.filter((a) => a?.online == true)
      : users?.filter((a) => a?.online == false);
  };
  return (
    <div className="px-2 rounded-2xl bg-white shadow-md space-y-6">
      <div className="px-2 py-4 text-black font-poppins text-base font-medium leading-6">
        Members
      </div>
      <div className="space-y-4 px-2">
        <p className="text-primary-neutral-500 font-poppins text-xs font-medium leading-5">
          Online
        </p>
        <div className="grid grid-cols-2 gap-4">
          {getUsers("online")?.length > 0 ? (
            getUsers("online")?.map((item) => {
              return (
                <div className="border border-primary-neutral-100 rounded-xl py-3 px-4 flex flex-row items-center space-x-2 justify-between">
                  <div className="flex flex-row items-center space-x-2">
                    <img
                      src={item?.image?.url}
                      alt=""
                      className="w-5 h-5 rounded-full object-cover"
                    />
                    <p className="text-primary-neutral-800 font-inter text-xs font-medium leading-5 tracking-[0.6px]">
                      {item?.first_name}{" "}
                      {item?.last_name ? item?.last_name : ""}
                    </p>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle cx="4" cy="4" r="4" fill="#2BB656" />
                  </svg>
                </div>
              );
            })
          ) : (
            <div className="col-span-2 flex flex-row items-center justify-center p-5 font-lato text-primary-neutral-500 text-2xs">
              No users online!
            </div>
          )}
        </div>
      </div>

      <div className="space-y-4 px-2">
        <p className="text-primary-neutral-500 font-poppins text-xs font-medium leading-5">
          Offline
        </p>
        <div className="grid grid-cols-2 gap-4">
          {getUsers("offline")?.length > 0 ? (
            getUsers("offline")?.map((item) => {
              return (
                <div className="border border-primary-neutral-100 rounded-xl py-3 px-4 flex flex-row items-center space-x-2 justify-between">
                  <div className="flex flex-row items-center space-x-2">
                    <img
                      src={item?.image?.url}
                      alt=""
                      className="w-5 h-5 rounded-full object-cover"
                    />
                    <p className="text-primary-neutral-800 font-inter text-xs font-medium leading-5 tracking-[0.6px]">
                      {item?.first_name}{" "}
                      {item?.last_name ? item?.last_name : ""}
                    </p>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle cx="4" cy="4" r="4" fill="#9C9A96" />
                  </svg>
                </div>
              );
            })
          ) : (
            <div className="col-span-2 flex flex-row items-center justify-center p-5 font-lato text-primary-neutral-500 text-2xs">
              No users offline!
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MembersList;
