import { useState, useEffect } from "react";
import { Plus } from "@phosphor-icons/react";
import Tabs from "components/Comman/Tabs";
import * as TaskAPI from "config/APIs/task/task";
import ProjectWiseTask from "./ProjectWiseTask";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import moment from "moment";
import GoalCreate from "components/Modals/Goal/GoalCreate";
import AssignGoal from "./AssignGoal";
import { getUsersGoals } from "config/APIs/task/goal";
import { BiChevronDown } from "react-icons/bi";
import { getMemberReport } from "config/APIs/project";
import { TaskStatus } from "helpers/task";
import ReportsCard from "components/MemberControl/ReportsCard";
import PerformanceCard from "components/MemberControl/PerformanceCard";
ChartJS.register(ArcElement, Tooltip, Legend);

let chartData = {
  labels: ["Red", "Blue", "Yellow", "Green"],
  datasets: [
    {
      label: "# of Goals",
      data: [0, 0, 0, 0],
      backgroundColor: ["#C63434", "#BA7507", "#CE5511", "#008B46"],
      borderWidth: 1,
    },
  ],
};
const tabs = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "This Week",
    value: "thisWeek",
  },
  {
    label: "Backlog",
    value: "backlog",
  },
];

function UserGoals({ selectedUser, closeModal }) {
  const [selectedTab, setSelectedTab] = useState("today");
  const [goalsModal, setGoalsModal] = useState(false);
  const [todayTasks, setTodayTask] = useState([]);
  const [weekTasks, setWeekTask] = useState([]);
  const [backlogTasks, setBacklogTask] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [statsView, setStatsView] = useState(false);
  const [stats, setStats] = useState({ goalsAllocation: chartData });

  const getTasks = async () => {
    setFetching(true);
    let todayTask = [];
    let weekTask = [];
    let backlogTask = [];

    TaskAPI.getUsersTasks(selectedUser?.id, { time: "today" })
      .then((res) => {
        let resp = res.data.data;

        let p = [];
        resp?.map((task) => {
          if (p?.includes(task?.project?.id)) {
            let idx = todayTask?.findIndex((a) => a?.id == task?.project?.id);
            todayTask[idx]?.tasks?.push(task);
          } else {
            p.push(task?.project?.id);
            let obj = { ...task?.project, tasks: [task], goals: [] };
            todayTask.push(obj);
          }
        });
        let g = [];
        let ug = [];
        todayTask?.map((project) => {
          project?.tasks?.map((task) => {
            if (task?.goal) {
              if (g?.includes(task?.goal?.id)) {
                let idx = project?.goals?.findIndex(
                  (a) => a?.id == task?.goal?.id
                );
                project?.goals[idx]?.tasks?.push(task);
              } else {
                g?.push(task?.goal?.id);
                let obj = { ...task?.goal, tasks: [task] };
                project?.goals?.push(obj);
              }
            } else {
              ug?.push(task);
            }
          });
          let obj = { id: 0, title: "Unassigned Tasks", tasks: ug };
          if (ug?.length > 0) project?.goals?.push(obj);

          ug = [];
          g = [];
        });
        // setFetching(false);
      })
      .catch((error) => {
        console.log(error);
      });

    TaskAPI.getUsersTasks(selectedUser?.id, { time: "thisWeek" })
      .then((res) => {
        let resp = res.data.data;

        let p = [];
        resp?.map((task) => {
          if (p?.includes(task?.project?.id)) {
            let idx = weekTask?.findIndex((a) => a?.id == task?.project?.id);
            weekTask[idx]?.tasks?.push(task);
          } else {
            p.push(task?.project?.id);
            let obj = { ...task?.project, tasks: [task], goals: [] };
            weekTask.push(obj);
          }
        });
        let g = [];
        let ug = [];
        weekTask?.map((project) => {
          project?.tasks?.map((task) => {
            if (task?.goal) {
              if (g?.includes(task?.goal?.id)) {
                let idx = project?.goals?.findIndex(
                  (a) => a?.id == task?.goal?.id
                );
                project?.goals[idx]?.tasks?.push(task);
              } else {
                g?.push(task?.goal?.id);
                let obj = { ...task?.goal, tasks: [task] };
                project?.goals?.push(obj);
              }
            } else {
              ug?.push(task);
            }
          });
          let obj = { id: 0, title: "Unassigned Tasks", tasks: ug };
          if (ug?.length > 0) project?.goals?.push(obj);

          ug = [];
          g = [];
        });

        // setFetching(false);
      })
      .catch((error) => {
        console.log(error);
      });

    TaskAPI.getUsersTasks(selectedUser?.id, { pastDueDate: true })
      .then((res) => {
        let resp = res.data.data;

        let p = [];
        resp?.map((task) => {
          if (p?.includes(task?.project?.id)) {
            let idx = backlogTask?.findIndex((a) => a?.id == task?.project?.id);
            backlogTask[idx]?.tasks?.push(task);
          } else {
            p.push(task?.project?.id);
            let obj = { ...task?.project, tasks: [task], goals: [] };
            backlogTask.push(obj);
          }
        });
        let g = [];
        let ug = [];
        backlogTask?.map((project) => {
          project?.tasks?.map((task) => {
            if (task?.goal) {
              if (g?.includes(task?.goal?.id)) {
                let idx = project?.goals?.findIndex(
                  (a) => a?.id == task?.goal?.id
                );
                project?.goals[idx]?.tasks?.push(task);
              } else {
                g?.push(task?.goal?.id);
                let obj = { ...task?.goal, tasks: [task] };
                project?.goals?.push(obj);
              }
            } else {
              ug?.push(task);
            }
          });
          let obj = { id: 0, title: "Unassigned Tasks", tasks: ug };
          if (ug?.length > 0) project?.goals?.push(obj);

          ug = [];
          g = [];
        });

        // setFetching(false);
      })
      .catch((error) => {
        console.log(error);
      });

    let resp = await getUsersGoals(selectedUser?.id, { time: "today" });
    let goals = resp?.data?.data;
    let ua = [];
    goals?.map((goal) => {
      if (goal?.project) {
        if (todayTask?.find((t) => t?.id == goal?.project?.id)) {
          let idx = todayTask?.find((t) => t?.id == goal?.project?.id);
          todayTask[idx]?.goals?.push({ ...goal, tasks: [] });
        } else {
          todayTask.push({ ...goal?.project, goals: [goal] });
        }
      } else {
        ua.push(goal);
      }
    });
    if (ua?.length > 0) todayTask.push({ title: "Unassigned", goals: ua });

    resp = await getUsersGoals(selectedUser?.id, { time: "thisWeek" });
    goals = resp?.data?.data;
    ua = [];
    goals?.map((goal) => {
      if (goal?.project) {
        if (weekTask?.find((t) => t?.id == goal?.project?.id)) {
          let idx = weekTask?.find((t) => t?.id == goal?.project?.id);
          weekTask[idx]?.goals?.push({ ...goal, tasks: [] });
        } else {
          weekTask.push({ ...goal?.project, goals: [goal] });
        }
      } else {
        ua.push(goal);
      }
    });
    if (ua?.length > 0) weekTask.push({ title: "Unassigned", goals: ua });

    resp = await getUsersGoals(selectedUser?.id, { pastDueDate: true });
    goals = resp?.data?.data;
    ua = [];
    goals?.map((goal) => {
      if (goal?.project) {
        if (backlogTask?.find((t) => t?.id == goal?.project?.id)) {
          let idx = backlogTask?.find((t) => t?.id == goal?.project?.id);
          backlogTask[idx]?.goals?.push({ ...goal, tasks: [] });
        } else {
          backlogTask.push({ ...goal?.project, goals: [goal] });
        }
      } else {
        ua.push(goal);
      }
    });
    if (ua?.length > 0) backlogTask.push({ title: "Unassigned", goals: ua });

    setFetching(false);
    setTodayTask(todayTask);
    setWeekTask(weekTask);
    setBacklogTask(backlogTask);
  };

  const fetchStat = async () => {
    setFetching(true);
    try {
      const response = await getMemberReport(selectedUser?.id);
      let data = response.data.data.goalStats;
      let chartStat = data?.goalCount;
      let label = TaskStatus?.map((s) => s?.label);

      let values = [];
      label?.map((l) => {
        let idx = chartStat?.findIndex(
          (c) => c?.status == TaskStatus?.find((t) => t?.label == l)?.value
        );
        if (idx) values.push(chartStat[idx]?.count);
        else values.push(0);
      });
      chartData.labels = label;
      chartData.datasets[0].data = values;
      console.log(chartData);
      data["goalsAllocation"] = chartData;

      setStats(data);
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };
  useEffect(() => {
    let isMounted = true;

    if (isMounted && selectedUser) {
      getTasks();
    }

    return () => {
      isMounted = false;
    };
  }, [selectedUser]);

  return (
    <>
      <GoalCreate
        isOpen={goalsModal}
        closeModal={() => setGoalsModal(false)}
        onCreate={getTasks}
        ownerId={selectedUser?.id}
        owner={selectedUser}
      />{" "}
      {/* <AssignGoal
        isOpen={goalsModal}
        closeModal={() => setGoalsModal(false)}
        user={selectedUser}
        onUpdate={() => {
          getTasks();
        }}
      /> */}
      <div className="relative h-[88vh] rounded-2xl bg-white shadow-md p-4 overflow-hidden">
        {closeModal && (
          <div
            onClick={() => {
              closeModal();
            }}
            className="flex flex-row items-center space-x-1"
          >
            <BiChevronDown className="w-6 transform rotate-90 scale-150" />
            <p className="text-sm font-semibold font-karla text-primary-gray-600 underline cursor-pointer">
              Back
            </p>
          </div>
        )}
        <div className="flex flex-row items-center justify-between p-2">
          <div className="flex">
            <div>
              <img
                src={
                  selectedUser?.image?.url || "/assets/images/defaultUser.png"
                }
                className="w-10 h-10 rounded-3xl mr-4"
              />
            </div>
            <div className="text-black font-karla text-xl font-bold">
              {selectedUser?.first_name}
              <div className="text-gray-500 text-sm font-medium font-lato">
                {selectedUser?.designation?.designation_name}
              </div>
            </div>
          </div>
          <button
            onClick={() => setStatsView(!statsView)}
            className="w-max mb-auto flex items-center gap-1 border p-1.5 border-black rounded text-xs text-black"
          >
            {statsView ? "View Goals" : "View Reports"}
          </button>
        </div>
        {statsView ? (
          <>
            <div className="grid grid-cols-[2fr,1fr] gap-4 pt-2.5 mt-[100px]">
              <div className="flex flex-col gap-6">
                <ReportsCard type="completed" stats={stats} />
                <ReportsCard type="overdue" stats={stats} />
                <ReportsCard type="incomplete" stats={stats} />
                <ReportsCard type="total" stats={stats} />
              </div>
              <div className="">
                <PerformanceCard data={stats?.goalsAllocation} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="text-black font-karla text-lg font-bold mt-5  mt-[100px]">
              Goals and Tasks
            </div>
            <div className="flex flex-row items-center justify-between">
              <Tabs
                tabs={tabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
              <button
                className="text-orange-500 underline p-2 flex bg-red-50 border border-red-50 rounded font-bold text-sm"
                onClick={() => setGoalsModal(true)}
              >
                <Plus size={12} className="mt-1 mr-2" />
                Assign New Goal
              </button>
            </div>
            <div className="h-full max-h-[60vh] overflow-y-auto">
              <ProjectWiseTask
                fetching={fetching}
                projects={
                  selectedTab == "today"
                    ? todayTasks
                    : selectedTab == "thisWeek"
                    ? weekTasks
                    : backlogTasks
                }
                user={selectedUser}
                onUpdate={(val, pId, gId) => {
                  if (val && pId && gId) {
                    let temp =
                      moment(val?.date).unix > moment().unix()
                        ? [...weekTasks]
                        : moment(val?.date).unix < moment().unix()
                        ? [...backlogTasks]
                        : [...todayTasks];

                    let idx = temp?.findIndex((p) => p?.id == pId);
                    let gIdx = temp[idx]?.goals?.findIndex((g) => g?.id == gId);
                    temp[idx]?.goals[gIdx]?.tasks?.push(val);
                    if (moment(val?.date).unix > moment().unix()) {
                      setWeekTask(temp);
                    } else if (moment(val?.date).unix < moment().unix()) {
                      setBacklogTask(temp);
                    } else {
                      setTodayTask(temp);
                    }
                  } else {
                    getTasks();
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default UserGoals;
