import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { RadioGroup } from "@headlessui/react";
import { Fade } from "react-awesome-reveal";

// Icons
import { XIcon, ArrowRightIcon } from "@heroicons/react/solid";
import Arrow from "assets/icons/Arrow.svg";
import * as Icons from "@heroicons/react/outline";

// APIs
import * as GoalAPI from "config/APIs/task/goal";

// Redux
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";

// Utils
import { TaskStatus, TShirtSizes } from "helpers/task";
import { classNames } from "helpers/utils/classNames";

const HeroIcon = ({ icon }) => {
  const Icon = Icons[icon];
  return (
    <div className="flex-col">
      <Icon className="h-7 w-7 cursor-pointer text-primary-yellow-darkest" />
    </div>
  );
};

function UpdateGoal({
  isOpen,
  closeModal,
  goal,
  onUpdate,
  type,
  details,
  setDetails,
}) {
  const dispatch = useDispatch();
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isOpen) {
      updateGoal();
    }

    return () => {
      isMounted = false;
    };
  }, [details]);

  const updateGoal = async () => {
    setCreating(true);
    let update = details;

    try {
      let body = {
        ...update,
      };
      const response = await GoalAPI.updateGoal(goal?.id, body);
      onUpdate();
      closeModal();
      dispatch(showToast({ message: "Goal updated successfully!" }));
    } catch (err) {
      switch (err.response?.status) {
        case 401:
          dispatch(showToast({ message: "Unauthorized!", type: "error" }));
          break;
        default:
          dispatch(
            showToast({
              message: err?.response?.message || "Something went wrong!",
              type: "error",
            })
          );
      }
    }
    setCreating(false);
    closeModal();
  };

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      open={isOpen}
      onClose={() => closeModal()}
      transitionDuration={500}
      PaperProps={{
        style: { maxHeight: "50vh", borderRadius: "20px 20px 0px 0px" },
      }}
    >
      <div className="bg-gray-50 rounded-t-[20px]">
        <div className="relative">
          <div className="px-3 pt-3 pb-3 relative">
            <div className="relative">
              <div className="flex flex-row w-full items-center justify-between p-3 rounded-t-lg">
                <div className="text-left text-base poppins font-medium mb-3">
                  Select {type}
                </div>
                <button
                  aria-label="Close"
                  type="button"
                  onClick={() => closeModal()}
                >
                  <XIcon className="h-5 w-5" />
                </button>
              </div>

              <div
                className={`w-full grid grid-cols-1 max-h-75vh overflow-y-auto`}
              >
                {type == "Status" && (
                  <RadioGroup
                    value={details?.status}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        status: e,
                      })
                    }
                    className=""
                  >
                    <RadioGroup.Label className=""></RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                      {TaskStatus.map((option) => (
                        <RadioGroup.Option
                          key={option.value}
                          value={option.value}
                          className={({ active, checked }) =>
                            classNames(
                              option.value == details?.status
                                ? "bg-primary-yellow-lightest border-transparent text-primary-yellow-darkest"
                                : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                              "border rounded-md p-2 cursor-pointer flex items-center justify-center text-sm font-medium uppercase sm:flex-1 hover-on-card"
                            )
                          }
                        >
                          <RadioGroup.Label as="p" className="card-hover-text">
                            {option.label}
                          </RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                )}
                {type == "Priority" && (
                  <RadioGroup
                    value={details?.priority}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        priority: e,
                      })
                    }
                    className=""
                  >
                    <RadioGroup.Label className=""></RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                      {[1, 2, 3, 4, 5, 6].map((option) => (
                        <RadioGroup.Option
                          key={option.name}
                          value={option}
                          className={({ active, checked }) =>
                            classNames(
                              option == details?.priority
                                ? "bg-primary-yellow-lightest border-transparent text-primary-yellow-darkest"
                                : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                              "border rounded-md p-2 cursor-pointer flex items-center justify-center text-sm font-medium uppercase sm:flex-1 hover-on-card"
                            )
                          }
                        >
                          <RadioGroup.Label as="p" className="card-hover-text">
                            {option}
                          </RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default UpdateGoal;
