import { Check, Target, Trash } from "@phosphor-icons/react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { classNames } from "helpers/utils/classNames";
import moment from "moment";
import * as GoalAPI from "config/APIs/task/goal";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {} from "react-icons";
import { MdOutlineLocalFireDepartment } from "react-icons/md";

const GoalItem = ({
  bold = false,
  goal,
  onEdit,
  isUpcoming = false,
  project,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [date, setDate] = useState();
  const [owner, setOwner] = useState();
  const [calendarOpen, setCalendarOpen] = useState(false);
  console.log({ goal });
  useEffect(() => {
    if (goal) {
      setDate(goal?.dueDate);
      setOwner(goal?.owner);
    }
  }, [goal]);

  const handleUpdate = async ({ owner, dueDate }) => {
    let body = {};

    if (dueDate) {
      body = { dueDate: dueDate };
    }
    if (owner) {
      body = { projectId: project?.id, ownerId: owner?.id };
    }

    try {
      const response = await GoalAPI.updateGoal(goal.id, body);
      const { task } = response.data.data;
    } catch (err) {
      switch (err.response?.status) {
        case 422:
          let error = {},
            { data } = err.response.data;
          for (let key of Object.keys(data)) {
            if (key.split(".")[0] === "update")
              error[key.split(".")[1]] = data[key];
          }

          break;
        case 401:
          console.log(err?.response);
          dispatch(showToast({ message: err.response?.data?.message }));
          break;
        default:
          dispatch(showToast({ message: err.response?.data?.message }));
      }
    }
  };

  const getDateValue = (date) => {
    let d = moment(date).format("YYYY-MM-DD");
    let today = moment().format("YYYY-MM-DD");
    let weekEnd = moment().add(7, "days").format("YYYY-MM-DD");
    let monthEnd = moment().add(1, "month").format("YYYY-MM-DD");

    if (d == today) {
      return "Today";
    }
    if (
      moment(d).unix() > moment(today).unix() &&
      moment(d).unix() < moment(weekEnd).unix()
    ) {
      return "Week";
    }
    if (
      moment(d).unix() > moment(today).unix() &&
      moment(d).unix() < moment(monthEnd).unix()
    ) {
      return "Month";
    }
    return "Custom";
  };

  const getDate = (value) => {
    let today = moment().format("YYYY-MM-DD");
    let weekEnd = moment().add(7, "days").format("YYYY-MM-DD");
    let monthEnd = moment().add(1, "month").format("YYYY-MM-DD");

    switch (value) {
      case "Today":
        return today;
      case "Week":
        return weekEnd;
      case "Month":
        return monthEnd;
      case "Custom":
        setCalendarOpen(true);
        break;
      default:
    }
  };

  return (
    <div className="rounded-[12px] bg-white shadow-md p-[16px]">
      <div className="flex flex-row justify-between">
        <span className="flex flex-row space-x-[10px] items-center justify-center rounded-xl border border-[#E7E6E5] p-[8px] text-[#696763] text-[12px] font-semibold hover:border-primary-red-300 hover:shadow-md">
          <MdOutlineLocalFireDepartment height={16} width={16} /> Prioritize
        </span>
        <span className="bg-[#FFE99A] text-[#532A01] text-[8px] font-semibold font-inter py-[4px] px-[8px] flex items-center rounded-[4px] h-fit">
          {goal?.status}
        </span>
      </div>

      <div className="flex flex-col mt-[16px] mb-[16px]">
        <p className="font-semibold text-[14px] ">{goal?.title}</p>
        <p className="text-[#363430] text-[12px] font-normal">
          {goal?.description}
        </p>
      </div>

      <div className="flex flex-row space-x-3 items-center">
        <span className="text-[#9C9A96] font-semibold text-[12px] font-inter">
          Assigned To:{" "}
        </span>
        <span
          className={`font-normal text-[12px] font-inter ${
            owner?.first_name ? "text-[#696763]" : "text-primary-orange-dark italic"
          }`}
        >
          {owner?.first_name || "Not Assigned"}
        </span>
      </div>
    </div>
  );
};

export const Skeleton = () => {
  return (
    <div
      className={`animate-pulse flex items-start gap-4 w-full cursor-pointer bg-primary-gray-150 text-black rounded-lg p-3 hover:shadow`}
    >
      <Target size={45} className={"text-primary-ash-180"} />
      <div className="flex flex-col">
        <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-2"></div>
        <div className="h-2.5 bg-gray-200 rounded-full w-36 mb-2"></div>
        <div className="h-2.5 bg-gray-200 rounded-full w-24 mb-2"></div>
      </div>
    </div>
  );
};

export default GoalItem;
