import { useEffect, useState } from "react";
import Header from "./Header";
import GoalSetting from "./GoalSetting";
import SquadReport from "./SquadReport";
import { getSquadReport } from "config/APIs/project";

const tabs = [
  {
    label: "Goal Setting",
    value: "Goal Setting",
  },
  {
    label: "Weekly Report",
    value: "Weekly Report",
  },
];

export default function DeliveryPlannerLaptop() {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const [stats, setStats] = useState();

  const fetchStat = async () => {
    try {
      const response = await getSquadReport("weekly");
      let data = response.data.data;
      setStats(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchStat();
  }, []);

  return (
    <section className="font-lato">
      <Header
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {selectedTab === tabs[0].value && <GoalSetting />}
      {selectedTab === tabs[1].value && <SquadReport stats={stats} />}
    </section>
  );
}
