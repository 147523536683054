import Header from "./../../Common/Header";
import { ProjectsTable } from "./Tables";
import ProjectOverview from "./ProjectOverview";
import TeamProgress from "./TeamProgress";
import ClickIn from "./ClockIn";
import TeamPerformance from "./TeamPerformance";

export default function SquadReport({ stats }) {
  const getTotalGoals = () => {
    let sum = 0;
    stats?.projectGoals?.map((p) => {
      sum += parseInt(p?.weekGoalsCount);
    });
    return sum;
  };

  const getProjects = () => {
    let pGood = [];
    let pBad = [];
    stats?.projectGoals?.map((p) => {
      if (parseInt(p?.weekGoalsCount) / parseInt(p?.allGoalsCount) / 100 > 75) {
        pGood.push(p);
      } else {
        pBad.push(p);
      }
    });
    return { pGood, pBad };
  };

  return (
    <div className="max-h-[80vh] overflow-y-scroll relative space-y-14 bg-white rounded-2xl shadow-md py-6 px-4">
      <Header stats={stats?.stats} weekly />
      <div className="grid grid-cols-1 gap-8 mt-8">
        <div className="flex gap-8">
          <ProjectsTable
            title={"Neglected Battles"}
            description={"Projects for which no goals have been assigned"}
          />
          <ProjectsTable
            title={"Battle Priority"}
            description={"Based on no. of goals assigned"}
          />
          <ProjectsTable
            title={"Winning Battles"}
            description={"Based on no. of goals completed"}
          />
          {/* <TeamProgress
            data={stats?.membersGoals}
            totalGoals={getTotalGoals()}
          /> */}
        </div>
        <div className="space-y-[10px]">
          <p className="text-primary-dawn-500 text-[18px] font-medium font-poppins">
            Battle Reports
          </p>
          <ProjectOverview list={stats?.stats?.clockins} />
        </div>

        {/* CLOCK IN OVERVIEW AND HOURES CLOCKED IN */}
        <ClickIn />

        {/* GOALS AND TEAM PERFORMANCE */}
        <TeamPerformance />
      </div>
    </div>
  );
}
