import React from 'react'
import Release from "./Release"
import Goals from "./Goals"
import ResourceAllocationMatrix from './ResourceAllocationMatrix'
const SprintPageLaptop = () => {
  return (
<>
     <div className="relative ">
      <div className="grid grid-cols-3 gap-6 mt-5">

      <Release />
      <Goals />
      <ResourceAllocationMatrix />
      </div>
</div>
</>
  )
}

export default SprintPageLaptop