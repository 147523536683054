import { useState } from "react";
import { MdExpand, MdExpandMore } from "react-icons/md";

const DropdownButton = ({ options, onSelect, label }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Use the Tailwind CSS classes based on the design specs
  const buttonStyle = "bg-white text-black px-4 py-2 rounded-lg shadow-custom1";
  const dropdownMenuStyle =
    "absolute mt-1 py-1 bg-white rounded-lg  z-10 shadow-custome2";

  return (
    <div className="relative inline-block text-left">
      <button
        className={buttonStyle}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="flex flex-row items-center space-x-2 justify-center">
          <div>
            <p className="text-[#363430] text-sm font-semibold font-inter">
              {label}
            </p>
          </div>
          <div>
            <MdExpandMore className="text-[#363430]" />
          </div>
        </div>
      </button>
      {isOpen && (
        <div className={dropdownMenuStyle}>
          {options.map((option, index) => (
            <div
              key={index}
              className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                onSelect(option);
                setIsOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
