import { PlusCircle } from "@phosphor-icons/react";
import Member from "./Member";
import { useEffect, useState } from "react";
import AddMembers from "components/Modals/AddMembers";
import * as ProjectAPI from "config/APIs/project";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import ConfirmModal from "components/Modals/Common/ConfirmModal";
import EmptyState from "components/Comman/EmptyState";
import { fetchAllProjects } from "redux/projects";

export default function Members({
  project,
  selectedMember,
  setSelectedMember,
  goals,
}) {
  const dispatch = useDispatch();
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [removeMember, setRemoveMember] = useState();
  const [allMembers, setAllMembers] = useState([]);
  useEffect(() => {
    if (project) {
      let all = project?.owner ? [{ ...project?.owner, type: "Owner" }] : [];
      console.log({ all });
      all = all
        .concat(project?.members?.filter((i) => i?.is_active))
        ?.map((e) => {
          const goal = goals?.filter((g) => g?.ownerId === e?.id);
          return { ...e, goalsCount: goal?.length };
        });
      setAllMembers(all);
    }
  }, [project, goals]);

  const handleUpdate = async (members, remove) => {
    try {
      let body = {
        members,
      };
      if (remove) body["remove"] = true;
      const response = await ProjectAPI.update(project.id, body);
      if (response?.status === 200) dispatch(fetchAllProjects());
    } catch (err) {
      console.log("Error", err);
      // let all = project?.owner ? [{ ...project?.owner, type: "Owner" }] : [];
      // all = all.concat(project?.members);
      // setSelectedMember?.(all[0]);
      dispatch(
        showToast({
          message: err.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  return (
    <>
      <AddMembers
        type="members"
        allUsers
        isOpen={showAddMembersModal}
        closeModal={() => setShowAddMembersModal(false)}
        selected={allMembers}
        onUpdate={(id, profiles) => {
          let all = [...allMembers];
          let oldIds = all?.map((p) => p?.id);
          let newMem = profiles?.filter((p) => !oldIds?.includes(p?.id));
          all = all.concat(newMem);
          setAllMembers(all);

          let ids = all?.map((p) => p?.id);
          console.log(ids);
          handleUpdate(ids);
          setShowAddMembersModal(false);
        }}
        project={project}
      />
      <ConfirmModal
        isOpen={removeMember ? true : false}
        closeModal={() => {
          setRemoveMember();
        }}
        onAccept={() => {
          handleUpdate([removeMember?.id], true);
          let all = [...allMembers];
          all = all?.filter((m) => m?.id !== removeMember?.id);

          setAllMembers(all);
          setSelectedMember?.(all[0]);
          setRemoveMember();
        }}
        text={
          <>
            Are you sure you want to remove <b>{removeMember?.first_name}</b>{" "}
            from <b>{project?.title}</b>?
          </>
        }
      />

      <div className="w-full h-full border border-gray-100 rounded-md p-4 px-3 shadow-md">
        <div className="flex justify-between items-center border-b pb-3 mb-3">
          <h2 className="font-extrabold text-lg text-black">Members</h2>
          <button
            onClick={() => setShowAddMembersModal(true)}
            className="flex items-center gap-1 border p-1.5 border-black rounded text-xs text-black"
          >
            <PlusCircle size={12} />
            Add a Member
          </button>
        </div>
        <div className="flex flex-col mt-3 gap-6">
          {!project ? (
            <EmptyState
              image="/assets/images/empty/tasks.png"
              text="Select project to view members!"
            />
          ) : !allMembers?.length ? (
            <EmptyState
              image="/assets/images/empty/tasks.png"
              text="This project has no members!"
            />
          ) : (
            allMembers?.map((member, i) => (
              <Member
                key={i}
                member={member}
                isSelected={selectedMember?.id === member?.id}
                onClick={() => {
                  if (selectedMember?.id !== member?.id) {
                    setSelectedMember(member);
                  } else {
                    setSelectedMember(null);
                  }
                }}
                onRemove={() => {
                  setRemoveMember(member);
                }}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
}
