import Drawer from "@mui/material/Drawer";
import React, { useEffect, useState } from "react";
import {
  XIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  CheckCircleIcon,
} from "@heroicons/react/solid";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "redux/toaster";
import ChronosButton from "components/Comman/Buttons";
import {
  getProjectName,
  PROJECT_CATEGORY,
  PROJECT_TYPES_BY_CATEGORY,
  ProjectTypes,
} from "helpers/projects";
import EmptyState from "components/Comman/EmptyState";
import { addOrRemove } from "helpers/array";
import { updateUsersProjects } from "config/APIs/users";

const defaultValue = {
  title: "",
  date: null,
  status: null,
};

function AddProjectModal({
  closeModal,
  isOpen,
  onAdd,
  userId,
  userProjects,
  forGoals,
}) {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [creating, setCreating] = useState(false);
  const [projectType, setProjectType] = useState("all");
  const projectsList = useSelector((state) => state.projects.list);

  useEffect(() => {
    if (isOpen) {
      if (userProjects) {
        setProjects(userProjects);
      }
    }
  }, [isOpen]);

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleAdd = async () => {
    if (forGoals) {
      onAdd(projects);
      closeModal();
    } else {
      setCreating(true);
      let body = {
        projectIds: projects
          ?.filter((p) => !JSON.stringify(userProjects)?.includes(p?.id))
          ?.map((p) => p?.id),
      };
      try {
        const response = await updateUsersProjects(userId, body);
        onAdd(projects);
        closeModal();

        showSuccessNotification("Projects assigned successfully!");
      } catch (err) {
        console.log("Error", err);
        switch (err.response?.status) {
          case 422:
            let error = {},
              { data } = err.response.data;
            for (let key of Object.keys(data)) {
              if (key.split(".")[0] === "task")
                error[key.split(".")[1]] = data[key];
            }

          case 401:
            console.log(err?.response);
          default:
            showErrorNotification(err.response?.data?.message);
        }
      }
      setCreating(false);
    }
  };

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      PaperProps={{
        style: {
          borderRadius: window.innerWidth < 1024 ? "20px 20px 0px 0px" : "0px",
          maxHeight: "100vh",
          width: window.innerWidth < 1024 ? "100%" : "560px",
        },
      }}
      open={isOpen}
      onClose={() => {
        closeModal();
      }}
      transitionDuration={250}
    >
      <div className="modals-component md:max-w-xl lg:h-screen lg:pt-20 mx-auto w-full transform transition-all ease-in-out duration-150">
        <div className="flex flex-row items-end justify-between px-5 pt-5 rounded-t-20px">
          <h5
            className="font-lato font-bold text-sm text-primary-gray-1000 flex flex-col items-start"
            id="exampleModalLiveLabel"
          >
            Add to projects
          </h5>

          <button
            aria-label="Close"
            type="button"
            onClick={() => {
              closeModal();
            }}
          >
            <XIcon className="h-6 w-6 text-primary-gray-1000" />
          </button>
        </div>

        <div className="mt-3.5 flex w-full flex-col items-start space-y-5 h-auto transition-all ease-in-out duration-150">
          <div className="w-full px-5 py-2.5 font-lato">
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <div className="w-full flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center justify-between">
                      <p className="font-lato font-normal text-xs text-primary-gray-1000">
                        Chose a <b>Project</b>
                      </p>
                      <Disclosure.Button>
                        <ChevronDownIcon
                          className={`h-4 w-4 transform text-primary-gray-1000 ${
                            open ? "-rotate-180" : "rotate-0"
                          }`}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                    </div>
                  </div>
                  <Disclosure.Panel className="w-full mt-5 space-y-5 ">
                    <div className="overflow-x-auto">
                      <div className="flex flex-row items-center justify-between space-x-5 min-w-max">
                        <div
                          onClick={() => {
                            setProjectType("all");
                          }}
                          key={"all"}
                          className=""
                        >
                          <div
                            className={`flex flex-row cursor-pointer text-sm py-1 text-primary-gray-1000 font-lato items-end justify-center text-center ${
                              projectType == "all"
                                ? "px-2 rounded-full bg-primary-orange-lighter font-medium"
                                : "font-normal"
                            }`}
                          >
                            All
                          </div>
                        </div>
                        {PROJECT_CATEGORY?.map((item) => {
                          return (
                            <div
                              onClick={() => {
                                setProjectType(item?.name);
                              }}
                              key={item?.name}
                              className=""
                            >
                              <div
                                className={`flex flex-row cursor-pointer text-sm py-1 text-primary-gray-1000 font-lato items-end justify-center text-center ${
                                  projectType == item?.name
                                    ? "px-2 rounded-full bg-primary-orange-lighter font-medium"
                                    : "font-normal"
                                }`}
                              >
                                {item?.name}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className=" min-h-40vh max-h-40vh overflow-y-auto mt-1">
                      <div className="grid grid-cols-2 gap-5">
                        {projectType == "all" ? (
                          projectsList?.map((p) => {
                            return (
                              <div
                                onClick={() => {
                                  if (forGoals) {
                                    setProjects([p]);
                                  } else {
                                    let newProjects = addOrRemove(
                                      [...projects],
                                      p
                                    );
                                    setProjects(newProjects);
                                  }
                                }}
                                className={`flex flex-col items-center rounded-10px relative p-2.5 w-full  ${
                                  JSON.stringify(projects)?.includes(p?.id)
                                    ? "bg-primary-orange-lightest font-bold"
                                    : "bg-white font-normal"
                                }`}
                              >
                                <CheckCircleIcon
                                  className={`h-5 w-5 absolute top-3 right-3 components-cards-task-checkcircle ${
                                    JSON.stringify(projects)?.includes(p?.id)
                                      ? "visible"
                                      : "invisible"
                                  }`}
                                />
                                <PlusCircleIcon
                                  className={` text-primary-gray-350 h-5 w-5 absolute top-3 right-3 ${
                                    JSON.stringify(projects)?.includes(p?.id)
                                      ? "invisible"
                                      : "visible"
                                  }`}
                                />
                                <img
                                  className="h-9 w-9 rounded object-cover"
                                  src={
                                    p?.image?.url ||
                                    "/assets/images/icons/rocket.png"
                                  }
                                  alt=""
                                />
                                <p className="font-lato text-sm text-center line-clamp-2 text-primary-gray-1000 mt-2.5">
                                  {getProjectName(p)}
                                </p>
                              </div>
                            );
                          })
                        ) : (
                          <div className="w-full flex flex-col space-y-4 col-span-2">
                            {PROJECT_TYPES_BY_CATEGORY?.find(
                              (a) => a?.name == projectType
                            )?.types?.map((type) => {
                              return (
                                <div className="flex flex-col space-y-2">
                                  <h1 className="text-primary-gray-800 font-semibold text-base font-lato">
                                    {type?.name}
                                  </h1>
                                  <div className="grid grid-cols-2 gap-5">
                                    {projectsList?.filter(
                                      (a) => a?.type == type?.name
                                    )?.length > 0 ? (
                                      projectsList?.map((p) => {
                                        if (p?.type == type?.name)
                                          return (
                                            <div
                                              onClick={() => {
                                                if (forGoals) {
                                                  setProjects([p]);
                                                } else {
                                                  let newProjects = addOrRemove(
                                                    [...projects],
                                                    p
                                                  );
                                                  setProjects(newProjects);
                                                }
                                              }}
                                              className={`flex flex-col items-center rounded-10px relative p-2.5 w-full  ${
                                                JSON.stringify(
                                                  projects
                                                )?.includes(p?.id)
                                                  ? "bg-primary-orange-lightest font-bold"
                                                  : "bg-white font-normal"
                                              }`}
                                            >
                                              <CheckCircleIcon
                                                className={`h-5 w-5 absolute top-3 right-3 components-cards-task-checkcircle ${
                                                  JSON.stringify(
                                                    projects
                                                  )?.includes(p?.id)
                                                    ? "visible"
                                                    : "invisible"
                                                }`}
                                              />
                                              <PlusCircleIcon
                                                className={` text-primary-gray-350 h-5 w-5 absolute top-3 right-3 ${
                                                  JSON.stringify(
                                                    projects
                                                  )?.includes(p?.id)
                                                    ? "invisible"
                                                    : "visible"
                                                }`}
                                              />
                                              <img
                                                className="h-9 w-9 rounded object-cover"
                                                src={
                                                  p?.image?.url ||
                                                  "/assets/images/icons/rocket.png"
                                                }
                                                alt=""
                                              />
                                              <p className="font-lato text-sm text-center line-clamp-2 text-primary-gray-1000 mt-2.5">
                                                {getProjectName(p)}
                                              </p>
                                            </div>
                                          );
                                      })
                                    ) : (
                                      <div className="col-span-2">
                                        <div className="flex justify-center items-center flex-col py-10 space-y-4 h-[10vh]">
                                          <img
                                            src="/assets/images/icons/rocket.png"
                                            alt=""
                                            className="relative h-8 w-8  object-cover"
                                          />

                                          <p className="text-gray-500 text-sm">
                                            No projects found!
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {projectType == "all" && projectsList?.length == 0 && (
                          <div className="col-span-2">
                            <EmptyState
                              text="No projects found!"
                              smallImage
                              image="/assets/images/icons/rocket.png"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
        <div className="mt-8 flex w-full flex-row items-center justify-between px-5 pb-5">
          <ChronosButton
            text="Cancel"
            tertiary
            onClick={() => {
              closeModal();
            }}
          />

          <ChronosButton
            text="Assign project"
            primary
            icon={<ChevronRightIcon className="w-4 h-4" />}
            onClick={() => {
              handleAdd();
            }}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default AddProjectModal;
