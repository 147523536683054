import React, { useState } from "react";
import EmptyState from "components/Comman/EmptyState";
import TaskCreateModal from "components/Tasks/Modals/TaskCreate/TaskCreate";
import ChronosButton from "components/Comman/Buttons";
import { PlusIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid";
import moment from "moment";

function GoalsTask({ goal, tasks, onUpdate }) {
  const [openMobileCreateModal, setOpenMobileCreateModal] = useState(false);
  return (
    <>
      <TaskCreateModal
        closeModal={() => {
          setOpenMobileCreateModal(false);
        }}
        isOpen={openMobileCreateModal}
        onCreate={() => {
          onUpdate(true);
          setOpenMobileCreateModal(false);
        }}
        goal={goal}
        project={goal?.project}
        ownerId={goal?.ownerId}
      />
      <div className="w-full mt-5 px-2.5 ">
        <div className="block mb-2.5">
          <div className="flex flex-row items-center justify-end px-5">
            <ChronosButton
              primary
              text="Add new Task"
              icon={<PlusIcon className="w-4 h-4 ml-1.5" />}
              onClick={() => setOpenMobileCreateModal(true)}
            />
          </div>
        </div>{" "}
        {tasks?.length > 0 ? (
          <div className="px-5 flow-root">
            <ul role="list" className="">
              {tasks?.map((item, idx) => {
                return (
                  <li>
                    <div className="relative pb-8">
                      {idx != tasks?.length - 1 ? (
                        <span
                          className="absolute top-9 left-3 -ml-px h-full w-0.5 border-r border-primary-gray-250"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div className="relative flex space-x-6 w-full">
                        <div className="w-6 h-6 rounded-full bg-primary-orange-lightest flex flex-row items-center justify-center">
                          {item?.owner ? (
                            <img
                              src={item?.owner?.image?.url}
                              className="w-6 h-6 rounded-full"
                            />
                          ) : (
                            <QuestionMarkCircleIcon className="w-4 h-4 rounded-full text-primary-orange-medium" />
                          )}
                        </div>
                        <div className="min-w-0 flex-1 flex justify-between space-x-4 w-full">
                          <div className="w-full">
                            <div className="w-full flex flex-col md:flex-row items-start md:items-center justify-between">
                              <div
                                className={`text-sm flex flex-row items-start font-lato cursor-pointer text-primary-gray-600 font-bold w-full break-words`}
                              >
                                <p>{item.title}</p>
                              </div>
                            </div>
                            <p
                              className={`text-2xs font-lato cursor-pointer text-primary-gray-350 font-normal`}
                            >
                              {item?.description}
                            </p>
                            {item?.dueDate && (
                              <p
                                className={`block text-2xs my-1.5 max-w-max font-lato cursor-pointer ${
                                  moment(item?.dueDate).unix() <
                                  moment().add(7, "days").unix()
                                    ? "text-primary-orange-darker font-normal bg-primary-orange-lightest"
                                    : "text-primary-indigo-700 font-normal bg-primary-indigo-100"
                                } px-4 py-1 rounded-full`}
                              >
                                {moment(item?.dueDate).format("ll")}
                              </p>
                            )}
                            <p
                              className={`text-2xs font-lato cursor-pointer text-primary-orange-darker underline font-normal`}
                            >
                              {item?.project?.title}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className="flex flex-row items-center justify-center min-h-20vh max-h-40vh">
            <EmptyState
              text="Oops! Looks there aren't any tasks!"
              image="/assets/images/empty/tasks.png"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default GoalsTask;
