import React, { useState } from "react";
import { Plus, Target } from "@phosphor-icons/react";
import { BiChevronUp } from "react-icons/bi";
import EmptyState from "components/Comman/EmptyState";
import moment from "moment";
import PriorityDropdown from "components/Tasks/Card/PriorityDropdown";
import StatusDropDown from "components/Comman/Inputs/StatusDropDown";
import { Transition, Disclosure } from "@headlessui/react";
import ChronosButton from "components/Comman/Buttons";
import DateInput from "components/Comman/Inputs/DateInput";
import * as TaskAPI from "config/APIs/task/task";
import * as GoalAPI from "config/APIs/task/goal";
import ConfirmModal from "components/Modals/Common/ConfirmModal";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import { canDeleteGoal } from "helpers/utils/permissions/goals";
import { canDeleteTask } from "helpers/utils/permissions/tasks";

function ProjectWiseTask({ user, projects, onUpdate, fetching }) {
  const dispatch = useDispatch();
  const [addTask, setAddTask] = useState(false);
  const [details, setDetails] = useState({});
  const [creating, setCreating] = useState(false);
  const [deleteModal, setDeleteModal] = useState();
  const onDelete = async (type, id) => {
    switch (type) {
      case "goal":
        try {
          const response = await GoalAPI.deleteGoal(id);
          if (response.data) {
            dispatch(showToast({ message: "Goal Deleted!" }));
            onUpdate();
          }
        } catch (err) {
          console.log("Delete task error", err);
          switch (err.response?.status) {
            case 401:
              dispatch(showToast({ message: "Unauthorized!", type: "error" }));
              break;
            default:
              dispatch(
                showToast({ message: "Something went wrong!", type: "error" })
              );
          }
        }
        break;
      case "task":
        try {
          const response = await TaskAPI.deleteTasks(id);
          if (response.data) {
            dispatch(showToast({ message: "Task Deleted!" }));
            onUpdate();
          }
        } catch (err) {
          console.log("Delete task error", err);
          switch (err.response?.status) {
            case 401:
              dispatch(showToast({ message: "Unauthorized!", type: "error" }));
              break;
            default:
              dispatch(
                showToast({ message: "Something went wrong!", type: "error" })
              );
          }
        }
        break;
      default:
    }
  };
  const addTasks = async () => {
    setCreating(true);

    TaskAPI.createTasks({ ...details })
      .then((res) => {
        let resp = res.data.data;
        onUpdate(resp, details?.projectId, details?.goalId);
        setDetails({});
        setAddTask(false);
        setCreating(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  if (fetching) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div role="status">
          <svg
            aria-hidden="true"
            class="w-8 h-8 animate-spin text-gray-200 fill-gray-400"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <>
      <ConfirmModal
        isOpen={deleteModal ? true : false}
        closeModal={() => setDeleteModal()}
        onAccept={() => {
          onDelete(deleteModal?.type, deleteModal[deleteModal?.type]?.id);
          setDeleteModal();
        }}
        text={
          <>
            Are you sure you want to delete the{" "}
            {deleteModal && deleteModal?.type}:{" "}
            {deleteModal && deleteModal[deleteModal?.type]?.title}?
          </>
        }
      />{" "}
      <div className="mt-4 w-full flex flex-col space-y-4">
        {projects?.length > 0 ? (
          projects?.map((item, i) => {
            return (
              <Disclosure defaultOpen className="w-full space-y-4">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="w-full">
                      <div className="pb-2 flex items-center justify-between cursor-pointer border-b border-primary-gray-250">
                        <div className="flex flex-row items-center justify-start space-x-4">
                          <img
                            src={item?.image?.url || "/assets/svg/rocket.svg"}
                            className="rounded-full bg-gray-100 w-8 h-8"
                          ></img>
                          <h2 className="font-semibold font-karla text-black">
                            {item.title || "Unassigned Project"}
                          </h2>
                        </div>

                        <BiChevronUp
                          className={
                            open
                              ? "transform w-6 scale-125"
                              : "-rotate-180 w-6 scale-125"
                          }
                        />
                      </div>
                    </Disclosure.Button>

                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="w-full space-y-4">
                        {item?.goals?.map((goal) => {
                          return (
                            <div className="border border-primary-gray-80 bg-white rounded-lg p-4 w-full space-y-4">
                              <div className="flex bg-primary-gray-50 border border-gray-80 rounded px-2 py-0.5 space-x-2 max-w-max flex-row items-center">
                                <Target className="w-4 h-4 text-primary-red-500" />
                                <span className="font-semibold font-karla text-2xs text-primary-gray-600">
                                  Goal
                                </span>
                              </div>
                              <div className="text-primary-gray-600 font-karla text-sm w-full">
                                {goal?.title}
                              </div>
                              <div className="text-primary-gray-250 font-lato text-xs w-full">
                                <b>Due On : </b>
                                <span className="text-xs">
                                  {moment(goal?.dueDate).format("ll")}
                                </span>
                              </div>
                              <div className="text-primary-gray-250 font-lato text-xs w-full">
                                <b> Owned By : </b>
                                <span className="text-xs">
                                  {goal?.owner ? goal?.owner?.first_name : "NA"}
                                </span>
                              </div>
                              <Disclosure className="w-full space-y-4">
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button className="w-full pb-2 border-b border-primary-gray-80">
                                      <div className="text-primary-gray-400 space-x-0.5 text-xs font-semibold font-lato flex flex-row items-center justify-between w-full">
                                        {/* <div className="flex flex-row  space-x-0.5 items-center">
                                          <p>
                                            Tasks ({goal?.tasks?.length || 0})
                                          </p>
                                          <BiChevronUp
                                            className={
                                              open
                                                ? "transform w-6 scale-150"
                                                : "-rotate-180 w-6 scale-150"
                                            }
                                          />
                                        </div>{" "} */}
                                        {canDeleteGoal(goal) &&
                                          goal?.id != 0 && (
                                            <div className="mt-2.5 block">
                                              <ChronosButton
                                                text="Delete"
                                                onClick={() => {
                                                  setDeleteModal({
                                                    type: "goal",
                                                    goal,
                                                  });
                                                }}
                                                tertiary
                                                red
                                              />
                                            </div>
                                          )}
                                      </div>
                                    </Disclosure.Button>

                                    <Transition
                                      enter="transition duration-100 ease-out"
                                      enterFrom="transform scale-95 opacity-0"
                                      enterTo="transform scale-100 opacity-100"
                                      leave="transition duration-75 ease-out"
                                      leaveFrom="transform scale-100 opacity-100"
                                      leaveTo="transform scale-95 opacity-0"
                                    >
                                      <Disclosure.Panel className="w-full space-y-2 px-2">
                                        {goal?.tasks?.map((task) => {
                                          return (
                                            <div className="flex flex-col bg-primary-gray-50 py-2 px-4 rounded-lg space-y-2">
                                              <div className="flex flex-row items-center justify-between w-full">
                                                <p className="w-10/12 text-primary-gray-600 font-karla text-sm">
                                                  {task?.title}
                                                </p>
                                                {task?.priority && (
                                                  <PriorityDropdown
                                                    priorityValue={
                                                      task?.priority
                                                    }
                                                    disabled
                                                  />
                                                )}
                                              </div>
                                              <div className="flex flex-row items-center justify-between w-full">
                                                <div className="text-primary-gray-250 font-lato text-xs">
                                                  <b>Due On :</b>
                                                  <span className="text-xs">
                                                    {moment(
                                                      task?.dueDate
                                                    ).format("ll")}
                                                  </span>
                                                </div>
                                                <StatusDropDown
                                                  status={task?.status}
                                                  disabled
                                                />
                                              </div>
                                              {canDeleteTask(task) &&
                                                task?.id != 0 && (
                                                  <div className="mt-2.5 block">
                                                    <ChronosButton
                                                      text="Delete"
                                                      onClick={() => {
                                                        setDeleteModal({
                                                          type: "task",
                                                          task,
                                                        });
                                                      }}
                                                      tertiary
                                                      red
                                                    />
                                                  </div>
                                                )}
                                            </div>
                                          );
                                        })}
                                        {addTask ? (
                                          <div className="addTaskCard px-6 py-4 rounded-lg space-y-4">
                                            <div className="flex flex-row items-center space-x-4">
                                              <label className="font-semibold text-sm text-primary-gray-600 font-lato">
                                                Task:
                                              </label>
                                              <input
                                                value={details?.title}
                                                onChange={(e) => {
                                                  setDetails({
                                                    ...details,
                                                    title: e.target.value,
                                                  });
                                                }}
                                                type="text"
                                                placeholder="Add task details.."
                                                className="border-b w-full border-primary-gray-250 rounded-t-lg border-t-0 border-l-0 border-r-0 bg-white text-sm text-primary-gray-600 px-4 py-2"
                                              />
                                            </div>
                                            <div className="flex flex-row items-center space-x-4">
                                              <label className="font-semibold text-sm text-primary-gray-600 font-lato">
                                                Due On:{" "}
                                              </label>
                                              <div className="border-b w-full border-primary-gray-250 rounded-t-lg border-t-0 border-l-0 border-r-0 bg-white text-sm text-primary-gray-600 max-w-max">
                                                <DateInput
                                                  noBorder
                                                  date={details?.dueDate}
                                                  onChange={(val) => {
                                                    setDetails({
                                                      ...details,
                                                      dueDate: val,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            {/* <div className="flex flex-row items-center space-x-4">
                                            <label className="font-semibold text-sm text-primary-gray-600 font-lato">
                                              Priority:
                                            </label>
                                            <div className="rounded-lg w-full px-2 py-1 flex flex-row space-x-4 bg-white text-sm text-primary-gray-600 max-w-max">
                                              {[1, 2, 3, 4, 5, 6].map((it) => (
                                                <div
                                                  onClick={() => {
                                                    setDetails({
                                                      ...details,
                                                      priority: it,
                                                    });
                                                  }}
                                                  className={`bg-yellow-300 cursor-pointer p-1 text-xs flex flex-row items-center rounded bg-opacity-${it}0 ${
                                                    details?.priority == it
                                                      ? "border border-primary-yellow-medium"
                                                      : ""
                                                  }`}
                                                >
                                                  <FireIcon
                                                    className={`w-4 h-4 text-primary-orange-dark`}
                                                  />
                                                  <span className="">{it}</span>
                                                </div>
                                              ))}
                                            </div>
                                          </div> */}
                                            <div className="flex justify-between mt-4">
                                              <ChronosButton
                                                underline
                                                tertiary
                                                text="Cancel"
                                                onClick={() => {
                                                  setAddTask(false);
                                                  setDetails({});
                                                }}
                                              />
                                              <ChronosButton
                                                loader={creating}
                                                primary
                                                orange
                                                reverseIcon
                                                icon={
                                                  <Plus
                                                    size={12}
                                                    className="mr-2"
                                                  />
                                                }
                                                text="Add Now"
                                                onClick={() => {
                                                  addTasks();
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="flex justify-end">
                                            <ChronosButton
                                              underline
                                              tertiary
                                              reverseIcon
                                              icon={
                                                <Plus
                                                  size={12}
                                                  className="mr-2"
                                                />
                                              }
                                              text="Add Task"
                                              onClick={() => {
                                                setAddTask(true);
                                                setDetails({
                                                  projectId: item?.id,
                                                  goalId: goal?.id,
                                                  ownerId: user?.id,
                                                  status: "NotStarted",
                                                });
                                              }}
                                            />
                                          </div>
                                        )}
                                      </Disclosure.Panel>
                                    </Transition>
                                  </>
                                )}
                              </Disclosure>
                            </div>
                          );
                        })}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            );
          })
        ) : (
          <EmptyState
            image="/assets/images/empty/goal.png"
            text={`${user?.first_name} has no tasks!`}
          />
        )}
      </div>
    </>
  );
}

export default ProjectWiseTask;
