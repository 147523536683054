import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { Calendar } from "@phosphor-icons/react";

function DateInput({ date, onChange, noBorder }) {
  const [calendarOpen, setCalendarOpen] = useState(false);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          value={dayjs(date)}
          onChange={(value) => {
            onChange(value);
          }}
          sx={{
            width: "100%",
            ".MuiInputBase-input": {
              padding: "4px 8px",
              fontSize: ".8rem",
            },
          }}
          renderInput={({ inputRef, inputProps }) => (
            <div
              onClick={() => setCalendarOpen(true)}
              className={`flex w-full flex-row items-center cursor-pointer ${
                noBorder ? "border-0" : "border"
              } bg-white rounded-lg border-gray-200 py-1 px-2.5 max-w-max`}
            >
              {date ? (
                <input
                  ref={inputRef}
                  {...inputProps}
                  className="text-black w-24 font-lato font-normal focus:border-0 focus:ring-0 focus:outline-none bg-transparent border-0 p-0 text-xs lg:text-base"
                ></input>
              ) : (
                <input
                  ref={inputRef}
                  {...inputProps}
                  placeholder="Add Due Date"
                  className="text-black w-24 bg-white rounded-full py-1 px-2.5 focus:border-0 focus:ring-0 focus:outline-none bg-transparent border-0 p-0 text-xs"
                ></input>
              )}
              <Calendar size={20} />
            </div>
          )}
        />
      </LocalizationProvider>
    </>
  );
}

export default DateInput;
