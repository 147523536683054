import { useState, useEffect } from "react";

import EmptyState from "components/Comman/EmptyState";

import { useSelector } from "react-redux";
import MembersList from "components/Comman/Lists/MembersList";
import YodaLoader from "components/Comman/Loaders/YodaLoader";

function CommunityList({ search, setShowing, selectedTab }) {
  const community = useSelector((state) => state.tam);
  const [fetching, setFetching] = useState(false);
  const [list, setList] = useState([]);

  const fetchAll = async () => {
    setFetching(true);
    if (community?.typeWiseList[selectedTab]?.length > 0) {
      setList(community?.typeWiseList[selectedTab]);
      let str = `${community?.typeWiseList[selectedTab]?.length} members`;
      setShowing(str);
      setFetching(false);
      return;
    }
  };

  useEffect(() => fetchAll(), [community?.typeWiseList]);
  useEffect(() => {
    let l = community?.typeWiseList[selectedTab];

    if (search?.isSearch) {
      l = l?.filter((e) => {
        e?.first_name.toLowerCase().includes(search?.searchText.toLowerCase());
      });
    }
    setShowing(`${l?.length || 0} members`);
    setList(l);
    // if (search?.isSearch) {
    //   let i = [];
    //   list?.map((item) => {
    //     i = i?.concat(
    //       item?.members.filter((e) =>
    //         e?.first_name
    //           .toLowerCase()
    //           .includes(search?.searchText.toLowerCase())
    //       )
    //     );
    //   });
    //   setShowing(`${i?.length} members`);
    // }
  }, [search, selectedTab]);

  return (
    <div className="space-y-5 flex flex-col w-full overflow-y-auto max-h-70vh pb-20">
      {!fetching ? (
        <>
          {list?.length > 0 ? (
            <>
              <MembersList tam category="" members={list} />
              {/* {list?.map((i) => {
                return (
                  <MembersList
                    tam
                    category={i?.rank_name}
                    members={
                      search?.isSearch
                        ? i?.members.filter((e) =>
                            e?.first_name
                              .toLowerCase()
                              .includes(search?.searchText.toLowerCase())
                          )
                        : i?.members
                    }
                  />
                );
              })} */}
            </>
          ) : (
            <EmptyState />
          )}
        </>
      ) : (
        <div className="h-75vh w-full">
          {<YodaLoader text="Getting work done is a game of patience 😶‍🌫" />}
        </div>
      )}
    </div>
  );
}

export default CommunityList;
