import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useEffect, useState } from "react";
//import  from 'apexcharts';

ChartJS.register(ArcElement, Tooltip, Legend);

let chartData = {
  labels: ["Daylight", "Sundown", "Daylight"],
  datasets: [
    {
      label: "# of Work Items",
      data: [12, 19, 3],
      backgroundColor: ["#FFE99A", "#8DA1FC", "#FEC5A4"],
      borderColor: ["#FFE99A", "#8DA1FC", "#FEC5A4"],
      borderWidth: 1,
    },
  ],
};

export default function ProjectsOverview({ data, goalsWithNoProject }) {
  const [stats, setStats] = useState(chartData);
  const [fetching, setFetching] = useState(true);

  /*  useEffect(() => {
    setFetching(true);
    let projects = [];
    let goals = [];
    data?.forEach((p) => {
      if (parseInt(p?.goalsCount) > 0) {
        projects?.push(p?.title);
        goals?.push(parseInt(p?.goalsCount));
      }
    });

    if (goalsWithNoProject?.length > 0) {
      projects?.push("No Project");
      goals?.push(
        goalsWithNoProject?.reduce((a, b) => a + parseInt(b.goalsCount), 0)
      );
    }

    chartData.labels = projects;
    chartData.datasets[0].data = goals;
    setStats(chartData);
    setFetching(false);
  }, [data]); */

  return (
    <div className="p-4 px-5 border border-gray-200 w-full">
      <h2 className="text-[16px] font-normal font-poppins">
        Punch In's Distribution
      </h2>
      <div className="flex p-6">
        <Pie
          data={stats}
          height="auto"
          width="auto"
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "right",
              },
            },
          }}
        />
        {/* {!fetching && (
          <Pie
            data={stats}
            height="auto"
            width="auto"
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: "right",
                },
              },
            }}
          />
        )} */}
      </div>
    </div>
  );
}
