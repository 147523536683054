import DropdownInput from "components/Comman/Inputs/DropdownInput";
import Tabs from "components/Comman/Tabs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDailyPlannerGoals } from "redux/goals";

export default function Header({ tabs, selectedTab, setSelectedTab }) {
  const [groupBy, setGroupBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const {
    tasks: { dailyPlannerTasks, tempDailyPlannerTasks },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleSortBy = (value) => {
    setSortBy(value);
    let sortedTasks = [...dailyPlannerTasks.list];
    switch (value) {
      case "date":
        sortedTasks.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        break;
      case "created_at":
        sortedTasks.sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        });
        break;
      case "priority":
        sortedTasks.sort((a, b) => {
          return a.priority - b.priority;
        });
        break;
      default:
        sortedTasks = tempDailyPlannerTasks;
    }
    dispatch(
      updateDailyPlannerGoals({
        list: sortedTasks,
      })
    );
  };

  const handleGroupBy = (value) => {
    setGroupBy(value);
    let groupedTasks = [...dailyPlannerTasks.list];
    switch (value) {
      case "project":
        groupedTasks.sort((a, b) => {
          return a.project_id > b.project_id ? 1 : -1;
        });
        break;
      case "priority":
        groupedTasks.sort((a, b) => {
          return a.priority - b.priority;
        });
        break;
      case "status":
        groupedTasks.sort((a, b) => {
          return a.status > b.status ? 1 : -1;
        });
        break;
      default:
        groupedTasks = tempDailyPlannerTasks;
    }
    dispatch(
      updateDailyPlannerGoals({
        list: groupedTasks,
      })
    );
  };

  return (
    <div className="flex justify-between items-center">
      {tabs && (
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      )}
      <div className="flex flex-row items-stretch space-x-4">
        <DropdownInput
          list={[
            {
              label: "Group By",
              value: "",
            },
            {
              label: "Project",
              value: "project",
            },
            {
              label: "Priority",
              value: "priority",
            },
            {
              label: "Status",
              value: "status",
            },
          ]}
          value={groupBy}
          setValue={handleGroupBy}
          label="Group By"
          srOnly
        />
        <DropdownInput
          list={[
            {
              label: "Sort By",
              value: "",
            },
            {
              label: "Due Date",
              value: "date",
            },
            {
              label: "Created At",
              value: "created_at",
            },
            {
              label: "Priority",
              value: "priority",
            },
          ]}
          value={sortBy}
          setValue={handleSortBy}
          label="Sort By"
          srOnly
        />

        <DropdownInput list={[]} label="Filter" srOnly />
      </div>
    </div>
  );
}
