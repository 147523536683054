import { useEffect, useState } from "react";
import CurrentGoals from "./CurrentGoals";
import Projects from "../../Common/Projects";
import { useSelector } from "react-redux";
import * as GoalsAPI from "config/APIs/task/goal";
import Members from "components/MemberControl/Members";

export default function GoalSetting() {
  const projects = useSelector((state) => state?.projects?.list);
  const [fetching, setFetching] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    if (projects?.length > 0) {
      setFetching(false);
      /* setSelectedProject(projects); */
    }
  }, [projects]);

  const [goals, setGoals] = useState([]);
  const [fetchingGoals, setFetchingGoals] = useState(false);

  const fetchGoals = async () => {
    try {
      setFetchingGoals(true);
      let query = { projectId: selectedProject?.id };
      const response = await GoalsAPI.getAllGoals(query);
      let goals = response.data.data.response || [];
      setGoals(goals);
      // const currentGoals = goals.filter((goal) => {
      //   const goalDate = moment(goal.due_date);
      //   const today = moment().add(7, "days");
      //   return goalDate.isSameOrBefore(today, "day");
      // });
      // const upcomingGoals = goals.filter((goal) => {
      //   const goalDate = moment(goal.due_date);
      //   const inAWeek = moment().add(7, "days");
      //   return goalDate.isAfter(inAWeek, "day");
      // });

      // setGoals({
      //   currentGoals: getSortedByDate(currentGoals || [], "due_date"),
      //   upcomingGoals: getSortedByDate(upcomingGoals || [], "due_date"),
      // });
    } catch (err) {
      console.log("Fetch goal task error", err);
      setFetchingGoals(false);
    } finally {
      setFetchingGoals(false);
    }
  };

  useEffect(() => {
    if (selectedProject?.id) {
      (async () => await fetchGoals())();
    }
  }, [selectedProject]);

  return (
    <div className="relative ">
      <div className="grid grid-cols-3 gap-6 mt-5">
        <>
          <Projects
            fetching={fetching}
            projects={projects}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            selectedGoalsCount={
              goals?.filter((e) => e?.projectId === selectedProject?.id)?.length
            }
          />
          <Members
            project={selectedProject}
            selectedMember={selectedMember}
            setSelectedMember={setSelectedMember}
            goals={goals}
          />
          <CurrentGoals
            project={selectedProject}
            goals={
              selectedMember
                ? goals?.filter((g) => g?.ownerId === selectedMember?.id)
                : goals
            }
            fetchGoals={fetchGoals}
            fetching={fetchingGoals}
            owner={selectedMember}
          />
        </>
        {/* <UpcomingGoals
          project={selectedProject}
          goals={goals}
          fetchGoals={fetchGoals}
          fetching={fetchingGoals}
        /> */}
      </div>
    </div>
  );
}
