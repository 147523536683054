import { pmoInstance } from "config";
import { getHeader } from "config";

export const findOne = (projectId) =>
  pmoInstance.get(`/product/documentation/${projectId}`, getHeader());

export const findAll = (query = {}) =>
  pmoInstance.get(
    `/product/documentation?${new URLSearchParams(query)}`,
    getHeader()
  );

export const update = (id, body) =>
  pmoInstance.patch(`/product/documentation/${id}`, body, getHeader());
