import { MdCalendarToday, MdNavigateNext } from "react-icons/md";
import { MdQueryBuilder } from "react-icons/md";

import { BsFillPersonFill } from "react-icons/bs";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { Drawer } from "@mui/material";
import Participants from "./Participants";
import { useState } from "react";
import MarkAttendance from "./MarkAttendance";
const TrackCard = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isAttendanceDrawerOpen, setIsAttendanceDrawerOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const toggleAttendanceDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsAttendanceDrawerOpen(open);
  };

  return (
    <div className="rounded-3xl flex flex-col space-y-6 bg-white  p-6 w-[480px]">
      <div className="font-poppins font-medium text-xl">Ted Talk Mornings</div>
      <div className="space-x-6 flex flex-row">
        <div className="text-[#9C9A96] flex space-x-1 justify-center items-center flex-row font-semibold text-xs font-inter">
          <div>
            {" "}
            <MdCalendarToday />
          </div>
          <div>
            <p> Mar 4</p>
          </div>
        </div>
        <div className="text-[#9C9A96] flex flex-row space-x-1 justify-center items-center font-semibold text-xs font-inter">
          <div>
            {" "}
            <MdQueryBuilder />
          </div>
          <div>
            <p> 11:00 am</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div>
          <p>Participants</p>
        </div>
        <div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row">
              <div className="bg-[#D9D9D9] h-5 w-5 rounded-full"></div>
              <div className="bg-[#D9D9D9] -ml-1.5 h-5 w-5 rounded-full"></div>
              <div className="bg-[#D9D9D9] -ml-1.5 h-5 w-5 rounded-full"></div>
              <div className="bg-[#D9D9D9] -ml-1.5 h-5 w-5 rounded-full"></div>
              <div className="bg-[white] -ml-1.5 flex justify-center items-center h-5 w-5 rounded-full">
                <p className="font-semibold text-[8px] font-inter text-black">
                  +2
                </p>
              </div>
            </div>
            <div>
              <button
                onClick={toggleDrawer(true)}
                className="flex  space-x-2 justify-center items-center"
              >
                <p className="font-inter font-semibold underline-offset-4 underline">
                  6 Participants
                </p>
                <MdNavigateNext className="text-black" />
              </button>
              <Drawer
                anchor={"right"}
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
              >
                <Participants toggleDrawer={toggleDrawer} />
              </Drawer>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button className="w-full" onClick={toggleAttendanceDrawer(true)}>
          <div className="bg-[#F57D34] px-6 py-4 flex flex-row justify-center items-center rounded-2xl space-x-2 w-full">
            <div className="flex justify-center items-center">
              <p className="text-white font-inter font-semibold text-sm flex">
                Mark attendance
              </p>
            </div>
            <div className="flex justify-center items-center">
              <BsFillPersonFill className="text-white   items-center  w-6 h-6 flex" />
            </div>
          </div>
        </button>
        <Drawer
          anchor={"right"}
          open={isAttendanceDrawerOpen}
          onClose={toggleAttendanceDrawer(false)}
        >
          <MarkAttendance toggleAttendanceDrawer={toggleAttendanceDrawer} />
        </Drawer>
      </div>
    </div>
  );
};
export default TrackCard;
