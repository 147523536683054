import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ProjectFRD from "./FRD";
import ProjectBRD from "./BRD";
import * as ProductDocumentationAPI from "config/APIs/product/documentation";
import Products from "components/Planner/Common/Products";
import Epics from "components/Planner/ProjectPlanner/GoalSetting/Epics";
import Stories from "components/Planner/ProjectPlanner/GoalSetting/Stories";
import Modal from "components/Planner/ProjectPlanner/Modal";

export default function Documentation() {
  const products = useSelector((state) => state.products.list);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedDocumentation, setSelectedDocumentation] = useState(null);
  const [frd, setFrd] = useState({});
  const [brd, setBrd] = useState({});
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [selectedStory, setSelectedStory] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (products?.length > 0) {
      setSelectedProduct(products[0]);
    }
  }, [products]);

  useEffect(() => {
    setSelectedDocumentation("default");
    fetchDocumentattion();
  }, [selectedProduct]);

  const fetchDocumentattion = async () => {
    try {
      setFetching(true);
      const response = await ProductDocumentationAPI.findOne(
        selectedProduct?.documentationId
      );
      let data = response?.data?.data;
      setFrd(data?.frd);
      setBrd(data?.brd);
    } catch (err) {
      console.log("Fetch epic task error", err);
    }
    setFetching(false);
  };

  const handleUpdate = async () => {
    try {
      setFetching(true);
      let body = { frd, brd };
      const response = await ProductDocumentationAPI.update(
        selectedProduct?.documentationId,
        body
      );
      let data = response?.data?.data;
      setFrd(data?.frd);
      setBrd(data?.brd);
    } catch (err) {
      console.log("Fetch epic task error", err);
    }
    setFetching(false);
  };
  return (
    <div className="relative ">
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        epic={selectedEpic}
        story={selectedStory}
      />{" "}
      <div className="min-h-[70vh] grid grid-cols-3 gap-7 mt-5">
        <Products
          list={products}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          showDocumentation
          selectedDocumentation={selectedDocumentation}
          setSelectedDocumentation={setSelectedDocumentation}
        />
        <div className="col-span-2">
          {selectedProduct && selectedDocumentation == "default" && (
            <div className="min-80vh grid grid-cols-2 gap-6">
              <Epics
                selectedProduct={selectedProduct}
                selectedEpic={selectedEpic}
                setSelectedEpic={setSelectedEpic}
              />
              <Stories
                selectedProduct={selectedProduct}
                selectedEpic={selectedEpic}
                setIsOpen={setIsOpen}
                selectedStory={selectedStory}
                setSelectedStory={setSelectedStory}
              />
            </div>
          )}
          {selectedProduct && selectedDocumentation == "FRD" && (
            <ProjectFRD
              product={selectedProduct}
              frd={frd}
              setFrd={setFrd}
              onUpdate={() => handleUpdate()}
              onRefresh={() => fetchDocumentattion()}
            />
          )}
          {selectedProduct && selectedDocumentation == "BRD" && (
            <ProjectBRD
              product={selectedProduct}
              brd={brd}
              setBrd={setBrd}
              onUpdate={() => handleUpdate()}
              onRefresh={() => fetchDocumentattion()}
            />
          )}
        </div>
      </div>
    </div>
  );
}
