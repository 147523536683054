export const USER_TYPE_CAARYA_CORE = "caarya-core";
export const USER_TYPE_CUSTOMER_FIC = "customer-fic";
export const USER_TYPE_CUSTOMER_FWS = "customer-fws";
export const USER_TYPE_CGC_LEADER = "cgc-leader";
export const USER_TYPE_CGC_CORE = "cgc-core";
export const USER_TYPE_YLAP = "ylap";
export const USER_TYPE_MENTORS = "caarya_mentors";
export const USER_TYPE_PARTNERS = "caarya_partners";
export const USER_TYPE_ASSOCIATES = "caarya_associates";
export const USER_TYPE_INITIATIVE = "initiative";

export const userTypes = [
  { label: "Core", value: USER_TYPE_CAARYA_CORE },
  { label: "Mentors", value: USER_TYPE_MENTORS },
  { label: "Associates", value: USER_TYPE_ASSOCIATES },
  { label: "Parnters", value: USER_TYPE_PARTNERS },
  /* { label: "CGC Leader", value: USER_TYPE_CGC_LEADER },
  { label: "YLAP", value: USER_TYPE_YLAP },
  { label: "CGC Core", value: USER_TYPE_CGC_CORE }, */
  //   { label: "Customer FIC", value: USER_TYPE_CUSTOMER_FIC },
  //   { label: "Customer FWS", value: USER_TYPE_CUSTOMER_FWS },
  //   { label: "Initiatives", value: USER_TYPE_INITIATIVE },
];
