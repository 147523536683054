import Drawer from "@mui/material/Drawer";
import GoalsPage from "pages/Goals/IndividualPage";
function GoalPageLaptop({ closeModal, isOpen, id, onUpdate }) {
  return (
    <Drawer
      anchor="right"
      PaperProps={{
        style: { borderRadius: "0px", maxHeight: "100vh", width: "560px" },
      }}
      open={isOpen}
      onClose={() => {
        closeModal();
      }}
      transitionDuration={250}
    >
      <div className="modals-component h-screen pt-10 px-5 transform transition-all ease-in-out duration-150">
        <GoalsPage
          goalId={isOpen ? id : null}
          closeModal={closeModal}
          onUpdate={onUpdate}
        />
      </div>
    </Drawer>
  );
}

export default GoalPageLaptop;
