import Number from "../Common/Number";
import SectionHeading from "../Common/SectionHeading";
import SimpleTextArea from "components/Comman/Inputs/SimpleTextArea";
import { PlusCircle } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import { useState } from "react";

function ProjectBRD({ product, brd, setBrd, onUpdate, onRefresh }) {
  const [editable, setEditable] = useState(false);

  return (
    <div className="space-y-8">
      <div className="flex flex-row items-center justify-between w-full space-x-2">
        <h2 className="font-extrabold text-lg text-black">
          {product?.name} BRD
        </h2>
        <div className="flex flex-row items-center justify-between space-x-2">
          {editable ? (
            <>
              <ChronosButton
                secondary
                text="Cancel"
                onClick={() => {
                  setEditable(false);
                  onRefresh();
                }}
              />
              <ChronosButton
                primary
                text="Save"
                onClick={() => {
                  setEditable(false);
                  onUpdate();
                }}
              />
            </>
          ) : (
            <>
              <ChronosButton
                primary
                text="Edit"
                onClick={() => setEditable(true)}
              />
            </>
          )}
        </div>
      </div>
      <div className=" flow-root ">
        <ul role="list" className="h-[85vh] overflow-auto space-y-8">
          <li className="flex flex-row items-start relative">
            <span
              className="absolute top-9 left-4 -ml-px h-full w-0.5 border-r border-primary-orange-lighter"
              aria-hidden="true"
            />{" "}
            <Number number={1} />
            <div className="space-y-3.5 w-10/12 ml-4">
              <SectionHeading heading="Methodology" />
              <SimpleTextArea
                isDisabled={!editable}
                value={brd?.methodology}
                setValue={(val) => {
                  setBrd({ ...brd, methodology: val });
                }}
                rows={3}
                srOnly
              />
            </div>
          </li>
          <li className="flex flex-row items-start relative">
            <span
              className="absolute top-9 left-4 -ml-px h-full w-0.5 border-r border-primary-orange-lighter"
              aria-hidden="true"
            />{" "}
            <Number number={2} />
            <div className="space-y-3.5 w-10/12 ml-4">
              <SectionHeading heading="Problem Statement" />
              <SimpleTextArea
                isDisabled={!editable}
                value={brd?.problemStatement}
                setValue={(val) => {
                  setBrd({ ...brd, problemStatement: val });
                }}
                rows={3}
                srOnly
              />
            </div>
          </li>
          <li className="flex flex-row items-start relative">
            <span
              className="absolute top-9 left-4 -ml-px h-full w-0.5 border-r border-primary-orange-lighter"
              aria-hidden="true"
            />{" "}
            <Number number={3} />
            <div className="space-y-3.5 w-10/12 ml-4">
              <SectionHeading heading="Overview" />
              <SimpleTextArea
                isDisabled={!editable}
                label="Description"
                value={brd?.description}
                setValue={(val) => {
                  setBrd({ ...brd, description: val });
                }}
                rows={3}
              />
              <SimpleTextArea
                isDisabled={!editable}
                label="Business Objectives"
                value={brd?.businessObjectives}
                setValue={(val) => {
                  setBrd({ ...brd, businessObjectives: val });
                }}
                rows={3}
              />
              <SimpleTextArea
                isDisabled={!editable}
                label="Business Goals"
                value={brd?.businessGoals}
                setValue={(val) => {
                  setBrd({ ...brd, businessGoals: val });
                }}
                rows={3}
              />
            </div>
          </li>
          <li className="flex flex-row items-start relative">
            <span
              className="absolute top-9 left-4 -ml-px h-full w-0.5 border-r border-primary-orange-lighter"
              aria-hidden="true"
            />{" "}
            <Number number={4} />
            <div className="space-y-3.5 w-10/12 ml-4">
              <SectionHeading heading="Success Indicators" />
              <div className="-m-1 flex flex-row items-center flex-wrap">
                {brd?.successIndicators?.map((i) => {
                  return (
                    <div className="flex items-center gap-1 p-2 bg-primary-ash-50 text-xs rounded-full text-primary-gray-300">
                      {i}
                    </div>
                  );
                })}
                {editable && (
                  <button
                    onClick={() => {
                      let g = brd.successIndicators
                        ? [...brd.successIndicators]
                        : [];
                      g.push(" ");
                      setBrd({ ...brd, successIndicators: g });
                    }}
                    className="flex items-center gap-1 p-2 bg-primary-ash-50 text-xs rounded-full text-primary-gray-300"
                  >
                    <PlusCircle size={12} />
                    Add Tag
                  </button>
                )}
              </div>
            </div>
          </li>
          <li className="flex flex-row items-start relative">
            <span
              className="absolute top-9 left-4 -ml-px h-full w-0.5 border-r border-primary-orange-lighter"
              aria-hidden="true"
            />{" "}
            <Number number={5} />
            <div className="space-y-3.5 w-10/12 ml-4">
              <SectionHeading heading="System Perspective" />
              <SimpleTextArea
                isDisabled={!editable}
                label="Assumptions"
                value={brd?.assumptions}
                setValue={(val) => {
                  setBrd({ ...brd, assumptions: val });
                }}
                rows={3}
              />
              <SimpleTextArea
                isDisabled={!editable}
                label="Constraints"
                value={brd?.constraints}
                setValue={(val) => {
                  setBrd({ ...brd, constraints: val });
                }}
                rows={3}
              />
            </div>
          </li>
          <li className="flex flex-row items-start relative">
            <span
              className="absolute top-9 left-4 -ml-px h-full w-0.5 border-r border-primary-orange-lighter"
              aria-hidden="true"
            />{" "}
            <Number number={6} />
            <div className="space-y-3.5 w-10/12 ml-4">
              <SectionHeading heading="Stakeholder Identification" />
              <div className="border rounded border-primary-gray-200 text-sm font-karla grid grid-cols-2">
                <div className="py-3 px-4 rounded-tl border-r border-primary-gray-200 bg-primary-ash-50 font-bold text-primary-ash-700">
                  Name & Designation (Role + Rank)
                </div>
                <div className="py-3 px-4 rounded-tr bg-primary-ash-50 font-bold text-primary-ash-700">
                  Responsibilities
                </div>
                {product?.owner && (
                  <>
                    <div
                      className={`py-3 px-4 rounded-tl border-r border-primary-gray-200 font-bold text-primary-ash-700 ${"border-b"}`}
                    >
                      {product?.owner?.first_name}
                      <br />
                      {product?.owner?.designation?.designation_name}
                    </div>
                    <div
                      className={`py-3 px-4 capitalize rounded-tr font-bold text-primary-ash-700 border-primary-gray-200 ${"border-b"}`}
                    >
                      Owner
                    </div>
                  </>
                )}{" "}
                {product?.members?.map((member, index) => {
                  return (
                    <>
                      <div
                        className={`py-3 px-4 rounded-tl border-r border-primary-gray-200 font-bold text-primary-ash-700 ${
                          index + 1 == product?.members?.length
                            ? ""
                            : "border-b"
                        }`}
                      >
                        {member?.first_name}
                        <br />
                        {member?.designation?.designation_name}
                      </div>
                      <div
                        className={`py-3 px-4 capitalize rounded-tr font-bold text-primary-ash-700 border-primary-gray-200 ${
                          index + 1 == product?.members?.length
                            ? ""
                            : "border-b"
                        }`}
                      >
                        {member?.userproducts?.type}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ProjectBRD;
