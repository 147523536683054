import Tabs from "components/Comman/Tabs";
import EpicTable from "components/Comman/Tabs/Epics/Epics";
import GoalsTab from "components/Comman/Tabs/Goals";
import StoriesTable from "components/Comman/Tabs/Stories/Stories";
import TaskTable from "components/Comman/Tabs/Tasks/Tasks";
import MembersTabs from "./Members";
import React, { useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { Pencil } from "@phosphor-icons/react";
import { update } from "config/APIs/project";

const ProjectTabs = ({
  project,
  goal,
  selectedTab,
  setSelectedTab,
  epic,
  story,
  setSelectedStory,
  setSelectedEpic,

  tabs,
}) => {
  const [summary, setSummary] = useState("");
  const [editSummary, setEditSummary] = useState(false);
  const [adding, setAdding] = useState(false);
  useEffect(() => {
    setSummary(project?.summary);
    if (!project?.summary || project?.summary == "") setEditSummary(true);
  }, [project]);

  const onUpdateProject = async () => {
    setAdding(true);
    try {
      const response = await update(project?.id, {
        project: { summary: summary },
      });

      if (response.status === 200) {
        setEditSummary(false);
      }
    } catch (err) {
      console.log("project fetching error", err);
    }
    setAdding(false);
  };
  return (
    <div className="relative">
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <div className="px-5">
        {/* {selectedTab == "Epics" && (
          <EpicTable
            project={project}
            goal={goal}
            emptyMessage={emptyMessage}
            selectedEpic={epic}
            setSelectedEpic={(val) => {
              setSelectedEpic(val);
            }}
          />
        )}
        {selectedTab == "Stories" && (
          <StoriesTable
            project={project}
            goal={goal}
            epic={epic}
            emptyMessage={emptyMessage}
            setSelectedStory={(val) => setSelectedStory(val)}
            mergeTabs
          />
        )}
        {selectedTab == "To-Do" && (
          <TaskTable
            emptyMessage={emptyMessage}
            project={project}
            goal={goal}
            epic={epic}
            story={story}
            hideGoals={hideGoals}
            setTasksView={(val) => {
              setTasksView(val);
            }}
          />
        )} */}
        {selectedTab == "Goals" && (
          <GoalsTab
            projectTab
            emptyMessage={`${project?.title} has no goals!`}
            project={project}
          />
        )}
        {selectedTab == "members" && (
          <MembersTabs
            project={project}
            owner={project?.owner}
            members={project?.members}
          />
        )}

        {selectedTab == "summary" && (
          <>
            <div className="flex flex-row items-center justify-end">
              {adding ? (
                <div className="w-4 h-4 rounded-full bg-transparent border border-primary-gray-500 border-t-0 animate-spin" />
              ) : editSummary && summary && summary !== "" ? (
                <CheckIcon
                  className="w-4 h-4 text-primary-indigo-400 z-50"
                  onClick={() => {
                    onUpdateProject();
                  }}
                />
              ) : summary && summary !== "" ? (
                <Pencil
                  className="w-4 h-4 text-primary-indigo-400 z-50"
                  onClick={() => {
                    setEditSummary(true);
                  }}
                />
              ) : (
                <></>
              )}
            </div>{" "}
            {summary && !editSummary ? (
              <p className={`font-lato text-primary-gray-800 text-sm `}>
                {summary}
              </p>
            ) : (
              <>
                <textarea
                  value={summary}
                  onChange={(e) => {
                    setSummary(e.target.value);
                  }}
                  className="w-full addSummary z-50 focus:outline-none focus:ring-transparent"
                  placeholder="Start typing to add battle summary...."
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default ProjectTabs;
